import { Button, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch } from "react-redux";
import { deleteDocument } from "../../../../redux/bookingRedux/bookingBasicDetailSlice";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { genrateDownloadLink } from "../../../../utils/utility";
import downloadDocument from "../../downloadDocument";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
const UploadTable = (props) => {
  const location = useLocation();
  let locationCheck = location?.pathname;
  const isEdit = props?.isEdit;
  const data = useSelector((state) => state);
  // in booking edit it takes data from props and in create it takes from redux
  let docsCollection = isEdit
    ? props.editDocs
    : data.bookingBasicDetails.DocsCollection;
  const dispatch = useDispatch();
  const deleteDoc = (index) => {
    dispatch(deleteDocument({ index: index }));
  };
  function formatFileSize(bytes, decimalPoint) {
    if (bytes == 0) return "0 Bytes";
    let k = 1000,
      dm = decimalPoint || 2,
      sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  return (
    <>
      <div className="table-container-div" style={{ maxHeight: "18rem" }}>
        <div
          className="table-head air mb-3 d-flex"
          style={{ fontSize: "var(--fs-mid)" }}
        >
          <div className="col-1">#</div>
          <div className="col-3">Name</div>
          <div className="col-2">Type</div>
          <div className="col-3">Size</div>
          <div className="col-3">Actions</div>
        </div>
        <div
          className="details-side-parent"
          style={{
            rowGap: "1rem",
          }}
        >
          {docsCollection.map((item, index) => {
            return (
              <div className="d-flex ">
                <div className="col-1">{index + 1}</div>
                <div className="col-3 wordEllips">{item?.DocsFileName}</div>
                <div className="col-2">{item?.DocsDocType}</div>
                <div className="col-3">
                  {formatFileSize(item?.DocsFileSize)}
                </div>
                <div className="col-3">
                  <IconButton
                    onClick={() => {
                      if (isEdit) {
                        props?.deleteNewDocs(index);
                      } else {
                        deleteDoc(index);
                      }
                    }}
                    aria-label="delete"
                    size="small"
                    style={{
                      color: "var(--color-miscellaneous-7)",
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>{" "}
                  {locationCheck.includes("edit") ? (
                    <Button
                      className=""
                      onClick={() => {
                        let prom = downloadDocument({
                          DocumentPk: item?.DocPK,
                          BookingNumber: item?.BookingNumber,
                        });
                        prom
                          .then((res) => {
                            const downloadLink = document.createElement("a");
                            console.log(downloadLink,"down")
                            const prefix = genrateDownloadLink(
                              res?.data?.DocsFileName ?? ""
                            );
                            downloadLink.href = `${prefix}${
                              res?.data?.DocsFileData ?? ""
                            }`;

                            if (prefix && res?.data?.DocsFileData) {
                              downloadLink.download =
                                res?.data?.DocsFileName ?? "";
                              downloadLink.click();
                            } else {
                              toast.error("Invalid document found!");
                            }
                          })
                          .catch((err) => {
                            console.log("errro", err);
                          });
                      }}
                    >
                      <FileDownloadOutlinedIcon />
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {docsCollection?.length === 0 && !location.pathname.includes("edit") ? (
        <div
          style={{
            height: "75%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            props.setUploadBar(true);
          }}
        >
          {" "}
          <UploadFileIcon /> Start uploading your files.
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default UploadTable;
