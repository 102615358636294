import React, { useEffect, useState, useRef } from 'react'
import './statement.css'
import BtnOutline from '../../components/button/BtnOutline'
import FilterListIcon from '@mui/icons-material/FilterList'
import Tableview from './components/Tableview'
import instance from '../../api/Interceptor'
import { API_PATH } from '../../constant/apiPath'
import {
  Button,
  CircularProgress,
  Grid,
  Skeleton,
  Typography
} from '@mui/material'
import FilterModelTransaction from '../../components/model/FilterModelTransaction'
import { height } from '@mui/system'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { toast } from 'react-hot-toast'
import { genrateDownloadLink } from '../../utils/utility'
import moment from 'moment/moment'
import urlResolver from '../../utils/urlResolver'
import CustomTooltip from '../../utils/CustomTooltip'
import accessGiven from '../../utils/permission'
import FormatCurrency from './components/formatCurrency'

const Index = () => {
  const [transactionCollection, setTransactionCollection] = useState([])
  const [pageNumber, setPageNumber] = useState(0)
  const [TransactionCount, setTransactionCount] = useState(0)
  const [filterModel, setFilterModel] = useState(false)
  const [search, setSearch] = useState('')
  const [searchedClicked, setSearchedClicked] = useState(false)
  const [loading, setLoading] = useState({
    initial: false,
    loadmore: false
  })
  const [searchValidation, setSearchValidation] = useState(true)
  const [sortColumn, setSortColumn] = useState({
    key: '',
    direction: 'Desc'
  })
  const [loadingAging, setLoadingAging] = useState(false)
  const [loadingDownload, setLoadingDownload] = useState(false)
  const [agingData, setAgingData] = useState([])
  const [filter, setFilter] = useState({
    Org: '',
    InvoiceNo: '',
    ShipmentNo: '',
    PoNo: '',
    BlNo: '',
    fromDueDate: null,
    toDueDate: null,
    fromInvoideDate: null,
    toInvoiceDate: null,
    InvoiceCurrency: ''
  })
  const filterObj = useRef({})
  const permission = accessGiven()

  const inputValidation = input => {
    const regex = new RegExp('^[^<>()*]*$')
    return regex.test(input)
  }

  useEffect(() => {
    getTransactionCollection(0, filter)
    getAgingData(filter, search)
  }, [])
  const downloadAllInvoices = searchtext => {
    setLoadingDownload(true)
    const body = {
      Organisation: filter?.Org ?? '',
      InvoiceNumber: filter?.InvoiceNo ?? '',
      ShipmentId: filter?.ShipmentNo ?? '',
      OrderRef: filter?.PoNo ?? '',
      Billoflading: filter?.BlNo ?? '',

      DueDateFrom: filter?.fromDueDate
        ? moment(new Date(filter.fromDueDate)).format('yyyy-MM-DD HH:mm:ss')
        : null,

      DueDateTo: filter?.toDueDate
        ? moment(new Date(filter.toDueDate)).format('yyyy-MM-DD HH:mm:ss')
        : null,
      InvoiceDateFrom: filter?.fromInvoideDate
        ? moment(new Date(filter.fromInvoideDate)).format('yyyy-MM-DD HH:mm:ss')
        : null,

      InvoiceDateTo: filter?.toInvoiceDate
        ? moment(new Date(filter.toInvoiceDate)).format('yyyy-MM-DD HH:mm:ss')
        : null,

      Currency: filter?.InvoiceCurrency ?? '',

      ShowDeveloperResponse: true,
      DateFrom: '',
      DateTo: '',
      Status: '',
      CurrentPage: 0,
      PageSize: (pageNumber + 1) * 10,
      SortColumnName: '',
      SortDirection: '',
      Search: searchtext?.trim() ?? ''
    }
    instance
      .post(API_PATH.GET_TRANSACTION_DATA, body)
      .then(res => {
        const downloadLink = document.createElement('a')
        const prefix = genrateDownloadLink(res.data.SC_FileName ?? '')
        downloadLink.href = `${prefix}${res.data.ImageDataVC ?? ''}`
        if (prefix && res.data.ImageDataVC) {
          downloadLink.download = res.data.SC_FileName ?? ''
          downloadLink.click()
        } else {
          toast.error('Invalid document found!')
        }
        setLoadingDownload(false)
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoadingDownload(false)
      })
  }
  const getTransactionCollection = (page, filter, searchtext, sort,length=10) => {
    if (page === 0) {
      setLoading({ ...loading, initial: true })
    } else {
      setLoading({ ...loading, loadmore: true })
    }
    const body = {
      Organisation: filterObj.current?.Org ?? '',
      InvoiceNumber: filterObj.current?.InvoiceNo ?? '',
      ShipmentId: filterObj.current?.ShipmentNo ?? '',
      OrderRef: filterObj.current?.PoNo ?? '',
      Billoflading: filterObj.current?.BlNo ?? '',
      DueDateFrom: filterObj.current?.fromDueDate
        ? moment(new Date(filterObj.current.fromDueDate)).format(
            'yyyy-MM-DD HH:mm:ss'
          )
        : null,
      DueDateTo: filterObj.current?.toDueDate
        ? moment(new Date(filterObj.current?.toDueDate)).format(
            'yyyy-MM-DD HH:mm:ss'
          )
        : null,
      InvoiceDateFrom: filterObj.current?.fromInvoideDate
        ? moment(new Date(filterObj.current?.fromInvoideDate)).format(
            'yyyy-MM-DD HH:mm:ss'
          )
        : null,
      InvoiceDateTo: filterObj.current?.toInvoiceDate
        ? moment(new Date(filterObj.current?.toInvoiceDate)).format(
            'yyyy-MM-DD HH:mm:ss'
          )
        : null,
      Currency: filterObj.current?.InvoiceCurrency ?? '',
      EmailID: '',
      Roll: '',
      ShowDeveloperResponse: true,
      DateFrom: '',
      DateTo: '',
      Status: '',
      CurrentPage: page,
      PageSize: length,
      SortColumnName: sort?.key,
      SortDirection: sort?.direction,
      Search: searchtext?.trim() ?? ''
    }
    setPageNumber(page)
    instance
      .post(API_PATH.GET_TRANSACTION_COLLECTION, body)
      .then(res => {
        setTransactionCount(res?.totalCount ?? 0)
        setLoading({ ...loading, initial: false, loadmore: false })
        if (page === 0) {
          setTransactionCollection(res.data)
        } else {
          setTransactionCollection([...transactionCollection, ...res.data])
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoading({ ...loading, initial: false, loadmore: false })
      })
  }

  const getAgingData = (filters, searchtext) => {
    setLoadingAging(true)
    const body = {
      Currency: '',
      Organisation: filters?.Org ?? '',
      InvoiceNumber: filters?.InvoiceNo ?? '',
      ShipmentId: filters?.ShipmentNo ?? '',
      OrderRef: filters?.PoNo ?? '',
      Billoflading: filters?.BlNo ?? '',
      DueDateFrom: filters?.fromDueDate ?? null,
      DueDateTo: filters?.toDueDate ?? null,
      InvoiceDateFrom: filters?.fromInvoideDate ?? null,
      InvoiceDateTo: filters?.toInvoiceDate ?? null,
      EmailID: '',
      Roll: '',
      ShowDeveloperResponse: true,
      DateFrom: '',
      DateTo: '',
      Status: '',
      CurrentPage: 0,
      PageSize: 10,
      SortColumnName: '',
      SortDirection: '',
      Search: searchtext ?? ''
    }
    instance
      .post(API_PATH.GET_AGING_DATA, body)
      .then(res => {
        setAgingData(res.data ?? [])
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoadingAging(false)
      })
  }
  function isObject(value) {
    return typeof value === 'object' && value !== null && !Array.isArray(value)
  }

  return (
    <>
      {filterModel ? (
        <FilterModelTransaction
          filterModel={filterModel}
          setFilterModel={setFilterModel}
          filter={filter}
          setFilter={setFilter}
          getTransactionCollection={getTransactionCollection}
          filterObj={filterObj}
        />
      ) : null}
      <div className='container-fluid'>
        <div className='row mb-3 justify-content-between statement-header'>
          <div className='col-md-7 col-xs-12 mr-3 card-top'>
            <div
              className='d-flex justify-content-center align-items-center font-weight-bold pb-3 mb-1 font-shipment'
              style={{ color: 'var(--color-black)' }}
            >
              Aged Outstanding{'  '}
              <CustomTooltip
                of='Statement_Of_Account'
                forKey='Aged_Outstanding'
              />
            </div>
            {loadingAging ? (
              <div className='skeleton-div'>
                <Skeleton style={{ height: '6rem' }} />
              </div>
            ) : (
              <>
                {agingData.length ? (
                  <Grid container spacing={2} className='aging-statment'>
                    {agingData?.map((item, index) => {
                      return (
                        <Grid
                          item
                          lg={3}
                          md={3}
                          sm={6}
                          xs={6}
                          sx={{
                            color: 'var(--color-black)',
                            borderRight: '1px solid var(--color-primary-light)'
                          }}
                          className='p-1'
                          key={index}
                        >
                          <Typography
                            className='d-flex justify-content-center align-items-center font-weight-bold  font-shipment'
                            sx={{
                              borderBottom:
                                '1px solid var(--color-primary-light)'
                            }}
                          >
                            {item.period === 1
                              ? '0 - 30'
                              : item.period === 2
                              ? '31-60'
                              : item.period === 3
                              ? '61-90'
                              : item.period === 4
                              ? '90+'
                              : null}
                          </Typography>

                          {item.currencyInfo.map((i, ind) => {
                            return (
                              <Typography className='px-3 font-shipment'>{`${
                                i.currency
                              } ${(Math.round(i.amount * 100) / 100).toFixed(
                                2
                              )} `}</Typography>
                            )
                          })}
                        </Grid>
                      )
                    })}
                  </Grid>
                ) : (
                  <div className='d-flex justify-content-center align-items-center font-change'>
                    No data found!
                  </div>
                )}
              </>
            )}
          </div>
          <div className='col-md-4 col-xs-12 search-parent-div'>
            <div className='headersearch searchShipmentDiv '>
              <input
                type='text'
                className='form-control '
                placeholder='Search Invoice no/ Job no/ PO no/ HBL no'
                value={search}
                onChange={e => {
                  setSearch(e.target.value)
                }}
                onKeyPress={e => {
                  const validation = inputValidation(search)
                  setSearchValidation(validation)
                  if (e.key === 'Enter' && validation) {
                    setPageNumber(0)
                    setSearchedClicked(true)
                    getTransactionCollection(0, filter, search)
                    getAgingData(filter, search)
                  }
                }}
              />
              <img
                src={urlResolver('assets/search_icon.svg')}
                onClick={e => {
                  const validation = inputValidation(search)
                  setSearchValidation(validation)
                  if (search && validation) {
                    setSearchedClicked(true)
                    getTransactionCollection(0, filter, search)
                    getAgingData(filter, search)
                  }
                }}
                style={{ color: 'rgba(var(--text-heading-dark))' }}
                className='searchIcon'
              />
              {search && (
                <CloseOutlinedIcon
                  style={{
                    position: 'absolute',
                    right: '1rem',
                    cursor: 'pointer'
                  }}
                  className='searchClearIcon'
                  onClick={() => {
                    setSearchValidation(true)

                    setSearch('')
                    setSearchedClicked(false)
                    getTransactionCollection(0, filter, '')
                    getAgingData(filter, '')
                  }}
                />
              )}
            </div>
            {inputValidation(search) ? (
              ''
            ) : (
              <div
                className='validation-error font-shipment'
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                Special characters are not allowed.
              </div>
            )}
            <div className='d-flex justify-content-end  pt-3'>
              <div className='me-2'>
                <div
                  className={`d-flex btn-filter ${
                    Object.values(filterObj.current).filter(element => {
                      if (element?.length > 0 || isObject(element)) return 1
                    }).length > 0
                      ? 'color-style'
                      : ''
                  }`}
                >
                  <div
                    className='d-flex justify-content-center align-items-center '
                    onClick={() => {
                      setFilterModel(true)
                    }}
                  >
                    <FilterListIcon
                      color={`${
                        Object.values(filterObj.current).filter(element => {
                          if (element?.length > 0 || isObject(element)) return 1
                        }).length > 0
                          ? 'third'
                          : 'primary'
                      }`}
                    />
                    <Typography
                      className={` ${
                        Object.values(filterObj.current).filter(element => {
                          if (element?.length > 0 || isObject(element)) return 1
                        }).length > 0
                          ? 'color-white font-shipment'
                          : 'color-primary font-shipment'
                      }`}
                      // color={`${
                      //   Object.values(filterObj.current).filter((element) => {
                      //     if (element?.length > 0) return 1;
                      //   }).length > 0
                      //     ? "third"
                      //     : "primary"
                      // }`}
                    >
                      Filter
                    </Typography>
                  </div>

                  {Object.values(filterObj.current).filter(element => {
                    if (element?.length > 0 || isObject(element)) return 1
                  }).length > 0 && (
                    <div className='d-flex ps-2'>
                      <div style={{ border: '1px solid white' }} />
                      <CloseOutlinedIcon
                        color='third'
                        onClick={() => {
                          filterObj.current = {
                            Org: '',
                            InvoiceNo: '',
                            ShipmentNo: '',
                            PoNo: '',
                            BlNo: '',
                            fromDueDate: null,
                            toDueDate: null,
                            fromInvoideDate: null,
                            toInvoiceDate: null,
                            InvoiceCurrency: ''
                          }
                          setFilter({
                            Org: '',
                            InvoiceNo: '',
                            ShipmentNo: '',
                            PoNo: '',
                            BlNo: '',
                            fromDueDate: null,
                            toDueDate: null,
                            fromInvoideDate: null,
                            toInvoiceDate: null,
                            InvoiceCurrency: ''
                          })
                          getTransactionCollection(0, filter, '')
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              {permission?.exportToExcel && (
                <div className='btn-dash'>
                  <Button
                    variant='outlined'
                    color='primary'
                    sx={{
                      borderRadius: '20px',
                      minWidth: '10rem',
                      border: '1px solid var(--color-secondary-light)'
                    }}
                    onClick={() => downloadAllInvoices(search)}
                  >
                    {!loadingDownload ? (
                      <Typography color='primary'>
                        Export to excel{' '}
                        <CustomTooltip
                          of='Statement_Of_Account'
                          forKey='Export_to_excel'
                        />
                      </Typography>
                    ) : (
                      <>
                        <Typography>Exporting...</Typography>
                        <CircularProgress
                          size='1rem'
                          style={{
                            color: 'var(--color-miscellaneous-4)'
                          }}
                        />
                      </>
                    )}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>

        {loading.initial ? (
          <div className='skeleton-div'>
            <Skeleton style={{ height: '4rem' }} />
            <Skeleton style={{ height: '4rem' }} />
            <Skeleton style={{ height: '4rem' }} />
            <Skeleton style={{ height: '4rem' }} />
            <Skeleton style={{ height: '4rem' }} />
          </div>
        ) : (
          <div style={{ margin: '-0.5rem' }}>
            <Tableview
              getTransactionCollection={getTransactionCollection}
              setSortColumn={setSortColumn}
              sortColumn={sortColumn}
              data={transactionCollection}
              TransactionCount={TransactionCount}
              pageNumber={pageNumber}
              filter={filter}
              search={search}
            />
            {transactionCollection.length === TransactionCount ? null : (
              <BtnOutline
                title={
                  loading.loadmore ? (
                    <CircularProgress
                      size='1rem'
                      style={{ color: 'var(--color-miscellaneous-4)' }}
                    />
                  ) : (
                    'Show More'
                  )
                }
                onClick={() => {
                  const page = parseInt(pageNumber) + 1
                  getTransactionCollection(page, filter, search, sortColumn)
                  setPageNumber(page)
                }}
              />
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default Index
