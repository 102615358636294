import * as React from "react";
// import Accordion from "@mui/material/Accordion";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import Typography from "@mui/material/Typography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MarkdownRenderer from "../../../common/MarkdownRenderer ";

export default function InfoAccordian({ Info }) {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [data, setData] = React.useState({
    "Features Added": [],
    Improvements: [],
    "Bugs Fixed": [],
    "Performance Enhancements": [],
  });
  React.useEffect(() => {
    const DATA = {
      "Features Added": Info["Features Added"],
      Improvements: Info["Improvements"],
      "Bugs Fixed": Info["Bugs Fixed"],
      "Performance Enhancements": Info["Performance Enhancements"],
    };
    setData(DATA);
  }, [Info]);
  return (
    <div>
      {Object.keys(data)?.map((singleKey) => {
        if (data[singleKey].length != 0)
          return (
            <div>
              <div className="heading">{singleKey}</div>
              <div className="innerContent">
                {data[singleKey]?.map((information) => (
                  <MarkdownRenderer data={information} />
                ))}
              </div>
            </div>
          );
      })}
    </div>
  );
}
// in case we need this informaiton in accordion to
// return (
//   <Accordion
//     expanded={expanded === singleKey}
//     onChange={handleChange(singleKey)}
//   >
//     <AccordionSummary
//       expandIcon={<ExpandMoreIcon />}
//       aria-controls="panel1bh-content"
//       id="panel1bh-header"
//     >
//       <Typography sx={{ width: "50%", flexShrink: 0 }}>
//         {singleKey}
//       </Typography>
//     </AccordionSummary>
//     <AccordionDetails>
//       <Typography>
//         {data[singleKey].map((information) => {
//           return <MarkdownRenderer data={information} />;
//         })}
//       </Typography>
//     </AccordionDetails>
//   </Accordion>
// );
