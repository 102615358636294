import React, { useEffect } from 'react'
import { basename } from '../../Config'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import './Card.css'
import { Divider } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { Routeconstant } from '../../routing/utils/RouteConstant'
import { DateFormat } from '../../utils/moment'
import { Box, styled } from '@mui/material'
import { useState } from 'react'
import urlResolver from '../../utils/urlResolver'
import { useContext } from 'react'
import { AuthContext } from '../../store/context/AuthProvider'
import { clsx } from 'clsx'
import VerticalProgressBar from '../verticalProgressBar/VerticalProgressBar'
const Vertical = styled(Box)(({ theme }) => ({
  height: '6rem',
  width: '0px ',
  border: '10.2px solid #286085',
  borderRadius: '5px',
  borderStyle: 'ridge grey',
  transform: 'scaleX(0.2)'
}))

const Card = ({
  baseroute,
  data,
  markFavourite,
  setCheckActive,
  index,
  checkActive
}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const context = useContext(AuthContext)
  const [loadingStar, setLoadingStar] = useState(false)
  useEffect(() => {
    context.setHeaderTab(true)
    return () => {
      context.setHeaderTab(false)
    }
  }, [])
  const inTransitProgress = () => {
    const destinationDate = new Date(data?.ETADate)
    const originDate = new Date(data?.ETDDate)
    const currentDate = new Date()
    if (currentDate > destinationDate) return 100
    const totalDays = Math.floor(
      (destinationDate - originDate) / (1000 * 60 * 60 * 24)
    ) // Calculate total days
    const daysRemaining = Math.floor(
      (destinationDate - currentDate) / (1000 * 60 * 60 * 24)
    ) // Calculate remaining days
    const currentProgress = totalDays - daysRemaining
    return currentProgress
  }
  const percentTageOfprogress = () => {
    return data.ShipmentStatus === 1
      ? 0
      : data.ShipmentStatus === 2
      ? inTransitProgress(data?.ETDDate, data?.ETDDate)
      : 100
  }
  const StatusDate = props => {
    // console.log(props, "props");

    if (data?.ShipmentStatus === 1) {
      return (
        <p
          className='m-0 origin-date '
          title={DateFormat(props?.data?.ETDDate ?? null)}
        >
          <span className='pe-2'>ETD:</span>
          {DateFormat(props?.data?.ETDDate ?? null)}
        </p>
      )
    } else if (data?.ShipmentStatus === 2) {
      return (
        <p
          className='m-0 origin-date '
          title={DateFormat(props?.data?.LoadPortATDDate ?? null)}
        >
          <span className='pe-2'>ATD:</span>

          {DateFormat(props?.data?.LoadPortATDDate ?? null)}
        </p>
      )
    } else if (data?.ShipmentStatus === 3) {
      return (
        <p
          className='m-0 origin-date '
          title={DateFormat(props?.data?.LoadPortATDDate ?? null)}
        >
          <span className='pe-2'>ATD:</span>
          {DateFormat(props?.data?.LoadPortATDDate ?? null)}
        </p>
      )
    } else if (data?.ShipmentStatus === 4) {
      return (
        <p
          className='m-0 origin-date '
          title={DateFormat(props?.data?.LoadPortATDDate ?? null)}
        >
          <span className='pe-2'>ATD:</span>
          {DateFormat(props?.data?.LoadPortATDDate ?? null)}
        </p>
      )
    } else {
    }
  }
  const TempStatusDate = props => {
    if (data.ShipmentStatus === 1) {
      return (
        <p
          className='m-0 origin-date '
          title={DateFormat(props?.data?.ETADate ?? null)}
        >
          {' '}
          <span className='pe-2'> ETA:</span>
          {DateFormat(props.data?.ETADate ?? null)}
        </p>
      )
    } else if (data.ShipmentStatus === 2) {
      return (
        <p
          className='m-0 origin-date '
          title={DateFormat(props?.data?.ETADate ?? null)}
        >
          <span className='pe-2'>ETA:</span>

          {DateFormat(props?.data?.ETADate ?? null)}
        </p>
      )
    } else if (data.ShipmentStatus === 3) {
      return (
        <p
          className='m-0 origin-date '
          title={DateFormat(props?.data?.DischargePortATADate ?? null)}
        >
          <span className='pe-2'>ATA:</span>

          {DateFormat(props?.data?.DischargePortATADate ?? null)}
        </p>
      )
    } else if (data.ShipmentStatus === 4) {
      return (
        <p
          className='m-0 origin-date '
          title={DateFormat(props?.data?.DischargePortATADate ?? null)}
        >
          <span className='pe-2'>ATA:</span>
          {DateFormat(props?.data?.DischargePortATADate ?? null)}
        </p>
      )
    } else {
    }
  }
  return (
    <div>
      <div
        className={`card-div p-2 mb-2 ${
          checkActive === index ? 'border-card-light' : ''
        }`}
      >
        <div className='mb-2 div-data'>
          <div className='d-flex justify-content-between align-items-center mb-3'>
            <div>
              {location.pathname.includes(Routeconstant.SHIPMENT_LISTING) ? (
                <p className='m-0 num-data' title={data?.ShipmentId ?? 'N/A'}>
                  {data?.ShipmentId ?? 'N/A'}
                </p>
              ) : location.pathname.includes(Routeconstant.ORDER_LISTING) ? (
                <p
                  className='m-0 num-data'
                  title={`${data?.OrderNumber ?? 'N/A'}/${
                    data?.OrderNumberSplit ?? 'N/A'
                  }`}
                >
                  {`${data?.OrderNumber ?? 'N/A'}/${
                    data?.OrderNumberSplit ?? 'N/A'
                  }`}
                </p>
              ) : null}
            </div>
            <div className='d-flex justify-content-center gap-2 align-items-center'>
              <ul className='mb-0 result-div'>
                {data.ShipmentStatus === 1 ? (
                  <li>Pre-Loaded</li>
                ) : data.ShipmentStatus === 2 ? (
                  <li>In-Transit</li>
                ) : data.ShipmentStatus === 3 ? (
                  <li>Arrived</li>
                ) : data.ShipmentStatus === 4 ? (
                  <li>Archived</li>
                ) : null}
              </ul>

              {/* start icon code */}
              <img
                alt=''
                style={{ cursor: 'pointer' }}
                className={clsx('img-star', loadingStar && 'flash')}
                src={urlResolver(
                  `assets/${data.IsFavourite ? 'coloredStar.svg' : 'star.svg'}`
                )}
                onClick={() => {
                  if (!loadingStar) {
                    markFavourite(
                      data?.ShipmentId,
                      data.IsFavourite ? false : true,
                      setLoadingStar
                    )
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-around align-items-center'>
          <div
            className='d-flex flex-column 
           
            align-items-end'
            style={{
              height: '8rem',
              justifyContent: 'space-between'
            }}
          >
            <p className=' text-origin'>Origin</p>
            <p className=' m-0 text-origin'>Destination</p>
          </div>
          <div
            style={{
              position: 'relative'
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '-23%',
                left: '5px'
              }}
            >
              <svg width='10' height='12' viewBox='0 0 23 25' fill='none'>
                <ellipse
                  cx='11.5'
                  cy='12.5'
                  rx='11.5'
                  ry='12.5'
                  fill='#286085'
                />
              </svg>
            </div>
            <Vertical />
            <div
              style={{
                position: 'absolute',
                top: '94%',
                left: '5px'
              }}
            >
              <svg width='10' height='12' viewBox='0 0 23 25' fill='none'>
                <ellipse
                  cx='11.5'
                  cy='12.5'
                  rx='11.5'
                  ry='12.5'
                  fill='#286085'
                />
              </svg>
            </div>
          </div>

          {/* progress bar vertical line */}
          {/* <div className="d-flex  flex-column justify-content-center align-items-center" style={{ gap: "2px", margin: "0px 8px" }}>
            <div className="d-flex ">
              <svg width="10" height="12" viewBox="0 0 23 25" fill="none">
                <ellipse
                  cx="11.5"
                  cy="12.5"
                  rx="11.5"
                  ry="12.5"
                  fill="#286085"
                />
              </svg>
            </div>
            <VerticalProgressBar percent={percentTageOfprogress(data.ShipmentStatus)} />
            <div className="d-flex ">
              <svg width="10" height="12" viewBox="0 0 23 25" fill="none">
                <ellipse
                  cx="11.5"
                  cy="12.5"
                  rx="11.5"
                  ry="12.5"
                  fill="#286085"
                />
              </svg>
            </div>
          </div> */}

          <div className='d-flex justify-content-between align-items-center flex-grow-1'>
            <div
              style={{
                height: '8rem',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column'
              }}
            >
              <div>
                <p
                  className='m-0 origin-num word-ellipsis'
                  title={data?.OriginPortName ?? 'N/A'}
                >
                  {data?.OriginPortName ?? 'N/A'}
                </p>
                <p className='m-0 origin-date '>
                  <StatusDate data={data} />

                  {/* {DateFormat(data?.ETDDate ?? null)} */}
                </p>
                <p
                  className=' origin-date '
                  title={data?.ConsignorName ?? 'N/A'}
                >
                  {data?.ConsignorName ?? 'N/A'}
                </p>
              </div>
              <div>
                <p
                  className='m-0 origin-num word-ellipsis'
                  title={data?.DestinationPortName ?? ''}
                >
                  {' '}
                  {data?.DestinationPortName ?? ''}
                </p>
                <p className='m-0 origin-date'>
                  <TempStatusDate data={data} />
                </p>
                <p
                  className=' origin-date'
                  title={data?.ConsigneeName ?? 'N/A'}
                >
                  {data?.ConsigneeName ?? 'N/A'}
                </p>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {data?.TransportMode === 'SEA' ? (
                <img
                  className='img-transport'
                  src={urlResolver('assets/ship.svg')}
                />
              ) : data?.TransportMode === 'AIR' ? (
                <img
                  className='img-transport'
                  src={urlResolver('assets/Air.svg')}
                />
              ) : data?.TransportMode === 'RAI' ? (
                <img
                  className='img-transport'
                  src={urlResolver('assets/rail.svg')}
                />
              ) : data?.TransportMode === 'ROA' ? (
                <img
                  className='img-transport'
                  src={urlResolver('assets/road.svg')}
                />
              ) : data?.TransportMode === 'COU' ? (
                <img
                  className='img-transport'
                  src={urlResolver('assets/courier.svg')}
                />
              ) : null}
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center'
                }}
              >
                <p
                  className='origin-date fw-bold '
                  style={{ marginRight: '50px' }}
                  title={data?.ContainerMode}
                >
                  {data?.ContainerMode}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Accordion className='accordian-item'>
            <AccordionSummary
              className='icon-expand-div'
              expandIcon={<ExpandMoreIcon className='icon-expand' />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography className='typo-text'>Show details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div
                className='d-flex justify-content-between align-items-center details-div'
                onClick={() => {
                  context.setShipmentId(data?.ShipmentId)
                  context?.setEmissions([
                    { CO2: '', loading: false },
                    { lune: '', loading: false }
                  ])
                  setCheckActive(index)
                  navigate(`${baseroute}/${Routeconstant.DETAILS}`, {
                    state: {
                      ShipmentId: data.ShipmentId,
                      data: data,
                      TransportMode: data.TransportMode
                      // HouseBillNumber: data.HouseBillNumber,
                    }
                  })
                }}
              >
                <p className='m-0 py-1 item-detail '>Basic Details</p>
                <img src={urlResolver('assets/side_arrow.svg')} />
              </div>
              <div
                className='d-flex justify-content-between align-items-center details-div '
                onClick={() => {
                  context.setShipmentId(data?.ShipmentId)
                  setCheckActive(index)
                  navigate(`${baseroute}/${Routeconstant.ROUTING}`, {
                    state: {
                      ShipmentId: data.ShipmentId,
                      // ShipmentTransportMode: data.TransportMode,
                      data: data
                      // HouseBillNumber: data.HouseBillNumber,
                    }
                  })
                }}
              >
                <p className='m-0 py-1 item-detail'>Route Details</p>
                <img src={urlResolver('assets/side_arrow.svg')} alt='' />
              </div>
              <div
                className='d-flex justify-content-between align-items-center details-div'
                onClick={() => {
                  context.setShipmentId(data?.ShipmentId)
                  setCheckActive(index)
                  navigate(`${baseroute}/${Routeconstant.CARGO_DETAILS}`, {
                    state: {
                      ShipmentId: data.ShipmentId,
                      TransportMode: data.TransportMode,
                      HouseBillNumber: data.HouseBillNumber,
                      data: data
                    }
                  })
                }}
              >
                <p className='m-0 py-1 item-detail'>Cargo Details</p>
                <img src={urlResolver('assets/side_arrow.svg')} alt='' />
              </div>
              <div
                className='d-flex justify-content-between align-items-center details-div'
                onClick={() => {
                  setCheckActive(index)
                  context.setShipmentId(data?.ShipmentId)
                  navigate(`${baseroute}/${Routeconstant.DOCUMENTATION}`, {
                    state: {
                      ShipmentId: data.ShipmentId,
                      TransportMode: data.TransportMode,
                      HouseBillNumber: data.HouseBillNumber
                    }
                  })
                }}
              >
                <p className='m-0 py-1 item-detail'>Documentation</p>
                <img src={urlResolver('assets/side_arrow.svg')} alt='' />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  )
}

export default Card
