
export const formatCustomNumber = (amount) => {
    if (!amount) return '';
  
    let amountStr = amount.toString().replace(/,/g, ''); // Remove any existing commas
  
    let [integerPart, decimalPart] = amountStr.split('.');
  
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  
    if (decimalPart) {
      decimalPart = decimalPart.padEnd(3, '0').replace(/(\d{3})(?=\d)/g, '$1,');
    } else {
      decimalPart = '000'; // Ensure there are always three decimal places
    }
  
    return decimalPart ? `${integerPart},${decimalPart}` : integerPart;
  };
  