import React, { useRef, useState } from "react";
import {
  Autocomplete,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BarChart from "../../../../charts/CarrierAnalysis/BarChart";
import BarChartByTime from "../../../../charts/CarrierAnalysis/BarChartByTime";
import TannagePieChart from "../../../../charts/CarrierAnalysis/TannagePieChart";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Fade from "@mui/material/Fade";
import ExploreIcon from "@mui/icons-material/Explore";
import CustomTooltip from "../../../../utils/CustomTooltip";
export default function CarrierAnalysis(props) {
  let handleClickOpen = props.handleClickOpen;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [tanType, setTanType] = useState("TEU");
  const [originval, setOriginval] = useState("");
  const [destinationval, setDestinationval] = useState("");
  const open = Boolean(anchorEl);
  const [averageTransitTime, setAverageTransitTime] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setUnits = (param) => {
    setTanType(param);
    handleClose();
  };

  return (
    <Container
      maxWidth="xxl"
      sx={{
        backgroundColor: "var(--color-white)",
        borderRadius: "5px",
      }}
    >
      <Typography variant="h6" className="font-shipment">
        Carrier Analysis
      </Typography>
      <Divider />
      <Grid container spacing={4}>
        {/* Charts */}
        <Grid
          item
          lg={12}
          sx={{
            marginTop: "1rem",
          }}
        >
          <Grid container spacing={2}>
            {/* CHART 1 */}
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <div
                className="paper-chart font-shipment"
                style={{ overflowX: "scroll", overflowY: "hidden" }}
              >
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <div>
                    <Typography
                      variant="subtitle2"
                      color={"primary"}
                      className="font-shipment"
                    >
                      No. of shipment mode wise{" "}
                      <CustomTooltip of="Carrier_Analysis" forKey="Mode_wise" />
                    </Typography>
                  </div>
                  <IconButton size={"small"}>
                    <ZoomOutMapIcon
                      onClick={() => {
                        handleClickOpen(
                          " No. of shipment mode wise",
                          <BarChart
                            filterOptions={props.filterOptions}
                            checkPop={true}
                          />
                        );
                      }}
                    />
                  </IconButton>
                </div>

                <BarChart
                  filterOptions={props.filterOptions}
                  checkPop={false}
                />
              </div>
              {/* </Paper> */}
            </Grid>
            {/* CHART 2 */}
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <div
                className="paper-chart"
                style={{
                  position: "relative",
                }}
              >
                {averageTransitTime && (
                  <div
                    style={{
                      minWidth: "300px",
                      position: "absolute",
                      zIndex: "5",
                      padding: "1rem",
                      width: "50%",
                      backgroundColor: "#fff",
                      top: "20%",
                      right: "15%",
                      boxShadow:
                        "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                    }}
                  >
                    <Autocomplete
                      id="origin"
                      Origin
                      // inputValue={originval}
                      options={props?.countryList}
                      getOptionLabel={(option) => option.Description}
                      onChange={(e, val) => {
                        setOriginval(val.Code);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Origin" />
                      )}
                      style={{marginBottom: '1rem'}}
                    />
                    <Autocomplete
                      id="destination"
                      // inputValue={destinationval}
                      Destination
                      options={props?.countryList}
                      getOptionLabel={(option) => option.Description}
                      onChange={(e, val) => {
                        setDestinationval(val.Code);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Destination" />
                      )}
                      style={{marginBottom: '1rem'}}
                    />
                    <Stack direction="row">
                      <div
                        style={{
                          paddingRight: "0.5rem",
                          width: "100%",
                        }}
                      >
                        <Button
                          variant="contained"
                          fullWidth
                          onClick={() =>
                            setAverageTransitTime(!averageTransitTime)
                          }
                        >
                          Apply
                        </Button>
                      </div>
                      <div>
                        <Button
                          fullWidth
                          variant="contained"
                          color="error"
                          onClick={() => {
                            setOriginval("");
                            setDestinationval("");
                            setAverageTransitTime(!averageTransitTime);
                          }}
                        >
                          {originval.length > 1 && destinationval.length > 1
                            ? "Reset"
                            : "Close"}
                        </Button>
                      </div>
                    </Stack>
                  </div>
                )}

                <div className="d-flex justify-content-between align-items-center mb-1">
                  <div>
                    <Typography variant="subtitle2" color={"primary"}>
                      Average transit time{" "}
                      <CustomTooltip
                        of="Carrier_Analysis"
                        forKey="Transit_time"
                      />
                    </Typography>
                  </div>
                  <div>
                    {originval === "" && destinationval === "" ? null : (
                      <Chip
                        label={`${originval} to ${destinationval}`}
                        size="small"
                        color="primary"
                      />
                    )}

                    <IconButton
                      size={"small"}
                      onClick={() => setAverageTransitTime(!averageTransitTime)}
                    >
                      <ExploreIcon />
                    </IconButton>
                    <IconButton size={"small"}>
                      <ZoomOutMapIcon
                        onClick={() => {
                          handleClickOpen(
                            "Average transit time.",
                            <BarChartByTime
                              countryList={props.countryList}
                              filterOptions={props.filterOptions}
                              originval={originval}
                              destinationval={destinationval}
                              checkPop={true}
                            />
                          );
                        }}
                      />
                    </IconButton>
                  </div>
                </div>

                <BarChartByTime
                  countryList={props.countryList}
                  filterOptions={props.filterOptions}
                  originval={originval}
                  destinationval={destinationval}
                />
              </div>
            </Grid>

            <Grid item lg={4} md={6} sm={6} xs={12}>
              <div className="paper-chart">
                <div className="d-flex justify-content-between align-items-center mb-1">
                  <div>
                    <Typography
                      variant="subtitle2"
                      color={"primary"}
                      className="font-shipment"
                    >
                      Tonnage carrier wise{" "}
                      <CustomTooltip of="Carrier_Analysis" forKey="Tonnage" />
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Chip
                      label={tanType == "" ? "CHW" : tanType}
                      size="small"
                      color="primary"
                    />
                    <IconButton size={"small"} onClick={handleClick}>
                      <FilterAltIcon />
                    </IconButton>
                    <Menu
                      id="fade-menu"
                      MenuListProps={{
                        "aria-labelledby": "fade-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      TransitionComponent={Fade}
                    >
                      <MenuItem
                        onClick={() => setUnits("TEU")}
                        selected={tanType == "TEU"}
                      >
                        TEU
                      </MenuItem>
                      <MenuItem
                        onClick={() => setUnits("VOL")}
                        selected={tanType == "VOL"}
                      >
                        VOL
                      </MenuItem>
                      <MenuItem
                        onClick={() => setUnits("")}
                        selected={tanType == ""}
                      >
                        CHW
                      </MenuItem>
                    </Menu>

                    <IconButton size={"small"}>
                      <ZoomOutMapIcon
                        onClick={() => {
                          handleClickOpen(
                            "Tonnage",
                            <TannagePieChart
                              TanCheckPop={true}
                              filterOptions={props.filterOptions}
                              tanType={tanType}
                            />
                          );
                        }}
                      />
                    </IconButton>
                  </div>
                </div>

                <TannagePieChart
                  TanCheckPop={false}
                  filterOptions={props.filterOptions}
                  tanType={tanType}
                />
                {/* </Paper> */}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
