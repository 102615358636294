import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
  Tooltip,
} from "chart.js";

import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import instance from "../../api/Interceptor";
import { API_PATH } from "../../constant/apiPath";
import { Bar } from "react-chartjs-2";
import { Box } from "@mui/system";
import NodatafoundComp from "../NodatafoundComp";
import { useContext } from "react";
import { AuthContext } from "../../store/context/AuthProvider";

// ChartJS.register(BarElement, CategoryScale, LinearScale, Legend, Tooltip);
const TannagePieChart = ({ TanCheckPop, filterOptions, tanType }) => {
  const colors = [["#ECF5FF"], ["#236C9C"], ["#0A2D44"]];
  const [chartData, setChartData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [unit, setUnit] = useState();
  const [type, setType] = useState();
  const [labelsForPopUp, setLabelsForPopUp] = useState([]);
  const [nodataFound, setNoDataFound] = useState(false);
  const context = useContext(AuthContext);
  // console.log("TanCheckPop", TanCheckPop);
  const getHalfPieChart = () => {
    const body = {
      ShpEtdFrom: "",
      ShpEtdTo: "",
      ShpEtaFrom: "",
      ShpEtaTo: "",
      ShpTransportMode: filterOptions?.filterMode
        ? filterOptions?.filterMode
        : "AIR",
      ShpOriginPortCode: "",
      ShpOriginPortName: "",
      ShpDestinationPortCode: "",
      ShpConsignorCode: "",
      ShpConsigneeCode: "",
      TunnageType: tanType,
      ShpCarrierCode: filterOptions?.filterCarrier
        ? filterOptions?.filterCarrier
        : "",
      ShpCarrierName: "",
      Year: filterOptions?.filterYear ? parseInt(filterOptions?.filterYear) : 0,
    };
    instance
      .post(API_PATH.TANNAGE_BY_CARRIER, body)
      .then((res) => {
        if (res.data.length) {
          setNoDataFound(false);
          // console.log(res.data, "res.datares.datares.datares.data");
          res.data.sort((a, b) => {
            // Compare CarrierName first
            if (a.CarrierName === null && b.CarrierName !== null) {
              return 1; // Move item with null CarrierName to the end
            }
            if (a.CarrierName !== null && b.CarrierName === null) {
              return -1; // Move item with null CarrierName to the end
            }

            // If CarrierName is the same or not null, compare CarrierCode
            if (a.CarrierCode === null && b.CarrierCode !== null) {
              return 1; // Move item with null CarrierCode to the end
            }
            if (a.CarrierCode !== null && b.CarrierCode === null) {
              return -1; // Move item with null CarrierCode to the end
            }

            return 0; // Keep the order unchanged
          });
          let tempLabel = res.data.map((indvData) => indvData.CarrierName);
          let temp = res.data.map((indvData) => indvData.Totalvalue);
          const unit = res.data[0].Unit;
          setUnit(unit);
          if (unit === "KG") setType("CHW");
          else if (unit == "Teu") setType("TEU");
          else {
            setType("VOL");
            setUnit("m³");
          }
          // to avoid zero
          let temp2 = [];
          let tempLabel2 = [];
          tempLabel.forEach((item, index) => {
            if (temp[index] !== 0) {
              temp2.push(temp[index]);
              if (tempLabel[index] === null) {
                tempLabel2.push("N/A");
              } else {
                tempLabel2.push(tempLabel[index]);
              }
            }
          });

          temp = temp2;
          tempLabel = tempLabel2;

          setChartData(temp);
          setLabelsForPopUp(tempLabel);

          let tempTrimLab = tempLabel.slice(0, 4).map((label) => {
            if (label === null) {
              return "N/A";
            } else {
              return label?.slice(0, 8) + "...";
            }
          });
          setLabels(tempTrimLab);
        } else {
          setNoDataFound(true);
        }

        // prepareData(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
        // toast.error(err);
      });
  };
  useEffect(() => {
    if (context.filterLoaded) getHalfPieChart();
  }, [filterOptions, tanType,context.filterLoaded]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    // plugins: {
    //   legend: false,
    //   title: {
    //     display: false,
    //     text: "Chart.js Bar Chart",
    //   },
    // },
    plugins: {
      legend: false, // Hide legend
      tooltip: {
        yAlign: "bottom",
        callbacks: {
          title: (tooltipItems) => {
            return labelsForPopUp[tooltipItems[0].parsed.x];
          },
        },
      },
    },
  };

  const data = {
    labels: TanCheckPop
      ? labelsForPopUp.map((lable) => {
          if (lable?.length < 15) return lable;
          return lable?.slice(0, 15) + "...";
        })
      : labels,
    datasets: [
      {
        data: chartData,
        // backgroundColor: colors,
        backgroundColor: "rgb(35,108,156)",
        label: `${type} (${unit})`,
        barPercentage: 1,
      },
    ],
  };

  return (
    <>
      {nodataFound ? (
        <>
          <NodatafoundComp />
        </>
      ) : (
        <div
          // className={
          //   TanCheckPop && labelsForPopUp.length > 4 ? "chartAreaWrapper" : ""
          // }
          style={{
            height: "100%",
            width: TanCheckPop && `${13 * chartData?.length}em`,
          }}
        >
          <Bar
            style={{
              minHeight: TanCheckPop && "500px",
              maxHeight: "90%",
              // width: "100%",
            }}
            options={{
              animation: !TanCheckPop
                ? false
                : {
                    x: {
                      duration: 1000,
                      from: 3000,
                    },
                    y: {
                      duration: 1000,
                      from: 3000,
                    },
                  },
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: false, // Hide legend
                tooltip: {
                  yAlign: "bottom",
                  callbacks: {
                    title: (tooltipItems) => {
                      return labelsForPopUp[tooltipItems[0].parsed.x];
                    },
                  },
                },
              },
            }}
            data={data}
          />
        </div>
      )}
    </>
  );
};

export default TannagePieChart;
