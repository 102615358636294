import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Routeconstant } from '../../routing/utils/RouteConstant'
import { AuthContext } from '../../store/context/AuthProvider'
import { basename, customerName, SECRET_KEY } from '../../Config'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import MailIcon from '@mui/icons-material/Mail'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import './layout.css'
import instance from '../../api/Interceptor'
import { API_PATH } from '../../constant/apiPath'
import Tooltip from '@mui/material/Tooltip'
import ShareIcon from '@mui/icons-material/Share'
import Slide from '@mui/material/Slide'
import CryptoJS from 'crypto-js'
import { Alert, Button, CircularProgress, Snackbar } from '@mui/material'
import urlResolver from '../../utils/urlResolver'
function SlideTransition(props) {
  return <Slide {...props} direction='up' />
}
function LayoutDetail(props) {
  const location = useLocation()
  const context = useContext(AuthContext)
  const [whatsappNumber, setWhatsappNumber] = useState('')
  const [emailContact, setemailContact] = useState('')
  const [open, setOpen] = React.useState(false)

  const handleClick = () => {
    setOpen(true)
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  // const [subscription, setSubscription] = useState("");
  // const postShipmentSubscription = () => {
  //   const body = {
  //     ShipmentId: location?.state?.ShipmentId,
  //     ShowDeveloperResponse: true,
  //   };
  //   instance
  //     .post(API_PATH.POST_SHIPMENT_SUBSCRIPTION, body)
  //     .then((res) => {
  //       setSubscription(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     })
  //     .finally(() => {
  //       // setLoadingAging(false);
  //     });
  // };

  const getEmailContacts = () => {
    const body = {
      ShipmentId: location?.state?.ShipmentId,
      ShowDeveloperResponse: true
    }
    instance
      .post(API_PATH.GET_EMAIL_CONTACTS, body)
      .then(res => {
        setemailContact(res.data[0].EmailId)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const getWhatsappNumber = () => {
    const body = {
      ShipmentId: location?.state?.ShipmentId,
      ShowDeveloperResponse: true
    }
    instance
      .post(API_PATH.GET_WHATSAPP_NUMBER, body)
      .then(res => {
        setWhatsappNumber(res.data[0].ContactNumber)
      })
      .catch(err => {
        console.log(err)
      })
  }
  useEffect(() => {
    if (context.gridViewShipment) {
      getWhatsappNumber()
      getEmailContacts()
    }
    // postShipmentSubscription();
  }, [location?.state?.ShipmentId])

  const sendMail = () => {
    let body = `Hello, %0A%0ATeam ${customerName}, %0A%0ACould you please send more details about my shipment? %0A%0AShipment Number: ${
      location?.state?.ShipmentId ?? ''
    } %0AHouse BL Number: ${
      location?.state?.data?.HouseBillNumber ?? ''
    } %0ATransport Mode: ${
      location?.state?.TransportMode ?? ''
    } %0A%0AThank you! %0A${'AYSLOG'}%0A%0A`
    window.location.href = `mailto:${emailContact}?subject=Shipment ID - ${
      location?.state?.ShipmentId ?? ''
    }&body=${body}`
  }
  return (
    <div
      className='detail-header overflow_detail'

      // className={
      //   !location.pathname.includes(Routeconstant.ROUTING)
      //     ? "detail-header"
      //     : "detail-header overflow_detail"
      // }
    >
      {context.gridViewShipment ? (
        <div className='d-flex justify-content-between align-items  p-2 details-head-div'>
          <div className='d-flex'>
            <p className='mb-0 mt-1 detail-text-head'>
              {location?.state?.ShipmentId ?? 'N/A'}
            </p>{' '}
            {/* <div className="d-flex align-items-center justify-content-center">
              {context?.emissions[0]?.loading === true ? (
                <CircularProgress size={"1rem"} />
              ) : (
                <Button
                  variant="outlined"
                  style={{ marginRight: "5px", padding: "0px 5px" }}
                  onClick={() => {
                    context?.getCarbonEmission();
                  }}
                >
                  {context?.emissions[0]?.CO2 || (
                    <>
                      CO <sub>2</sub>
                    </>
                  )}
                </Button>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-center">
              {" "}
              {context?.emissions[1]?.loading === true ? (
                <CircularProgress size={"1rem"} />
              ) : (
                <Button
                  variant="outlined"
                  style={{ marginRight: "5px", padding: "0px" }}
                  onClick={() => {
                    context?.getLuneEmission();
                  }}
                >
                  {context?.emissions[1]?.lune || "Lune"}
                </Button>
              )}
            </div> */}
          </div>

          <div
            className='d-flex justify-content-between align-items-center'
            style={{ minWidth: '8%', gap: '0.2rem' }}
          >
            {/* <NotificationsNoneIcon className="layout-notification" /> */}
            {/* <a o> */}
            <Tooltip title='Mail'>
              <MailIcon className='layout-notification' onClick={sendMail} />
            </Tooltip>
            {/* </a> */}
            <Tooltip title='Whatsapp'>
              <a
                href={`https://web.whatsapp.com/send?phone=${whatsappNumber}`}
                target='_blank'
              >
                <WhatsAppIcon className='layout-notification' />
              </a>
            </Tooltip>
            {location.pathname.includes('/details') && (
              <Tooltip title='Share shipment details (Public View)'>
                <img
                  style={{
                    height: '1.5rem'
                  }}
                  src={urlResolver('assets/link.svg')}
                  onClick={() => {
                    var currentdate = new Date()

                    let encryptedate = CryptoJS.AES.encrypt(
                      currentdate.toString(),
                      SECRET_KEY
                    ).toString()
                    let encodedate = CryptoJS.enc.Base64.parse(
                      encryptedate
                    ).toString(CryptoJS.enc.Hex)
                    let encrypted = CryptoJS.AES.encrypt(
                      location?.state?.ShipmentId,
                      SECRET_KEY
                    ).toString()
                    let encodeditem = CryptoJS.enc.Base64.parse(
                      encrypted
                    ).toString(CryptoJS.enc.Hex)

                    let encryptedata = encodedate.concat('__', encodeditem)

                    if (basename === '') {
                      navigator.clipboard.writeText(
                        `${
                          document.location.origin
                        }/${`shipment/details/`}${encryptedata}`
                      )
                    } else {
                      navigator.clipboard.writeText(
                        `${
                          document.location.origin
                        }/${basename}/${`shipment/details/`}${encryptedata}`
                      )
                    }

                    handleClick()
                  }}
                />
                {/* <ShareIcon
                  style={{
                    fontSize: "1.8rem",
                    color: "var(--color-primary)",
                  }}
                  onClick={() => {
                    var currentdate = new Date();

                    let encryptedate = CryptoJS.AES.encrypt(
                      currentdate.toString(),
                      SECRET_KEY
                    ).toString();
                    let encodedate = CryptoJS.enc.Base64.parse(
                      encryptedate
                    ).toString(CryptoJS.enc.Hex);
                    let encrypted = CryptoJS.AES.encrypt(
                      location?.state?.ShipmentId,
                      SECRET_KEY
                    ).toString();
                    let encodeditem = CryptoJS.enc.Base64.parse(
                      encrypted
                    ).toString(CryptoJS.enc.Hex);

                    let encryptedata = encodedate.concat("__", encodeditem);

                    if (basename === "") {
                      navigator.clipboard.writeText(
                        `${
                          document.location.origin
                        }/${`shipment/details/`}${encryptedata}`
                      );
                    } else {
                      navigator.clipboard.writeText(
                        `${
                          document.location.origin
                        }/${basename}/${`shipment/details/`}${encryptedata}`
                      );
                    }

                    handleClick();
                  }}
                /> */}
              </Tooltip>
            )}
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              TransitionComponent={SlideTransition}
            >
              <Alert
                onClose={handleClose}
                severity='success'
                sx={{ width: '100%' }}
              >
                Copied to clipboard !
              </Alert>
            </Snackbar>
            {/* <ShareOutlinedIcon className="mx-2 cursorPointer" /> */}
            {/* <img src={urlResolver("assets/dotted.svg")} /> */}
          </div>
        </div>
      ) : null}
      <div
        className={
          context.gridViewShipment
            ? 'layout-details-div  mx-3'
            : 'layout-details-div  mx-3 pt-5'
        }
      >
        {' '}
        {props.children}{' '}
      </div>
    </div>
  )
}

export default LayoutDetail
