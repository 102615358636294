import { Description } from "@mui/icons-material";
import { createSlice } from "@reduxjs/toolkit";

const selectedDataSlice = createSlice({
  name: "selectedData",
  initialState: {
    selected: {
      originselectedunloco: [],
      destselectedunloco: [],
      carrierSelected: [],
    },
  },
  reducers: {
    originselectedpage: (state, action) => {
      state.selected.originselectedunloco = action.payload;
    },
    destselectedpage: (state, action) => {
      state.selected.destselectedunloco = action.payload;
    },
    carrierUpdate: (state, action) => {
      state.selected.carrierSelected = action.payload;
    },
  },
});
export const { originselectedpage, destselectedpage, carrierUpdate } =
  selectedDataSlice.actions;
export default selectedDataSlice.reducer;
