import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import instance from "../../api/Interceptor";
import { API_PATH } from "../../constant/apiPath";
import validator from "validator";
import BtnOutline from "../button/BtnOutline";

const ChangePasswordModal = ({ open, handleClose }) => {
  const [newPassworderror, setNewPassworderror] = useState("");
  const [confirmPassworderror, setConfirmPassworderror] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confrmpass, setConfrmpass] = useState("");
  const [passwordChange, setPasswordChange] = useState({
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  const onSubmitChange = () => {
    setLoading(true);
    if (newPass === "" || confrmpass === "") {
      toast.error("Please enter both new password and confirm password");
      setLoading(false);
    } else if (newPass !== confrmpass) {
      toast.error("Password and confirm password do not match");
      setLoading(false);
    } else {
      // const body = {
      //   password: passwordChange.password ?? "",
      //   confirmPassword: passwordChange.confirmPassword ?? "",
      // };
      const body = {
        password: newPass,
        confirmPassword: confrmpass,
        // id: id ?? "",
      };

      instance
        .post(API_PATH.CHANGE_PASSWORD, body)
        .then((res) => {
          toast.success(res?.message?.Message ?? "");
          handleClose();
        })
        .catch((err) => {
          toast.success(err ?? "");
          handleClose();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const validatePassword = (e) => {
    setNewPass(e.target.value);
    // console.log("data1", e.target.value);
    var pssword = e.target.value;
    if (
      validator.isStrongPassword(pssword, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setNewPassworderror("");
    } else {
      if (validator.isEmpty(pssword)) {
        setNewPassworderror("");
      } else {
        setNewPassworderror(
          "Passwords must be at least 8 characters. Passwords must have at least one special character. Passwords must have at least one digit (0-9). Passwords must have at least one uppercase (A-Z)."
        );
      }
    }
  };
  const validateConfirmPassword = (e) => {
    var validated = false;
    setConfrmpass(e.target.value);
    // console.log("data1", e.target.value);
    var pssword = e.target.value;
    if (validator.isEmpty(pssword)) {
      setConfirmPassworderror("");
    } else {
      if (newPass !== pssword) {
        setConfirmPassworderror("Password Not match");
      } else {
        setConfirmPassworderror("");
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogContent>
        <div className="container-fluid">
          <div className="row">
            {/* <div className="col-12">
              <label for="inputPassword3" className="col-sm-10 col-form-label">
                Old Password
              </label>
              <div className="col-sm-11 pr-0">
                <input
                  type="password"
                  className="form-control"
                  id="inputPassword3"
                  placeholder="Old Password"
                />
              </div>
            </div> */}
            <div className="col-12">
              <Typography
                color="primary"
                style={{
                  fontFamily: " var(--family-label)",
                  fontStyle: "var(--family-style)",
                  fontWeight: "700",
                  fontSize: "var(--fs-subtitle)",
                }}
              >
                Change password
              </Typography>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <label for="inputPassword3" className="col-sm-10 col-form-label ">
                New Password
              </label>
              <div className="col-sm-11 pr-0">
                <input
                  type="password"
                  minLength="8"
                  autoComplete="off"
                  name="password"
                  className="form-control"
                  // id="inputPassword3"
                  placeholder="New Password"
                  // value={passwordChange.password}
                  value={newPass}
                  // onChange={({ target: { value } }) => {
                  //   setPasswordChange({
                  //     ...passwordChange,
                  //     password: value,
                  //   });
                  // }}
                  onChange={validatePassword}
                />
                <div>
                  {newPassworderror === "" ? null : (
                    <div
                      style={{
                        color: "red",
                        fontSize: "15px",
                        width: "25rem",
                      }}
                    >
                      {newPassworderror}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <label for="inputPassword3" className="col-sm-10 col-form-label">
                Confirm Password
              </label>
              <div className="col-sm-11 pr-0">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password"
                  autoComplete="off"
                  minLength="8"
                  name="Confirm password"
                  value={confrmpass}
                  onChange={validateConfirmPassword}
                />
                <div>
                  {confirmPassworderror === "" ? null : (
                    <div
                      style={{
                        color: "red",
                        fontSize: "16px",
                      }}
                    >
                      {confirmPassworderror}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-3">
            <div className="col-6   ">
              <BtnOutline onClick={() => handleClose()} title="Cancel" />
            </div>
            <div className="col-6  ">
              <BtnOutline
                onClick={() => {
                  onSubmitChange();
                }}
                title={
                  loading ? (
                    <CircularProgress
                      size="2rem"
                      style={{ color: "var(--color-miscellaneous-4)" }}
                    />
                  ) : (
                    "Submit"
                  )
                }
              />
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordModal;
