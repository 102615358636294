import React, { useRef, useState } from "react";
import "../User.css";
import { API_PATH } from "../../../constant/apiPath";
import { CircularProgress, Skeleton } from "@mui/material";
import BtnOutline from "../../../components/button/BtnOutline";
import UserFilterModal from "../../../components/model/UserFilterModal";
import axios from "axios";
import { baseUrl } from "../../../Config";
import { toast } from "react-hot-toast";
import { useDraggable } from "react-use-draggable-scroll";
import UserTableHeader from "./UserTableHeader";
import UserTableRow from "./UserTableRow";
import Modal from "../modal/Modal";
import Usercard from "./Usercard.js";
const UserTable = ({
  setPropertiesAddEditDelete,
  setOpenAddEditDelete,
  setActiveUserAndAdminNumber,
  setUserFilter,
  userFilter,
  filterData,
  setFilterData,
  getAllUser,
  userData,
  pageNo,
  setPageNo,
  sortColumn,
  setSortColumn,
  sortDirection,
  setSortDirection,
  lableToSort,
  setLoading,
  loading,
  search,
  filterObj,
  userCount,
  Admin_Portal_Action_Modules,
}) => {
  const [loadingReset, setLoadingReset] = useState(null);
  const [open, setOpen] = useState(false);
  const [moduleCaller, setModuleCaller] = useState(null);
  const sendResetLink = (email) => {
    // setLoadingReset(true);
    axios
      .get(`${baseUrl}${API_PATH.FORGET_PASSWORD}${email}`)
      .then((res) => {
        setLoadingReset(false);
        if (res.data.IsSuccess) {
          toast.success(res.data?.Message?.Message ?? "");
        } else {
          toast.error(res.data?.Message?.Message ?? "");
        }
      })
      .catch((err) => {
        setLoadingReset(false);
        console.log(err);
      })
      .finally(() => {
        setLoadingReset(null);
      });
  };
  const ref = useRef();
  const { events } = useDraggable(ref);
  return (
    <>
      {/* this modal is for roleManagement & module accessibility */}
      {open && (
        <Modal
          setOpen={setOpen}
          open={open}
          moduleCaller={moduleCaller}
          getAllUserInformaiton={{pageNo,getAllUser,search}}
        />
      )}

      {userFilter ? (
        <UserFilterModal
          setUserFilter={setUserFilter}
          userFilter={userFilter}
          setFilterData={setFilterData}
          filterData={filterData}
          getAllUser={getAllUser}
          setPageNo={setPageNo}
          pageNo={pageNo}
          filterObj={filterObj}
        />
      ) : null}

      <div className="tablediv">
        <div
          className="tableWrap flex max-w-xl space-x-3 overflow-x-scroll scrollbar-hide"
          {...events}
          ref={ref}
        >
          {loading.initial ? (
            <div className="skeleton-div">
              <Skeleton style={{ height: "80vh" }} />
            </div>
          ) : (
            <>
              <table className="table">
                <UserTableHeader
                  sortColumn={sortColumn}
                  SortWork={userData.length > 0}
                  lableToSort={lableToSort}
                  sortDirection={sortDirection}
                  setSortColumn={setSortColumn}
                  setSortDirection={setSortDirection}
                  getAllUser={getAllUser}
                  filterData={filterData}
                />

                {userData.length > 0 && (
                  <tbody style={{ fontFamily: "var(--family-label)" }}>
                    {userData.map((item, index) => {
                      const data = item?.Roles.split(",");
                      return (
                        <UserTableRow
                          Admin_Portal_Action_Modules={
                            Admin_Portal_Action_Modules
                          }
                          setOpenAddEditDelete={setOpenAddEditDelete}
                          setPropertiesAddEditDelete={
                            setPropertiesAddEditDelete
                          }
                          setActiveUserAndAdminNumber={
                            setActiveUserAndAdminNumber
                          }
                          setOpen={setOpen}
                          data={data}
                          item={item}
                          index={index}
                          sendResetLink={sendResetLink}
                          setLoadingReset={setLoadingReset}
                          loadingReset={loadingReset}
                          setModuleCaller={setModuleCaller}
                        />
                      );
                    })}
                  </tbody>
                )}
              </table>
              {userData.length == 0 && (
                <div className="d-flex justify-content-center align-items-center">
                  No data found
                </div>
              )}
            </>
          )}
          {userCount > userData?.length ? (
            <div className="mt-2">
              <BtnOutline
                title={
                  loading.loadMore ? (
                    <CircularProgress
                      size="1rem"
                      style={{ color: "var(--color-miscellaneous-4)" }}
                    />
                  ) : (
                    "Show More"
                  )
                }
                onClick={() => {
                  const pagenumber = pageNo + 1;
                  getAllUser(pagenumber, filterData, search);
                  setPageNo(pagenumber);
                }}
              />
            </div>
          ) : null}
        </div>
      </div>

      <div className="userCard" style={{ width: "100%", marginLeft: "0px" }}>
        {loading.initial ? (
          <div className="skeleton-div">
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        ) : (
          <>
            {userData.length ? (
              <>
                {userData.map((item, index) => {
                  return (
                    <>
                      <Usercard
                        Admin_Portal_Action_Modules={
                          Admin_Portal_Action_Modules
                        }
                        setOpenAddEditDelete={setOpenAddEditDelete}
                        setPropertiesAddEditDelete={setPropertiesAddEditDelete}
                        setActiveUserAndAdminNumber={
                          setActiveUserAndAdminNumber
                        }
                        setOpen={setOpen}
                        data={item?.Roles.split(",")}
                        index={index}
                        loadingReset={loadingReset}
                        setModuleCaller={setModuleCaller}
                        userData={item}
                      />
                    </>
                  );
                })}
                {userCount > userData?.length ? (
                  <BtnOutline
                    title={
                      loading.loadMore ? (
                        <CircularProgress
                          size="1rem"
                          style={{ color: "var(--color-miscellaneous-4)" }}
                        />
                      ) : (
                        "Show More"
                      )
                    }
                    onClick={() => {
                      const pagenumber = parseInt(pageNo) + 1;
                      getAllUser(pagenumber, filterData, "");
                      setPageNo(pagenumber);
                    }}
                  />
                ) : null}
              </>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                No data found
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default UserTable;
