import * as React from "react";
import TransferListModal from "./TransferListModal";
import { useState } from "react";
import { useEffect } from "react";
import { getModuleData, putModuleData } from "../userApi/userApiDefination";
import { Skeleton } from "@mui/material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Divider,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import BackDropLoader from "../../../components/Loader/BackDropLoader";
import FetchHook from "../../../customHook/FetchHook";
import { API_PATH } from "../../../constant/apiPath";
import toast from "react-hot-toast";

export default function Modal({
  setOpen,
  open,
  moduleCaller,
  getAllUserInformaiton
}) {
  const {pageNo,getAllUser,search} = getAllUserInformaiton
  const [loading, setLoading] = useState(false);
  const [dataModel, setDataModel] = useState({});
  const [dataRoleById, setDataRoleById] = useState([]); //role which are assigned
  const [dataRoleNotAssigned, setDataRoleNotAssigned] = useState([]);
  //ARRAY CONTAIN THE LEFT AND RIGHT LIST
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  // WHEN API CALL THIS BACKDROP ACTIVATED AND CLOSE WHEN API CALL IS DONE
  const [backdrop, setBackDrop] = useState(false);

  // method to close the modal
  const handleClose = () => {
    setOpen(false);
  };
  // loader
  const Loader = () => {
    return (
      <div className="d-flex gap-4">
        <Skeleton variant="rounded" width={"25em"} height={"17em"} />
        <Skeleton variant="rounded" width={"25em"} height={"17em"} />
      </div>
    );
  };
  // reset all the list as received from api
  const resetList = () => {
    const data = moduleCaller?.name === "Accessibility" ? dataModel : [];
    if (moduleCaller?.name === "Accessibility") {
      setLeft(Object?.keys(data).filter((singleKey) => data[singleKey] === 0));
      setRight(Object?.keys(data).filter((singleKey) => data[singleKey] === 1));
    } else {
      setLeft(dataRoleNotAssigned);
      setRight(dataRoleById);
    }
  };

  useEffect(() => {
    resetList();
  }, [dataModel, dataRoleById]);

  const generateBodyForAccessModule = (AccessArray, nonAccessArray, body) => {
    AccessArray.forEach((key) => {
      body[key] = 1;
    });
    nonAccessArray.forEach((key) => {
      body[key] = 0;
    });
    return body;
  };

  const generatebodyForUpdateDelteRole = (roleInformation, body) => {
    if (roleInformation?.length == 0) return body;
    roleInformation.forEach((role) => {
      body.Roles.push({
        Role: role,
      });
    });
    return body;
  };
  const generateArrayForUpdateDelteRole = () => {
    const saveRole = right.filter((role) => dataRoleById.indexOf(role) == -1);
    const deletedRole = dataRoleById.filter(
      (role) => right.indexOf(role) == -1
    );
    return { saveRole, deletedRole };
  };
  async function apiCallToUpdateDeleteRole() {
    const { saveRole, deletedRole } = generateArrayForUpdateDelteRole();
    const body = {
      UserId: moduleCaller?.mailId ?? "",
      Roles: [],
    };
    const informationTosave = {
      body: generatebodyForUpdateDelteRole(saveRole, body),
      setLoading: setBackDrop,
      setData: () => {},
      apiUrl: `${API_PATH?.UPDATE_DETETE}`,
      method: "post",
    };
    if (informationTosave?.body?.Roles?.length > 0) {
      const saveResponce = await FetchHook(informationTosave);
      if (saveResponce?.message?.Type == "ERROR") {
        toast.error(saveResponce?.message?.Message);
      } else {
        toast.success(saveResponce?.message?.Message);
      }
    }
    const bodyFordelete = {
      UserId: moduleCaller?.mailId ?? "",
      Roles: [],
    };
    const informationToDelte = {
      body: {
        data: generatebodyForUpdateDelteRole(deletedRole, bodyFordelete),
      },
      setLoading: setBackDrop,
      setData: () => {},
      apiUrl: `${API_PATH?.UPDATE_DETETE}`,
      method: "delete",
    };
    if (informationToDelte?.body?.data?.Roles?.length > 0) {
      const deleteResponce = await FetchHook(informationToDelte);
      if (deleteResponce?.message?.Type == "ERROR") {
        toast.error(deleteResponce?.message?.Message);
      } else {
        toast.success(deleteResponce?.message?.Message);
      }
    }
    getAllUser(pageNo,"",search);
    handleClose();
  }

  async function apiCallForRole() {
    const informationForSpecificrole = {
      body: "",
      setLoading: setLoading,
      setData: () => {},
      apiUrl: `${API_PATH.GET_ROLE_ID}${moduleCaller?.mailId}`,
      method: "get",
    };
    const { data: roleAssigned } = await FetchHook(informationForSpecificrole);
    const information1forAllrole = {
      body: "",
      setLoading: setLoading,
      setData: () => {},
      apiUrl: `${API_PATH?.GET_aLL_ROLE}`,
      method: "get",
    };
    const { data: allroleAvailable } = await FetchHook(information1forAllrole);
    const roleNameAsssigned = roleAssigned?.map((obj) => obj.Role);
    const allRoleName = allroleAvailable?.map((obj) => obj.Role);
    const roleNameNotAssigned = allRoleName?.filter((role) => {
      if (!roleNameAsssigned.includes(role)) {
        return role;
      }
    });
    setDataRoleNotAssigned(roleNameNotAssigned);
    setDataRoleById(roleNameAsssigned);
  }
  useEffect(() => {
    setLoading(true);
    if (moduleCaller?.name === "Accessibility")
      getModuleData(moduleCaller?.mailId, setDataModel, setLoading);
    else {
      apiCallForRole();
    }
  }, []);
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <BackDropLoader open={backdrop} />
        <DialogContent className="removePadding">
          <Card style={{ border: "none", boxShadow: "none" }}>
            <CardHeader
              action={
                <Button
                  onClick={() => {
                    resetList();
                  }}
                >
                  Reset all
                </Button>
              }
              subheader={
                <div className="d-flex">
                  <Button
                    onClick={() => {
                      handleClose();
                    }}
                    color={"primary"}
                  >
                    <KeyboardBackspaceIcon /> Back
                  </Button>

                  <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ width: "80%" }}
                  >
                    {moduleCaller?.userName}
                  </div>
                </div>
              }
            />
            <Divider sx={{ backgroundColor: "var(--color-miscellaneous-1)" }} />
            <CardContent>
              {loading ? (
                <Loader />
              ) : (
                <TransferListModal
                  senderName={moduleCaller?.name}
                  left={left}
                  setLeft={setLeft}
                  right={right}
                  setRight={setRight}
                  title={
                    moduleCaller?.name === "Accessibility"
                      ? ["Modules", "Acess"]
                      : ["Roles", "Roles assigned"]
                  }
                  subtitle={
                    moduleCaller?.name === "Accessibility"
                      ? ["module available", "Access granted"]
                      : ["Available", "Assinged"]
                  }
                />
              )}
            </CardContent>
            <CardActions className="d-flex justify-content-end">
              <Button
                onClick={() => {
                  const data =
                    moduleCaller?.name === "Accessibility" ? dataModel : [];
                  if (moduleCaller?.name === "Accessibility") {
                    const body = generateBodyForAccessModule(right, left, data);
                    putModuleData(body, setBackDrop, handleClose);
                  } else {
                    apiCallToUpdateDeleteRole();
                  }
                }}
              >
                Save
              </Button>
            </CardActions>
          </Card>
        </DialogContent>
      </Dialog>
    </>
  );
}
