import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import "./filterbooking.css";
import instance from "../../api/Interceptor";
import { API_PATH } from "../../constant/apiPath";
import { Autocomplete, Divider, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { KeyboardBackspace } from "@mui/icons-material";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const HeadingText = ({ children }) => {
  return (
    <p
      style={{
        margin: "0",
        fontWeight: 400,
        lineHeight: 1.5,
        fontSize: "var(--fs-lg)",
        fontFamily: "var(--family-label)",
        width: "49%",
      }}
    >
      {children}
    </p>
  );
};
const FieldContainer = ({ children, customcss }) => {
  // console.log(customcss);
  return (
    <div className="child-container" style={customcss}>
      {children}
    </div>
  );
};
export default function FilterModalBooking({
  open,
  handleClose,
  handleClickOpen,
  filterOptions,
  setFilterOptions,
  handleClear,
  handleBack,
  handleFilter,
}) {
  const [orgDestPair, setOrgDestPair] = React.useState({
    originPort: [],
    destinationPort: [],
  });
  const [selected, setSelected] = React.useState({
    origin: [],
    destination: [],
  });

  console.log(selected);

  const {
    data: transportmodes,
    isError,
    getData: getTransportModes,
  } = GetData("get", API_PATH.GET_TRANSPORT_MODE, {});

  const getOrg = (search) => {
    let body = {
      CountryCode: "",
      Search: search ?? "",
    };

    instance
      .post("api/v1/master/GetUnlocoInfo", body)
      .then((res) => {
        let data = { ...orgDestPair };
        data.originPort = res?.data;
        setOrgDestPair(data);
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };
  const getDest = (search) => {
    let body = {
      CountryCode: "",
      Search: search ?? "",
    };

    instance
      .post("api/v1/master/GetUnlocoInfo", body)
      .then((res) => {
        let data = { ...orgDestPair };
        data.destinationPort = res?.data;
        setOrgDestPair(data);
      })
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  React.useEffect(() => {
    getTransportModes();
  }, []);
  const handleFilterChange = (filterName, value) => {
    setFilterOptions({ ...filterOptions, [filterName]: value });
  };

  const formatDate = (date) => {
    return moment(new Date(date)).format("YYYY-MM-DD HH:mm:ss");
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth="sm"
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle style={{ padding: "10px 24px" }}>
          {"Filter By"}
        </DialogTitle> */}
        <DialogActions
          className="justify-content-between"
          style={{ padding: "20px 24px 0px 24px" }}
        >
          <Button onClick={handleBack}>
            <KeyboardBackspace color="primary" />
            Back
          </Button>
          <Button
            onClick={() => {
              handleClear();
              setSelected({
                origin: [],
                destination: [],
              });
              setOrgDestPair({
                origin: [],
                destination: [],
              });
            }}
          >
            Reset All
          </Button>
        </DialogActions>

        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "1rem",
          }}
        >
          <Divider
            sx={{
              backgroundColor: "var(--color-miscellaneous-1)",
              justifyContent: "center",
            }}
          />
          <div className="parent-container">
            <FieldContainer>
              <HeadingText>Status</HeadingText>

              <Select
                fullWidth
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterOptions?.status ?? ""}
                onChange={(e, val) => {
                  handleFilterChange("status", e.target.value);
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">None</MenuItem>
                {status.map((mode) => (
                  <MenuItem value={mode.value} key={mode?.value}>
                    {mode.label}
                  </MenuItem>
                ))}
              </Select>
            </FieldContainer>
          </div>
          <div className="parent-container">
            <FieldContainer>
              <HeadingText>Transport mode</HeadingText>

              <Select
                fullWidth
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterOptions?.transportMode ?? ""}
                onChange={(e, val) => {
                  handleFilterChange("transportMode", e.target.value);
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">None</MenuItem>
                {transportmodes.map((mode) => (
                  <MenuItem value={mode.Code} key={mode?.Code}>
                    {mode.Description}
                  </MenuItem>
                ))}
              </Select>
            </FieldContainer>
          </div>
          <div className="parent-container">
            <FieldContainer>
              <HeadingText>Created by</HeadingText>

              <TextField
                style={{ marginBottom: "0px !important" }}
                fullWidth
                size="small"
                id="outlined-basic"
                value={filterOptions?.createdBy ?? ""}
                label=""
                variant="outlined"
                onChange={(e) => {
                  handleFilterChange("createdBy", e.target.value);
                }}
              />
            </FieldContainer>
          </div>
          <div className="parent-container">
            <FieldContainer>
              <HeadingText>Consignee</HeadingText>

              <TextField
                fullWidth
                size="small"
                id="outlined-basic"
                value={filterOptions?.consignee ?? ""}
                label=""
                variant="outlined"
                onChange={(e) => {
                  handleFilterChange("", e.target.value);
                }}
              />
            </FieldContainer>
          </div>
          <div className="parent-container">
            <FieldContainer>
              <HeadingText>Consignor</HeadingText>

              <TextField
                fullWidth
                size="small"
                id="outlined-basic"
                value={filterOptions?.consignor ?? ""}
                label=""
                variant="outlined"
                onChange={(e) => {
                  handleFilterChange("", e.target.value);
                }}
              />
            </FieldContainer>
          </div>
          <div className="parent-container">
            <FieldContainer>
              <HeadingText>Origin</HeadingText>

              <Autocomplete
                fullWidth
                freeSolo
                disablePortal
                options={orgDestPair?.originPort ?? []}
                onChange={(e, val) => {
                  console.log(val);
                  handleFilterChange("origin", val.Code);
                  let temp = { ...selected };
                  temp.origin = val;
                  setSelected(temp);
                }}
                value={selected?.origin}
                getOptionLabel={(option) =>
                  option.Name ? `${option.Name}( ${option.Code})` : ""
                }
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    onChange={(e) => {
                      if (e.target.value.length >= 3) {
                        getOrg(e.target.value);
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </FieldContainer>
          </div>
          <div className="parent-container">
            <FieldContainer>
              <HeadingText>Destination</HeadingText>

              <Autocomplete
                fullWidth
                freeSolo
                disablePortal
                options={orgDestPair?.destinationPort ?? []}
                onChange={(e, val) => {
                  handleFilterChange("destination", val.Code);
                  let temp = { ...selected };
                  temp.destination = val;
                  setSelected(temp);
                  // console.log(val);
                }}
                value={selected?.destination}
                getOptionLabel={(option) =>
                  option.Name ? `${option.Name}( ${option.Code})` : ""
                }
                size="small"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    onChange={(e) => {
                      if (e.target.value.length >= 3) {
                        getDest(e.target.value);
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                )}
              />
            </FieldContainer>
          </div>
          <div className="parent-container">
            <FieldContainer>
              <HeadingText>Created</HeadingText>
            </FieldContainer>
            <FieldContainer>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  maxDate={filterOptions?.createdTo}
                  valueDefault={null}
                  value={filterOptions?.createdFrom ?? null}
                  inputFormat="DD/MM/YYYY"
                  onChange={(e) => {
                    handleFilterChange("createdFrom", formatDate(e));
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      color="primary"
                      error={null}
                    />
                  )}
                />
              </LocalizationProvider>{" "}
            </FieldContainer>
            <FieldContainer customcss={{ marginLeft: "10px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  minDate={filterOptions?.createdFrom}
                  valueDefault={null}
                  value={filterOptions?.createdTo ?? null}
                  inputFormat="DD/MM/YYYY"
                  onChange={(e) => {
                    handleFilterChange("createdTo", formatDate(e));
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      color="primary"
                      error={null}
                    />
                  )}
                />
              </LocalizationProvider>{" "}
            </FieldContainer>
          </div>
          <div className="parent-container">
            <FieldContainer>
              <HeadingText>ETD</HeadingText>
            </FieldContainer>
            <FieldContainer>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  maxDate={filterOptions?.etdTo}
                  valueDefault={null}
                  value={filterOptions?.etdFrom ?? null}
                  inputFormat="DD/MM/YYYY"
                  onChange={(e) => {
                    handleFilterChange("etdFrom", formatDate(e));
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      color="primary"
                      error={null}
                    />
                  )}
                />
              </LocalizationProvider>{" "}
            </FieldContainer>
            <FieldContainer customcss={{ marginLeft: "10px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  minDate={filterOptions?.etdFrom}
                  valueDefault={null}
                  value={filterOptions?.etdTo ?? null}
                  inputFormat="DD/MM/YYYY"
                  onChange={(e) => {
                    handleFilterChange("etdTo", formatDate(e));
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      color="primary"
                      error={null}
                    />
                  )}
                />
              </LocalizationProvider>{" "}
            </FieldContainer>
          </div>

          <div className="parent-container">
            <FieldContainer>
              <HeadingText>ETA</HeadingText>
            </FieldContainer>
            <FieldContainer>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  maxDate={filterOptions?.etaTo}
                  valueDefault={null}
                  value={filterOptions?.etaFrom ?? null}
                  inputFormat="DD/MM/YYYY"
                  onChange={(e) => {
                    handleFilterChange("etaFrom", formatDate(e));
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      color="primary"
                      error={null}
                    />
                  )}
                />
              </LocalizationProvider>{" "}
            </FieldContainer>
            <FieldContainer customcss={{ marginLeft: "10px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  minDate={filterOptions?.etaFrom}
                  valueDefault={null}
                  value={filterOptions?.etaTo ?? null}
                  inputFormat="DD/MM/YYYY"
                  onChange={(e) => {
                    handleFilterChange("etaTo", formatDate(e));
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      color="primary"
                      error={null}
                    />
                  )}
                />
              </LocalizationProvider>{" "}
            </FieldContainer>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleFilter}>Search</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
export const GetData = (type, apiUrl, body) => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const getData = () => {
    setLoading(true);
    instance[type](apiUrl, body)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        setIsError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return { data, loading, isError, getData };
};
const status = [
  { label: "Pending", value: 0 },
  { label: "Accepted", value: 1 },
  { label: "Rejected", value: 2 },
];
