import React, { useEffect, useState } from 'react'
import LayoutDetail from '../../../components/layout/LayoutDetail'
import '../shipment.css'
import { basename } from '../../../Config'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import Button from '@mui/material/Button'
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined'
import DocUploadShipmentSidebar from '../../../components/sidebar/DocUploadShipmentSidebar'
import { API_PATH } from '../../../constant/apiPath'
import instance from '../../../api/Interceptor'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Skeleton,
  Tooltip
} from '@mui/material'
import { toast } from 'react-hot-toast'
import { genrateDownloadLink } from '../../../utils/utility'
import urlResolver from '../../../utils/urlResolver'
import { AuthContext } from '../../../store/context/AuthProvider'
import { useContext } from 'react'
import { Routeconstant } from '../../../routing/utils/RouteConstant'
import FileViewer from 'react-file-viewer'
import PreviewIcon from '@mui/icons-material/Preview'
import download from 'downloadjs'
import { PDFDocument, StandardFonts, degrees, rgb } from 'pdf-lib'

const Documentation = () => {
  const [docUpload, setDocUpload] = useState(false)
  const [loading, setLoading] = useState(false)
  const [docLoading, setDocLoading] = useState({
    preview: false,
    download: false,
    index: null
  })
  const [docList, setDocList] = useState({})
  const [docListOld, setDocListOld] = useState({})
  const [filter, setFilter] = useState('ALL')
  const location = useLocation()
  const context = useContext(AuthContext)
  const [fullWidth, setFullWidth] = useState(true)
  const [maxWidth, setMaxWidth] = useState('md')

  const handleClose = () => {
    setOpen(false)
  }
  const [open, setOpen] = useState(false)
  const [docLink, setDocLink] = useState('')
  const [extension, setExtension] = useState('')

  const { Email } = JSON.parse(localStorage.getItem('userData'))

  // ----------------------------------------
  // const [checkALL, setCheckAll] = useState(false);
  // const [individualCheckArr, setIndividualCheckArr] = useState([]);

  const documentList = () => {
    setLoading(true)
    const body = {
      ShipmentID: location?.state?.ShipmentId ?? ''
    }
    instance
      .post(API_PATH.GET_SHIPMENT_DOCUMENT_LIST, body)
      .then(res => {
        if (res.data) {
          setDocList(res?.data)
          setDocListOld(res?.data)
        } else {
          setDocList(res?.data)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    if (context?.shipmentId?.length !== 0) documentList()
  }, [location?.state?.ShipmentId])

  const file = docLink
  const type = extension
  const src = `data:image/jpeg;base64,${file}`
  console.log("src21",src)

  async function modifyPdf(link, name) {
    // Fetch an existing PDF document
    const url = link
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes)

    // Embed the Helvetica font
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

    // Get the first page of the document
    const pages = pdfDoc.getPages()

    // Get the width and height of the first page
    /* const modeNames = values(BlendMode);
      
       modeNames.forEach((m, i) => {
          firstPage.drawText(`blendMode: ${m}`, {
      size: 14,
      x: 40,
      y: 700 - i * 20,
      color: cmyk(0, 0, 0, 0.65),
      blendMode: m,
          });
        }); */

    // Draw a string of text diagonally across the first page
    // pages.map(page => {
    //   const { width, height } = page.getSize()

    //   return page.drawText('Draft2222!', {
    //     x: width / 3,
    //     y: height / 2,
    //     size: 80,
    //     font: helveticaFont,
    //     color: rgb(0.95, 0.1, 0.1),
    //     rotate: degrees(45)
    //     // color: cmyk(0.58, 0.78, 0.53, 0),
    //     // blendMode: BlendMode.Darken,
    //   })
    // })

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save()

    // Trigger the browser to download the PDF document
    download(pdfBytes, `${name}`, 'application/pdf')
  }

  const downloadDocs = (item, index) => {
    let tempLoading = { ...docLoading, download: true, index: index };
    setDocLoading(tempLoading);
  
    const body = {
      ShipmentID: item?.SC_JobNum ?? '',
      DocumentPk: item?.SC_PK ?? ''
    };
  
    instance
      .post(API_PATH.GET_DOCUMENT_DATA, body)
      .then(i => {
        const downloadLink = document.createElement('a');
        const prefix = genrateDownloadLink(i?.data[0]?.SC_FileName ?? '');
        const fileData = i?.data[0]?.ImageDataVC ?? '';
  
        if (prefix && fileData) {
          const link = `${prefix}${fileData}`;
          const fileName = i?.data[0]?.SC_FileName ?? '';
          const fileExtension = fileName.split('.').pop();
  
          if (fileExtension === 'pdf') {
            modifyPdf(link, fileName);
          } else {
            // For JPG and other file types
            downloadLink.href = link;
            downloadLink.download = fileName;
            downloadLink.click();
          }
        } else {
          toast.error('Invalid document found!');
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        let tempLoading = { ...docLoading, download: false };
        setDocLoading(tempLoading);
      });
  };
  const navigate = useNavigate()
  useEffect(() => {
    if (context?.shipmentId?.length === 0) {
      navigate(Routeconstant.SHIPMENT_LISTING)
    }
  }, [])
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle>Preview</DialogTitle>
        <DialogContent style={{ height: '70rem' }}>
          {/* <iframe
            title="doc-view"
            src={`${docLink}#toolbar=0`}
            width="100%"
            height="100%"
          /> */}
          {/* <div
            style={{
              zIndex: 1,
              position: 'absolute',
              height: '2rem',
              width: '2rem',
              opacity: '0.5',
              left: '35%',
              top: '50%',
              display: 'flex',
              // justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              transform: ' rotate(-45deg)'
            }}
          >
            <span
              style={{
                color: 'red',
                fontSize: 'var(--fs-md)',
                fontWeight: '700'
              }}
            >
              Draft!
            </span>
            <span
              style={{
                color: 'red',
                fontSize: 'var(--fs-head)',
                fontWeight: '700'
              }}
            >
              {Email}
            </span>
          </div> */}
          {type === 'pdf' ? (
            <FileViewer fileType={type} filePath={file} />
          ) : (
            <div className='image-container'>
              <img
                src={file}
                alt='File'
                className='responsive-image'
              />
            </div>
          )}
        </DialogContent>
      </Dialog>

      {location.pathname.includes(Routeconstant.DOCUMENTATION) ? (
        <LayoutDetail>
          {docUpload ? (
            <DocUploadShipmentSidebar
              setDocUpload={setDocUpload}
              docUpload={docUpload}
              documentList={documentList}
            />
          ) : null}

          <div className='p-2'>
            <p className='basic-head font-shipment'>Documentation</p>
          </div>
          {loading ? (
            <div className='skeleton-div'>
              <Skeleton style={{ height: '3rem' }} />
              <Skeleton style={{ height: '30rem' }} />
            </div>
          ) : (
            <>
              {docList?.DocumentModelCollection?.length ? (
                <>
                  <div className='d-flex justify-content-around align-items-center'>
                    <button
                      // key={item}
                      // value={item}
                      className={
                        filter === 'ALL'
                          ? 'btn-doc px-5 py-1 backgroundColor font-shipment'
                          : 'btn-doc px-5 py-1 font-shipment'
                      }
                      onClick={e => {
                        setFilter('ALL')
                        const temp = { ...docListOld }
                        setDocList(temp)
                      }}
                    >
                      ALL
                    </button>
                    {docList.DocumentTypes.map(item => {
                      return (
                        <button
                          key={item}
                          value={item}
                          className={
                            filter === item
                              ? 'btn-doc px-5 py-1 backgroundColor font-shipment'
                              : 'btn-doc px-5 py-1 font-shipment'
                          }
                          onClick={e => {
                            setFilter(e.target.value)
                            const temp = { ...docListOld }
                            const temp2 = temp.DocumentModelCollection.filter(
                              item => {
                                return item.SC_DocType === e.target.value
                              }
                            )
                            setDocList({
                              ...docList,
                              DocumentModelCollection: [...temp2]
                            })
                          }}
                        >
                          {item}
                        </button>
                      )
                    })}
                  </div>
                  <div className='container-fluid mt-3 '>
                    <div className='row justify-content-between tableRow-div header-document'>
                      <div className='col-4 font-shipment d-flex doc-font-header'>
                        Name
                      </div>
                      <div className='col-1 font-shipment d-flex doc-font-header'>
                        Type
                      </div>
                      <div className='col-1 font-shipment d-flex doc-font-header ps-0'>
                        {' '}
                        File type
                      </div>
                      <div className='col-1 font-shipment d-flex ps-0 doc-font-header'>
                        Preview
                      </div>
                      <div className='col-1 font-shipment d-flex'>
                        <img
                          alt=''
                          src={urlResolver('assets/dotted.svg')}
                          style={{ visibility: 'hidden' }}
                        />
                      </div>
                    </div>
                    {docList.DocumentModelCollection.map((i, index) => {
                      return (
                        <div
                          className='row justify-content-between tableBody-div'
                          key={index}
                          style={{ borderBottom: '1px solid #CFDBEB' }}
                        >
                          <div className='col-4 wordWrap-doc  font-shipment invoice '>
                            {i?.SC_FileName ?? 'N/A'}{' '}
                          </div>
                          <div className='col-1 wordWrap-doc font-shipment'>
                            {i?.SC_DocType ?? 'N/A'}
                          </div>
                          <div className='col-1 wordWrap-doc font-shipment'>
                            {i?.SC_FileName.split('.')[1] ?? 'N/A'}
                          </div>
                          <div className='col-1  '>
                            {docLoading?.preview === true &&
                            docLoading?.index === index ? (
                              <CircularProgress size={'1rem'} />
                            ) : (
                              <PreviewIcon
                                onClick={() => {
                                  let tempLoading = {
                                    ...docLoading,
                                    preview: true,
                                    index: index
                                  }
                                  setDocLoading(tempLoading)
                                  setExtension(i?.SC_FileName.split('.')[1])
                                  const body = {
                                    ShipmentID: i?.SC_JobNum ?? '',
                                    DocumentPk: i?.SC_PK ?? ''
                                  }

                                  instance
                                    .post(API_PATH.GET_DOCUMENT_DATA, body)
                                    .then(i => {
                                      const downloadLink =
                                        document.createElement('a')
                                      const prefix = genrateDownloadLink(
                                        i?.data[0]?.SC_FileName ?? ''
                                      )
                                      downloadLink.href = `${prefix}${
                                        i?.data[0]?.ImageDataVC ?? ''
                                      }`
                                      let link = `${prefix}${i?.data[0]?.ImageDataVC}`
                                      setDocLink(link)
                                    })
                                    .catch(err => {
                                      console.log(err)
                                    })
                                    .finally(() => {
                                      let tempLoading = {
                                        ...docLoading,
                                        preview: false
                                      }
                                      setDocLoading(tempLoading)
                                      setOpen(true)
                                    })
                                }}
                              />
                            )}
                          </div>
                          <div className='col-1 d-contents ps-0 btn-documents'>
                            <Button
                              variant='outlined'
                              className='mr-2 '
                              onClick={() => {
                                downloadDocs(i, index)
                              }}
                            >
                              {docLoading?.download === true &&
                              docLoading?.index === index ? (
                                <CircularProgress size={'1rem'} />
                              ) : (
                                <FileDownloadOutlinedIcon />
                              )}
                            </Button>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  {/*--Mobile Respnsive */}
                  <>
                    {docList.DocumentModelCollection.map((i, index) => {
                      return (
                        <div
                          class='card sip-card mt-3'
                          style={{
                            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                          }}
                        >
                          <div className='d-flex align-items-center'>
                            <div class='card-body'>
                              <ul
                                className='list-inline'
                                style={{ display: 'flex' }}
                              >
                                <li className='font-shipment'>Name</li>
                                <li className='font-shipment ps-2'>
                                  {i?.SC_FileName ?? 'N/A'}{' '}
                                </li>
                              </ul>
                              <ul
                                className='list-inline'
                                style={{ display: 'flex' }}
                              >
                                <li className='font-shipment'>Type</li>
                                <li className='ps-2 font-shipment'>
                                  {' '}
                                  {i?.SC_DocType ?? 'N/A'}
                                </li>
                              </ul>
                              <ul
                                className='list-inline'
                                style={{ display: 'flex' }}
                              >
                                <li className='font-shipment'>File Type</li>
                                <li className='ps-2 font-shipment'>
                                  {' '}
                                  {i?.SC_FileName.split('.')[1] ?? 'N/A'}
                                </li>
                              </ul>
                              <ul
                                className='list-inline'
                                style={{ display: 'flex' }}
                              >
                                <li className='font-shipment'>File Type</li>
                                <li className='ps-2 font-shipment'>
                                  {' '}
                                  {i?.SC_FileName.split('.')[1] ?? 'N/A'}
                                </li>
                              </ul>
                            </div>

                            <Button
                              variant='outlined'
                              className='mr-5 font-shipment'
                              onClick={() => {
                                downloadDocs(i)
                              }}
                            >
                              <FileDownloadOutlinedIcon />
                            </Button>
                          </div>
                        </div>
                      )
                    })}
                  </>
                </>
              ) : (
                <div className='d-flex justify-content-center align-items-center w-100 '>
                  No document found!
                </div>
              )}
              {/* <img
                onClick={() => {
                  setDocUpload(true)
                }}
                src={urlResolver('assets/DocumentUploadIcon.svg')}
                className='doc-upload-icon'
              /> */}
            </>
          )}
        </LayoutDetail>
      ) : (
        <LayoutDetail>
          {docUpload ? (
            <DocUploadShipmentSidebar
              setDocUpload={setDocUpload}
              docUpload={docUpload}
              documentList={documentList}
            />
          ) : null}

          <div className='p-2 d-flex justify-content-between'>
            <p className='basic-head font-shipment'>Documentation</p>
            <Tooltip title='Upload document'>
              <img
                onClick={() => {
                  setDocUpload(true)
                }}
                src={urlResolver('assets/DocumentUploadIcon.svg')}
                style={{ height: '2.5rem' }}
              />
            </Tooltip>
          </div>
          {loading ? (
            <div className='skeleton-div'>
              <Skeleton style={{ height: '3rem' }} />
              <Skeleton style={{ height: '30rem' }} />
            </div>
          ) : (
            <>
              {docList?.DocumentModelCollection?.length ? (
                <>
                  <div className='d-flex justify-content-around align-items-center'>
                    <button
                      // key={item}
                      // value={item}
                      className={
                        filter === 'ALL'
                          ? 'btn-doc px-5 py-1 backgroundColor font-shipment'
                          : 'btn-doc px-5 py-1 font-shipment'
                      }
                      onClick={e => {
                        setFilter('ALL')
                        const temp = { ...docListOld }
                        setDocList(temp)
                      }}
                    >
                      ALL
                    </button>
                    {docList.DocumentTypes.map(item => {
                      return (
                        <button
                          key={item}
                          value={item}
                          className={
                            filter === item
                              ? 'btn-doc px-5 py-1 backgroundColor font-shipment'
                              : 'btn-doc px-5 py-1 font-shipment'
                          }
                          onClick={e => {
                            setFilter(e.target.value)
                            const temp = { ...docListOld }
                            const temp2 = temp.DocumentModelCollection.filter(
                              item => {
                                return item.SC_DocType === e.target.value
                              }
                            )
                            setDocList({
                              ...docList,
                              DocumentModelCollection: [...temp2]
                            })
                          }}
                        >
                          {item}
                        </button>
                      )
                    })}
                  </div>
                  <div className='container-fluid mt-3 '>
                    <div className='row justify-content-between tableRow-div header-document'>
                      <div className='col-4 font-shipment d-flex doc-font-header'>
                        Name
                      </div>
                      <div className='col-1 font-shipment d-flex doc-font-header'>
                        Type
                      </div>
                      <div className='col-1 font-shipment d-flex doc-font-header ps-0'>
                        {' '}
                        File type
                      </div>
                      <div className='col-1 font-shipment d-flex ps-0 doc-font-header'>
                        Preview
                      </div>
                      <div className='col-1 font-shipment d-flex'>
                        <img
                          alt=''
                          src={urlResolver('assets/dotted.svg')}
                          style={{ visibility: 'hidden' }}
                        />
                      </div>
                    </div>
                    {docList.DocumentModelCollection.map((i, index) => {
                      return (
                        <div
                          className='row justify-content-between tableBody-div'
                          key={index}
                          style={{ borderBottom: '1px solid #CFDBEB' }}
                        >
                          <div className='col-4 wordWrap-doc  font-shipment invoice '>
                            {i?.SC_FileName ?? 'N/A'}{' '}
                          </div>
                          <div className='col-1 wordWrap-doc font-shipment'>
                            {i?.SC_DocType ?? 'N/A'}
                          </div>
                          <div className='col-1 wordWrap-doc font-shipment'>
                            {i?.SC_FileName.split('.')[1] ?? 'N/A'}
                          </div>
                          <div className='col-1  '>
                            {docLoading?.preview === true &&
                            docLoading?.index === index ? (
                              <CircularProgress size={'1rem'} />
                            ) : (
                              <PreviewIcon
                                onClick={() => {
                                  let tempLoading = {
                                    ...docLoading,
                                    preview: true,
                                    index: index
                                  }
                                  setDocLoading(tempLoading)
                                  setExtension(i?.SC_FileName.split('.')[1])
                                  const body = {
                                    ShipmentID: i?.SC_JobNum ?? '',
                                    DocumentPk: i?.SC_PK ?? ''
                                  }

                                  instance
                                    .post(API_PATH.GET_DOCUMENT_DATA, body)
                                    .then(i => {
                                      const downloadLink =
                                        document.createElement('a')
                                      const prefix = genrateDownloadLink(
                                        i?.data[0]?.SC_FileName ?? ''
                                      )
                                      downloadLink.href = `${prefix}${
                                        i?.data[0]?.ImageDataVC ?? ''
                                      }`
                                      let link = `${prefix}${i?.data[0]?.ImageDataVC}`
                                      setDocLink(link)
                                    })
                                    .catch(err => {
                                      console.log(err)
                                    })
                                    .finally(() => {
                                      let tempLoading = {
                                        ...docLoading,
                                        preview: false
                                      }
                                      setDocLoading(tempLoading)
                                      setOpen(true)
                                    })
                                }}
                              />
                            )}
                          </div>
                          <div className='col-1 d-contents ps-0 btn-documents'>
                            <Button
                              variant='outlined'
                              className='mr-2 '
                              onClick={() => {
                                downloadDocs(i, index)
                              }}
                            >
                              {docLoading?.download === true &&
                              docLoading?.index === index ? (
                                <CircularProgress size={'1rem'} />
                              ) : (
                                <FileDownloadOutlinedIcon />
                              )}
                            </Button>
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  {/*--Mobile Respnsive */}
                  <>
                    {docList.DocumentModelCollection.map((i, index) => {
                      return (
                        <div
                          class='card sip-card mt-3'
                          style={{
                            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                          }}
                        >
                          <div className='d-flex align-items-center'>
                            <div class='card-body'>
                              <ul
                                className='list-inline'
                                style={{ display: 'flex' }}
                              >
                                <li className='font-shipment'>Name</li>
                                <li className='font-shipment ps-2'>
                                  {i?.SC_FileName ?? 'N/A'}{' '}
                                </li>
                              </ul>
                              <ul
                                className='list-inline'
                                style={{ display: 'flex' }}
                              >
                                <li className='font-shipment'>Type</li>
                                <li className='ps-2 font-shipment'>
                                  {' '}
                                  {i?.SC_DocType ?? 'N/A'}
                                </li>
                              </ul>
                              <ul
                                className='list-inline'
                                style={{ display: 'flex' }}
                              >
                                <li className='font-shipment'>File Type</li>
                                <li className='ps-2 font-shipment'>
                                  {' '}
                                  {i?.SC_FileName.split('.')[1] ?? 'N/A'}
                                </li>
                              </ul>
                              <ul
                                className='list-inline'
                                style={{ display: 'flex' }}
                              >
                                <li className='font-shipment'>File Type</li>
                                <li className='ps-2 font-shipment'>
                                  {' '}
                                  {i?.SC_FileName.split('.')[1] ?? 'N/A'}
                                </li>
                              </ul>
                            </div>

                            <Button
                              variant='outlined'
                              className='mr-5 font-shipment'
                              onClick={() => {
                                downloadDocs(i)
                              }}
                            >
                              <FileDownloadOutlinedIcon />
                            </Button>
                          </div>
                        </div>
                      )
                    })}
                  </>
                </>
              ) : (
                <div className='d-flex justify-content-center align-items-center w-100 '>
                  No document found!
                </div>
              )}
            </>
          )}
        </LayoutDetail>
      )}
    </>
  )
}

export default Documentation
