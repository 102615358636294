import { createSlice } from "@reduxjs/toolkit";

const errorDataSlice = createSlice({
    name: "errorBooking",
    initialState: {
        Booking: {
            consignee: null,
            consigneeAddress: null,
            consignor: null,
            consignorAddress: null,
            origin: null,
            etd: null,
            destination: null,
            eta: null,
            transportmode: null,
            cargotype: null,
        }
    },
    reducers: {
        addErrors: (state, action) => {
            state.Booking= action.payload.value
        },

        resetErrorState: (state, action) => {
            state.Booking ={
                consignee: null,
                consigneeAddress: null,
                consignor: null,
                consignorAddress: null,
                origin: null,
                etd: null,
                destination: null,
                eta: null,
                transportmode: null,
                cargotype: null,
            }
        }
    }
})
export const { addErrors,resetErrorState } = errorDataSlice.actions;
export default errorDataSlice.reducer;