import React, { useState } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import "react-phone-number-input/style.css";
import {
  Grid,
  TextField,
  Button,
  Container,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from "@mui/material";
import "./AddUser.css"; // Import your CSS file
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import { useEffect } from "react";
const AddUser = ({ propertiesAddEditDelete, formik }) => {
  const sender = propertiesAddEditDelete.sender;
  // console.log(sender, "sendersender", formik?.values);
  const [number, setNumber] = useState(
    formik?.values?.ContactNumber ?? undefined
  );
  const disableStatus = propertiesAddEditDelete?.changeAllow;
  useEffect(() => {
    if (number == undefined || number == "") {
      formik.setFieldValue("ContactNumber", "");
    } else {
      const numberinfo = formatPhoneNumberIntl(number);
      const numberArray = numberinfo?.split(" ");
      const first = numberArray?.shift();
      const second = numberArray?.toString("")?.replaceAll(",", "");
      formik.setFieldValue("ContactNumber", first + "-" + second);
    }
  }, [number]);
  return (
    <Container mt={4}>
      <form className="user-form">
        {/* ContactName */}
        <Grid container>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <label htmlFor="ContactName"> Contact name : -</label>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
            {sender == "removeUser" ? (
              <Typography>{formik?.values?.ContactName ?? "N/A"}</Typography>
            ) : (
              <TextField
                label="ContactName"
                className="inputDivMargin"
                id="ContactName"
                type="text"
                required
                fullWidth
                disabled={disableStatus}
                error={
                  formik.touched.ContactName &&
                  Boolean(formik.errors.ContactName)
                }
                helperText={
                  formik.touched.ContactName ? formik.errors.ContactName : null
                }
                {...formik.getFieldProps("ContactName")}
              />
            )}
          </Grid>
        </Grid>

        {/* JobTitle */}
        <Grid container>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <label htmlFor="JobTitle"> Job Title : -</label>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
            {sender == "removeUser" ? (
              <Typography>
                {formik?.values?.JobTitle?.length > 0
                  ? formik?.values?.JobTitle
                  : "N/A"}
              </Typography>
            ) : (
              <TextField
                className="inputDivMargin"
                label="JobTitle"
                id="JobTitle"
                type="text"
                fullWidth
                disabled={disableStatus}
                {...formik.getFieldProps("JobTitle")}
              />
            )}
          </Grid>
        </Grid>

        {/* JobCategory  */}
        <Grid container>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <label htmlFor="JobCategory"> Job Category : -</label>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
            {sender == "removeUser" ? (
              <Typography>
                {formik?.values?.JobCategory.length > 0
                  ? formik?.values?.JobCategory
                  : "N/A"}
              </Typography>
            ) : (
              <TextField
                className="inputDivMargin"
                label="JobCategory"
                id="JobCategory"
                type="text"
                disabled={disableStatus}
                fullWidth
                {...formik.getFieldProps("JobCategory")}
              />
            )}
          </Grid>
        </Grid>

        {/*EmailId  */}
        <Grid container>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <label htmlFor="EmailId"> EmailId : -</label>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
            {sender == "removeUser" ? (
              <Typography>{formik?.values?.EmailId ?? "N/A"}</Typography>
            ) : (
              <TextField
                className="inputDivMargin"
                label="EmailId"
                id="EmailId"
                type="text"
                required
                disabled={disableStatus || sender == "editUser"}
                error={formik.touched.EmailId && Boolean(formik.errors.EmailId)}
                helperText={
                  formik.touched.EmailId ? formik.errors.EmailId : null
                }
                fullWidth
                {...formik.getFieldProps("EmailId")}
              />
            )}
          </Grid>
        </Grid>

        {/* Gender */}
        <Grid
          container
          style={sender != "removeUser" ? { marginBottom: "14px" } : null}
        >
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <label htmlFor="Gender"> Gender : -</label>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
            {sender == "removeUser" ? (
              <Typography>
                {formik?.values?.Gender?.length > 0
                  ? formik?.values?.Gender
                  : "N/A"}
              </Typography>
            ) : (
              <FormControl
                required
                fullWidth
                error={formik.touched.Gender && Boolean(formik.errors.Gender)}
              >
                <InputLabel id="demo-simple-select-error-label">
                  Gender
                </InputLabel>
                <Select
                  style={{ height: "45px" }}
                  labelId="demo-simple-select-error-label"
                  id="Gender"
                  label="Gender"
                  required
                  disabled={disableStatus}
                  {...formik.getFieldProps("Gender")}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
                <FormHelperText>
                  {formik.touched.Gender ? formik.errors.Gender : null}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
        </Grid>

        {/*ContactNumber*/}
        <Grid container>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <label htmlFor="ContactNumber"> Contact Number : -</label>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            lg={8}
            xl={8}
            className="phoneinputcss"
          >
            {sender == "removeUser" ? (
              <Typography>
                {formik?.values?.ContactNumber?.length > 0
                  ? formik?.values?.ContactNumber
                  : "N/A"}
              </Typography>
            ) : (
              <FormControl
                error={
                  formik.touched.ContactNumber &&
                  Boolean(formik.errors.ContactNumber)
                }
                style={{ width: "100%", maxWidth: "400px", margin: "0 auto" }}
              >
                <PhoneInput
                  label="ContactNumber"
                  id="ContactNumber"
                  international
                  initialValueFormat="national"
                  countryCallingCodeEditable={false}
                  defaultCountry="IN"
                  // required
                  disabled={disableStatus}
                  placeholder="Contact number"
                  // error={
                  //   formik.touched.ContactNumber &&
                  //   Boolean(formik.errors.ContactNumber)
                  // }
                  // helperText={
                  //   formik.touched.ContactNumber
                  //     ? formik.errors.ContactNumber
                  //     : null
                  // }
                  value={number}
                  onChange={setNumber}
                  onBlur={formik.handleBlur}
                  style={{ border: "none", width: "100%" }}
                />
                <FormHelperText>
                  {formik.touched.ContactNumber
                    ? formik.errors.ContactNumber
                    : null}
                </FormHelperText>
              </FormControl>
            )}
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default AddUser;
