import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DateAccordian from "./DateAccordian";
import InfoAccordian from "./InfoAccordian";

export default function VersionAccordion({
  allFormatedUpdataInformation,
  versionAndPushDate,
}) {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const versionFormat = (version) => {
    if (version % 10 == 0)
      return `V.${version / 10}.0_${versionAndPushDate.current[version]}`;
    return `V.${version / 10}_${versionAndPushDate.current[version]}`;
  };
  return (
    <div>
      {Object.keys(allFormatedUpdataInformation)
        .reverse()
        .map((version) => {
          return (
            <Accordion
              expanded={expanded === version}
              onChange={handleChange(version)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ width: "50%", flexShrink: 0 }}>
                  {versionFormat(version)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* <DateAccordian
                  versionDateInformaiton={allFormatedUpdataInformation[version]}
                /> */}
                <InfoAccordian Info={allFormatedUpdataInformation[version]} />
              </AccordionDetails>
            </Accordion>
          );
        })}
    </div>
  );
}
