import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import React, { useRef } from "react";
import instance from "../../../../../api/Interceptor";
import { useState } from "react";
import { API_PATH } from "../../../../../constant/apiPath";
import { useEffect } from "react";
const Modefilter = ({
  modeList,
  setModeList,
  modeListCopy,
  filterOptions,
  setFilterOptions,
  setModeListCopy,
  getCarrierCode
}) => {
  const modevalue = useRef("");
  const [open, setOpen] = useState(true);
  const filterModeList = (val) => {
    let temp = modeListCopy.filter((item) => {
      return item.Description.toLowerCase().includes(val.toLowerCase());
    });
    setModeList(temp);
  };
  return (
    <div
      style={{
        padding: "2%",
      }}
    >
      <div
        style={{
          border: "0.1rem solid var(--color-primary)",
          borderRadius: "5px",
          padding: "2%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography>Mode</Typography>
        <IconButton onClick={() => setOpen(!open)}>
          <KeyboardArrowDownIcon />
        </IconButton>
      </div>
      {open ? (
        <>
          <div
            style={{
              borderRadius: "7px",
              backgroundColor: "var(--color-miscellaneous-6)",
              marginTop: "2%",
            }}
          >
            <IconButton sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <input
              type="text"
              value={modevalue.current}
              style={{
                border: "none",
                width: "80%",
                backgroundColor: "var(--color-miscellaneous-6)",
              }}
              onChange={(e) => {
                const { value } = e.target;
                var letters = /^[A-Za-z]+$/;
                if (value.match(letters) || value.length === 0) {
                  modevalue.current = value;
                  filterModeList(e.target.value);
                }
              }}
            />
          </div>
          <div
            style={{
              maxHeight: "25vh",
              overflowY: "auto",
            }}
          >
            <FormControl
              sx={{
                padding: "2%",
              }}
            >
              {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
              <RadioGroup
                aria-labelledby="mode filter"
                defaultValue={
                  filterOptions.filterMode == null
                    ? "AIR"
                    : filterOptions.filterMode
                }
                name="radio-buttons-group"
                onChange={(e) => {
                  const modeSelected = e.target.value
                  setFilterOptions({
                    ...filterOptions,
                    filterMode: e.target.value,
                  });
                  getCarrierCode(modeSelected)
                }}
              >
                {modeList.map((mode) => {
                  return (
                    <FormControlLabel
                      control={<Radio value={mode.Code} />}
                      label={mode.Description}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </div>
        </>
      ) : null}
    </div>
  );
};
export default Modefilter;
