import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import "../updatePopUp/updateInformation.css";
import VersionAccordion from "./VersionAccordion";
import { Skeleton } from "@mui/material";
import { useRef } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function UpdateInformaiton({ open, setOpen }) {
  const [allFormatedUpdataInformation, setAllFormatedUpdataInformation] =
    useState({});
  const handleClose = () => {
    setOpen(false);
  };
  const isObjectEmpty = (objectName) => {
    return JSON.stringify(objectName) === "{}";
  };
  const versionAndPushDate = useRef({});
  function informationFilter(info) {
    const updateInformationObject = {
      "Features Added": [],
      Improvements: [],
      "Bugs Fixed": [],
      "Performance Enhancements": [],
    };
    if (info?.length > 0) {
      info.forEach((obj) => {
        updateInformationObject[obj.Header]?.push(obj);
      });
      return { ...updateInformationObject };
    }
  }
  function areDatesEqual(dateStr1, dateStr2) {
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  const { updateInformation, loading: loadingUpdateData } = useSelector(
    (state) => state.updateInformation
  );
  const groupByVersion = (updateInformation) => {
    let info2 = {};
    let versioned = updateInformation?.data[0]?.Version;
    info2 = { [versioned]: [] };
    updateInformation?.data.forEach((element) => {
      if (element.Version == versioned) {
        info2[versioned]?.push(element);
      } else {
        versioned = element?.Version;
        info2[versioned] = [];
        info2[versioned].push(element);
      }
    });
    return info2;
  };
  const groupByDate = (information) => {
    if (information?.length == 0 || information == undefined) return [];
    let info = {};
    let date = information[0]?.PushUtcDate;
    info = { [date]: [] };
    information?.forEach((element) => {
      if (areDatesEqual(date, element?.PushUtcDate)) {
        info[date].push(element);
      } else {
        date = element?.PushUtcDate;
        info[date] = [];
        info[date].push(element);
      }
    });
    return info;
  };
  const divisionOnHeader = (singleVersionInfo) => {
    let result = {};
    Object.keys(singleVersionInfo).forEach((singleDate) => {
      result[singleDate] = informationFilter(singleVersionInfo[singleDate]);
    });
    return result;
  };
  function formatDate(dateString) {
    // Parse the date string to a Date object
    const date = new Date(dateString);

    // Extract day, month, and year
    let day = date.getDate().toString();
    let month = (date.getMonth() + 1).toString(); // getMonth() returns 0-11
    const year = date.getFullYear().toString();

    // Ensure day and month are two digits
    day = day.length < 2 ? "0" + day : day;
    month = month.length < 2 ? "0" + month : month;

    // Concatenate in the desired format
    return day + month + year;
  }
  function getMaxDate(dateArray) {
    // Convert string dates to Date objects
    const dateObjects = dateArray.map((dateString) => new Date(dateString));

    // Find the maximum date
    const maxDate = new Date(Math.max(...dateObjects));

    return formatDate(maxDate);
  }
  const pushDate = (versionData) => {
    //array of object
    console.log(versionData, "versiondata");
    const ArrayOfDate = versionData?.map(
      (singledata) => singledata?.PushUtcDate
    );
    return getMaxDate(ArrayOfDate);
  };
  useEffect(() => {
    if (!isObjectEmpty(updateInformation)) {
      const versionDetails = groupByVersion(updateInformation);
      Object.keys(versionDetails).forEach((singleversion) => {
        versionAndPushDate.current[singleversion] = pushDate(
          versionDetails[singleversion]
        );
      });
      const info = divisionOnHeader(versionDetails);
      // Object.keys(copyData).forEach((data) => {
      //   copyData[data].forEach((singleinfo) =>
      //     date.add(singleinfo?.PushUtcDate)
      //   );
      // });
      // console.log(versionDetails,"versionDetailsversionDetails")
      // Object.keys(versionDetails).forEach((eachVersion, index) => {
      //   const obj = versionDetails[eachVersion];
      //   const info = groupByDate(obj);
      //   const ans = divisionOnHeader(info);
      //   versionDetails[eachVersion] = ans;
      // });
      setAllFormatedUpdataInformation(info);
    }
  }, [updateInformation]);
  return (
    <BootstrapDialog
      fullWidth={true}
      maxWidth={"md"}
      // onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        All Updates
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        {loadingUpdateData ? (
          <div className="loaderCss">
            <Skeleton sx={{ height: "150%", width: "100%" }} />
          </div>
        ) : (
          <div style={{ overflowX: "hidden" }}>
            {!isObjectEmpty(allFormatedUpdataInformation) ? (
              <>
                <VersionAccordion
                  allFormatedUpdataInformation={allFormatedUpdataInformation}
                  versionAndPushDate={versionAndPushDate}
                />
              </>
            ) : (
              <div className="noDataFound">
                <div className="center">No Data Found</div>
              </div>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          ok
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}
