import { baseOrderUrl } from "../../../../../Config";
import instance from "../../../../../api/Interceptor";
import orderInstance from "../../../../../api/orderInterceptor";
import { API_PATH } from "../../../../../constant/apiPath";
// ORDER NUMBER
const getAgents = async () => {
  let { data } = await orderInstance.get(
    `${baseOrderUrl}${API_PATH.GET_AGENTS}`
  );
  return data;
};

//ORDER DETAILS
const getCurrencyofPayment = async () => {
  let { data } = await orderInstance.get(
    `${baseOrderUrl}${API_PATH.GET_CURRENCIES}`
  );
  return data;
};
const getContainerModes = async () => {
  let { data } = await orderInstance.get(
    `${baseOrderUrl}${API_PATH.GET_CONTAINER_MODE}`
  );
  return data;
};
const getTransportModes = async () => {
  let { data } = await orderInstance.get(
    `${baseOrderUrl}${API_PATH.GET_ORDER_TRANSPORT_MODES}`
  );
  return data;
};
const getIncoTerm = async () => {
  let { data } = await orderInstance.get(
    `${baseOrderUrl}${API_PATH.GET_INCO_TERM}`
  );
  return data;
};
const getCountries = async () => {
  let { data } = await orderInstance.get(
    `${baseOrderUrl}${API_PATH.GET_COUNTRIES}`
  );
  return data;
};
const getServiceLevel = async () => {
  let { data } = await orderInstance.get(
    `${baseOrderUrl}${API_PATH.GET_SERVICE_LEVEL_CODES}`
  );
  return data;
};
const getPackageType = async () => {
  let { data } = await orderInstance.get(
    `${baseOrderUrl}${API_PATH.GET_PACKAGE_TYPE}`
  );
  return data;
};

//Buyer and Supplier
const getBuyer = async () => {
  let { data } = await orderInstance.get(
    `${baseOrderUrl}${API_PATH.GET_BUYER_NAME}?orgCode=${""}`
  );
  return data;
};

const getSupplier = async () => {
  let { data } = await orderInstance.get(
    `${baseOrderUrl}${API_PATH.GET_SUPPLIER_NAME}?orgCode=${""}`
  );
  return data;
};
// buyer and supplier address  api
const getAddress = async (code) => {
  let { data } = await orderInstance.get(
    `${baseOrderUrl}${API_PATH.GET_ADDRESS}?orgCode=${code}`
  );
  return data;
};
const getWeightUnit = async () => {
  let { data } = await orderInstance.get(
    `${baseOrderUrl}${API_PATH.GET_WEIGHT_UNITS}`
  );
  return data;
};
const getVolumeUnit = async () => {
  let { data } = await orderInstance.get(
    `${baseOrderUrl}${API_PATH.GET_VOLUME_UNITS}`
  );
  return data;
};
const getDimensionsUnit = async () => {
  let { data } = await orderInstance.get(
    `${baseOrderUrl}${API_PATH.GET_DIMENSIONS_UNITS}`
  );
  return data;
};
const getUnlocos = async (countryCode, portSearch) => {
  let { data } = await orderInstance.get(
    `${baseOrderUrl}${API_PATH.GET_UNLOCOS}${countryCode}&unloco=${portSearch}`
  );
  return data;
};
const getCarrierCodes = async () => {
  let { data } = await instance.get(`${API_PATH.GET_CARRIER_CODES}?Mode=${""}`);
  return data;
};

const getOrderDetails = async (orderPK) => {
  let { data } = await orderInstance.get(
    `${API_PATH.GET_ORDER_DETAIL}${orderPK}`
  );
  return data;
};

export {
  getAgents,
  getCurrencyofPayment,
  getContainerModes,
  getIncoTerm,
  getCountries,
  getBuyer,
  getSupplier,
  getTransportModes,
  getServiceLevel,
  getAddress,
  getPackageType,
  getWeightUnit,
  getVolumeUnit,
  getDimensionsUnit,
  getUnlocos,
  getCarrierCodes,
  getOrderDetails,
};
