
export const formatCLPAmount = (amount) => {
    if (!amount) return '';
  
    let amountStr = amount.toString().replace(/[\.,]/g, '');
    let integerPart = amountStr.split('').reverse().join('');
    let formattedInteger = integerPart
      .match(/.{1,3}/g)
      .join('.')
      .split('')
      .reverse()
      .join('');
  
    return formattedInteger;
  };
  
  export const formatInvoiceAmount = (amount, currency) => {
    if (!amount || !currency) return '';
  
    if (currency === 'USD') {
      let amountStr = parseFloat(amount).toFixed(2);
      let [integerPart, decimalPart] = amountStr.split('.');
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      return `${integerPart},${decimalPart}`;
    }
  
    return amount;
  };
  