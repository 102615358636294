import { Description } from "@mui/icons-material";
import { createSlice } from "@reduxjs/toolkit";

const storeDataSlice = createSlice({
  name: "createBooking",
  initialState: {
    store: {
      originunloco: [],
      destunloco: [],
      carrier: [],
    },
    // carrierstore: {
    //   carrieroldlist: [],
    //   carriernewlist: [],
    //   search: "",
    //   carrierdata: {},
    // },
  },
  reducers: {
    originstorepage: (state, action) => {
      state.store.originunloco = action.payload;
    },

    deststorepage: (state, action) => {
      state.store.destunloco = action.payload;
    },
    carrierStorePage: (state, action) => {
      state.store.carrier = action.payload;
    },
  },
});

export const { originstorepage, deststorepage, carrierStorePage } =
  storeDataSlice.actions;
export default storeDataSlice.reducer;
