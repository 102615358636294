import React, { useContext, useEffect, useState } from 'react'
import LayoutDetail from '../../../components/layout/LayoutDetail'
import '../shipment.css'
import { AuthContext } from '../../../store/context/AuthProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import instance from '../../../api/Interceptor'
import { API_PATH } from '../../../constant/apiPath'
import {
  Alert,
  Grid,
  IconButton,
  Skeleton,
  Snackbar,
  Tooltip
} from '@mui/material'
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined'
import { Routeconstant } from '../../../routing/utils/RouteConstant'
import formatNumber from '../../../components/format/formatNumber'
import { formatCustomNumber } from '../../../utils/NumberFormatWeightVolume';

const ShipmentDetails = props => {
  const [shipmentDetails, setShipmentDetails] = useState({})
  const location = useLocation()
  const context = useContext(AuthContext)
  const [accordin, setAccordin] = useState({
    first: true,
    second: true,
    third: true
  })

  const [dataContainer, setDataContainer] = useState(false)
  const [loading, setLoading] = useState(false)

  const getShipmentDetails = () => {
    setLoading(true)
    const body = {
      ShipmentId: context?.shipmentId ?? '',
      TransportMode: '',
      ContainerType: '',
      Carrier: '',
      HouseBillNumber: '',
      Consignee: '',
      Consignor: '',
      OrderNumber: '',
      MasterBillNumber: '',
      ETAFrom: '',
      ETATo: '',
      ETDFrom: '',
      ETDTo: '',
      ATDFrom: '',
      ATDTo: '',
      ATAFrom: '',
      ATATo: '',
      EmailID: '',
      ShowDeveloperResponse: true,
      DateFrom: '',
      DateTo: '',
      Status: '',
      CurrentPage: 0,
      PageSize: 0,
      SortColumnName: '',
      SortDirection: ''
    }
    instance
      .post(API_PATH.GET_SHIPMENT_DETAILS, body)
      .then(res => {
        setLoading(false)
        setShipmentDetails(res?.data[0] ?? {})
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  
  useEffect(() => {
    if (props.hasOwnProperty('shipmentDetails')) {
      setShipmentDetails(props.shipmentDetails)
    } else {
      getShipmentDetails()
    }
  }, [context?.shipmentId])
  const navigate = useNavigate()
  useEffect(() => {
    if (context?.shipmentId?.length === 0) {
      navigate(Routeconstant.SHIPMENT_LISTING)
    }
  }, [])

  return (
    <LayoutDetail shipmentDetails={shipmentDetails}>
      {loading ? (
        <div className='skeleton-div'>
          <Skeleton style={{ height: '45rem', marginTop: '1rem' }} />
        </div>
      ) : (
        <>
          {context.gridViewShipment ? (
            <div className='p-2 '>
              <p className='basic-head font-change'>Basic Details</p>
            </div>
          ) : null}
          {/* --Shipment specifics-- */}
          <Grid
            container
            spacing={1}
            sx={{
              padding: '0% 1%'
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <p
                style={{
                  minWidth: 'fit-content'
                }}
                className='m-0 basic-specific'
              >
                Shipment Specifics
              </p>
              <div
                style={{
                  border: '1px solid var(--color-secondary-light) ',
                  width: '90%',
                  opacity: '0.4',
                  marginLeft: '20px'
                }}
              />

              <IconButton
                onClick={e => {
                  if (accordin.first === true) {
                    setAccordin({ ...accordin, first: false })
                  } else {
                    setAccordin({ ...accordin, first: true })
                  }
                }}
              >
                <ExpandCircleDownOutlinedIcon color='primary' />
              </IconButton>
            </Grid>
            {accordin.first ? (
              <>
                <Grid
                  container
                  spacing={2}
                  style={{ paddingLeft: '1%', paddingBottom: '1rem' }}
                >
                  <Grid item lg={2.5} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className='m-0  text-head'>Master Bill No.</p>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        {shipmentDetails &&
                          shipmentDetails?.MasterBillNumber && (
                            <p
                              className='m-0 text-sub overflow-text'
                              title={shipmentDetails?.MasterBillNumber}
                            >
                              {shipmentDetails?.MasterBillNumber[0] ?? null}{' '}
                              <span>
                                {shipmentDetails?.MasterBillNumber.length > 1
                                  ? '...'
                                  : ''}
                              </span>
                            </p>
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={2} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className='m-0 text-head '>House Bill No.</p>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p
                          title={shipmentDetails?.HouseBillNumber}
                          className='m-0 text-sub '
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {shipmentDetails?.HouseBillNumber ?? null}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={2} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className='m-0 text-head '>Order Reference No.</p>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className='m-0 text-sub overflow-text overflow-text-order'>
                          {shipmentDetails?.OrderRef ?? null}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={2} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className='m-0 text-head'>Type of Shipment</p>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className='m-0 text-sub'>
                          {shipmentDetails?.ShipmentType ??
                            shipmentDetails?.ShipmentType ??
                            null}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={1.5} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <p className='m-0 text-head '>INCO Terms</p>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p className='m-0 text-sub'>
                        {shipmentDetails?.INCOTerm ?? null}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid item lg={2} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <p className='m-0 text-head '>Shipper Reference</p>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p className='m-0 text-sub'>
                        {shipmentDetails?.ShipperRef ?? null}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
          {/* --Container and cargo description-- */}
          <Grid
            container
            spacing={1}
            sx={{
              padding: '0% 1%'
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <p
                style={{
                  minWidth: 'fit-content'
                }}
                className='m-0 basic-specific'
              >
                {`${
                  shipmentDetails?.TransportMode !== 'AIR'
                    ? 'Container and Cargo Description'
                    : 'Cargo Description'
                }`}
              </p>

              <div
                style={{
                  border: '1px solid var(--color-secondary-light) ',
                  width: '90%',
                  opacity: '0.4',
                  marginLeft: '20px'
                }}
              />

              <IconButton>
                <ExpandCircleDownOutlinedIcon
                  color='primary'
                  onClick={() => {
                    if (accordin.second === true) {
                      setAccordin({ ...accordin, second: false })
                    } else {
                      setAccordin({ ...accordin, second: true })
                    }
                  }}
                />
              </IconButton>
            </Grid>

            {accordin.second ? (
              <>
                <Grid
                  container
                  spacing={2}
                  style={{ paddingLeft: '1%', paddingBottom: '1rem' }}
                >
                  <Grid item lg={2} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className='m-0 text-head '>
                          {shipmentDetails?.TransportMode === 'AIR'
                            ? 'Airline'
                            : 'Carrier'}{' '}
                        </p>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        {shipmentDetails && shipmentDetails?.CarrierName && (
                          <p
                            className='m-0 text-sub overflow-text'
                            title={shipmentDetails?.CarrierName}
                          >
                            {shipmentDetails?.CarrierName[0] ?? null}{' '}
                            <span>
                              {shipmentDetails?.CarrierName.length > 1
                                ? '...'
                                : ''}
                            </span>
                          </p>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {shipmentDetails?.TransportMode !== 'AIR' && (
                    <>
                      <Grid item lg={3} md={4} sm={4} xs={6}>
                        <Grid container spaing={0}>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className='d-flex align-items-center'
                          >
                            <p className='m-0 text-head'>Container No.</p>
                            <IconButton>
                              <ExpandCircleDownOutlinedIcon
                                // color="primary"
                                onClick={() => {
                                  setDataContainer(!dataContainer)
                                }}
                              />
                            </IconButton>
                          </Grid>

                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            {shipmentDetails &&
                              shipmentDetails.ContainerNumbers && (
                                <p
                                  className='m-0 text-sub overflow-text'
                                  title={shipmentDetails.ContainerNumbers}
                                >
                                  {dataContainer ? (
                                    <>
                                      {' '}
                                      {shipmentDetails?.ContainerNumbers?.toString()
                                        .split(',')
                                        .join(', ') ?? null}{' '}
                                      {/* <span>
                                        {shipmentDetails.ContainerNumbers
                                          .length > 1
                                          ? "..."
                                          : ""}
                                      </span> */}
                                    </>
                                  ) : (
                                    <>
                                      {' '}
                                      {shipmentDetails?.ContainerNumbers
                                        ?.length &&
                                        shipmentDetails
                                          ?.ContainerNumbers[0]}{' '}
                                      <span>
                                        {shipmentDetails?.ContainerNumbers
                                          ?.length > 1
                                          ? '...'
                                          : ''}
                                      </span>
                                    </>
                                  )}
                                </p>
                              )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={2} md={4} sm={4} xs={6}>
                        <Grid container spaing={0}>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <p className='m-0 text-head '>Container Count</p>
                          </Grid>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <p
                              className='m-0 text-sub'
                              title={shipmentDetails?.FCLContainerCount ?? null}
                            >
                              {shipmentDetails?.FCLContainerCount ?? null}
                            </p>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Grid item lg={1.5} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <p className='m-0 text-head'>Release Type</p>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p
                        className='m-0 text-sub'
                        title={shipmentDetails?.ReleaseType ?? null}
                      >
                        {shipmentDetails?.ReleaseType ?? null}
                      </p>
                    </Grid>
                  </Grid>
                  {/* <Grid item lg={1.5} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className='m-0 text-head '>Weight</p>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p
                          className='m-0 text-sub overflow-text'
                          title={formatNumber(
                            shipmentDetails?.TotalWeight ?? 'N/A'
                          )}
                        >
                          {formatNumber(shipmentDetails?.TotalWeight ?? 'N/A')}
                          {/* {shipmentDetails?.TotalWeight ?? null} */}
                          {/* {shipmentDetails?.TotalWeightUQ ?? null}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid> */}
                  
                  <Grid item lg={1.5} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className="m-0 text-head ">Weight</p>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p
                          className="m-0 text-sub overflow-text"
                          title={formatCustomNumber(
                            shipmentDetails?.TotalWeight ?? 'N/A'
                          )}
                        >
                          {formatCustomNumber(shipmentDetails?.TotalWeight ?? 'N/A')}
                          {shipmentDetails?.TotalWeightUQ ? ` ${shipmentDetails?.TotalWeightUQ}` : ''}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item lg={2} md={4} sm={4} xs={6}>
                    <Grid container spaing={0}>
                      <p className='m-0 text-head'>Volume</p>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p
                        className='m-0 text-sub overflow-text'
                        title={formatCustomNumber(
                          shipmentDetails?.TotalVolume ?? 'N/A'
                        )}
                      >
                        {formatCustomNumber(shipmentDetails?.TotalVolume ?? 'N/A')}

                        {/* {shipmentDetails?.TotalVolume ?? null} */}

                        &nbsp;{shipmentDetails?.TotalVolumeUQ ?? null}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
          {/* --Additional details-- */}
          <Grid
            container
            spacing={1}
            sx={{
              padding: '0% 1%'
            }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <p
                style={{
                  minWidth: 'fit-content'
                }}
                className='m-0 basic-specific'
              >
                {' '}
                Additional Details
              </p>
              <div
                style={{
                  border: '1px solid var(--color-secondary-light) ',
                  width: '90%',
                  opacity: '0.4',
                  marginLeft: '20px'
                }}
              />
              <IconButton
                onClick={() => {
                  if (accordin.third === true) {
                    setAccordin({ ...accordin, third: false })
                  } else {
                    setAccordin({ ...accordin, third: true })
                  }
                }}
              >
                <ExpandCircleDownOutlinedIcon color='primary' />
              </IconButton>
            </Grid>

            {accordin.third ? (
              <>
                <Grid
                  container
                  spacing={2}
                  style={{ paddingLeft: '1%', paddingBottom: '1rem' }}
                >
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className='m-0 text-head'>Goods Description</p>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className='m-0 text-sub'>
                          {shipmentDetails?.GoodsDescription ?? null}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className='m-0 text-head text-head'>
                          Marks and Comments
                        </p>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className='m-0 text-head text-sub'>
                          {shipmentDetails?.MarksandNumberDescription ?? null}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className='m-0 text-head text-head'>
                          Client Visible Note
                        </p>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className='m-0 text-head text-sub'>
                          {shipmentDetails?.ClientVisibleNoteDescription ??
                            null}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={0}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className='m-0 text-head text-head'>Goods value</p>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className='m-0 text-head text-sub'>
                          {/* {shipmentDetails?.GoodsValue ?? null} */}
                          {formatNumber(shipmentDetails?.GoodsValue ?? 'N/A')}

                          {shipmentDetails?.GoodsValueCurrencyCode ? (
                            <span>
                              ({shipmentDetails.GoodsValueCurrencyCode})
                            </span>
                          ) : null}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
        </>
      )}
    </LayoutDetail>
  )
}

export default ShipmentDetails
