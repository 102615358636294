import instance from "../api/Interceptor";

const useApiCall = async (apiUrl, body, method) => {
  let informationReceived = {};
  try {
    await instance[method](apiUrl, body).then((responce) => {
      informationReceived = responce;
    });
  } catch (err) {
    console.log(err);
  }
  return informationReceived;
};

export default useApiCall;
