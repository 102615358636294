import toast from "react-hot-toast";
import instance from "../../../api/Interceptor";
import { API_PATH } from "../../../constant/apiPath";

const userRegisterApiEditAndUpdate = async (
  data,
  type,
  handleClose,
  setBackDropLoader,
  getAllUser,
  pageNo
) => {
  const body = {
    ...data,
    Gender: data.Gender.charAt(0),
  };
  const formData = new FormData();
  Object.keys(body).forEach((key) => {
    formData.append(key, body[key]);
  });
  setBackDropLoader(true);
  try {
    instance[type](`${API_PATH.REFISTER_USER}`, formData).then(
      (response) => {
        if (response?.IsSuccess) {
          toast.success(response?.message?.Message);
          getAllUser(pageNo);
        } else {
          toast.error(response?.message?.Message);
        }
      },
      () => {
        toast.error("Invalid Email ID");
      }
    );
  } catch (err) {
    toast.error("Server issue");
    console.log(err);
  } finally {
    setBackDropLoader(false);
    handleClose();
  }
};
const userRegisterApiDelete = async (
  data,
  getActiveCount,
  handleClose,
  setBackDropLoader,
  getAllUser,
  pageNo
) => {
  setBackDropLoader(true);
  try {
    instance
      .delete(`${API_PATH.REFISTER_USER}?emailId=${data?.EmailId ?? null}`)
      .then(
        (response) => {
          if (response?.IsSuccess) {
            getAllUser(pageNo);
            getActiveCount(); //to update the count
            toast.success(response?.message?.Message);
          } else {
            toast.error(response?.message?.Message ?? "Server issue");
          }
        },
        () => {
          toast.error("Invalid Email ID");
        }
      );
  } catch (err) {
    toast.error("Server issue");
    console.log(err);
  } finally {
    setBackDropLoader(false);
    handleClose();
  }
};

export { userRegisterApiEditAndUpdate, userRegisterApiDelete };
