import axios from 'axios'
import React, { useContext, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  basename,
  baseUrl,
  LOCAL_STORAGE_KEY,
  SECRET_KEY
} from '../../../Config'
import { Routeconstant } from '../../../routing/utils/RouteConstant'
import { AuthContext } from '../../../store/context/AuthProvider'
import { Buffer } from 'buffer'
import jwt_decode from 'jwt-decode'
import './login.css'
import { ConeSeries } from '@amcharts/amcharts4/charts'
import { toast } from 'react-hot-toast'
import { API_PATH } from '../../../constant/apiPath'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useEffect } from 'react'
import { FaSyncAlt } from 'react-icons/fa'
import CancelIcon from '@mui/icons-material/Cancel'
import LoopIcon from '@mui/icons-material/Loop'
import CryptoJS from 'crypto-js'
import instance from '../../../api/Interceptor'
import modulePermission from '../../../utils/modulePermission'
import encryptData from '../../../decryptencrypt/encrypt'
import urlResolver from '../../../utils/urlResolver'

const Login = ({ setModuleAccessibility }) => {
  const formRef = useRef(null)
  const navigate = useNavigate()
  const context = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [fieldError, setFieldError] = useState({})
  const [isError, setIsError] = useState(false)
  const [id, setId] = useState(null)
  const [field, setField] = useState({
    Email: '',
    Password: ''
  })
  const [trackYourOrder, setTrackYourOrder] = useState(false)
  const [showcaptcha, setShowcaptcha] = useState(true)
  const [showcaptchaTrack, setShowcaptchaTrack] = useState(true)
  const [captchaCode, setCaptchaCode] = useState('')
  const [captchaCodeTrack, setCaptchaCodeTrack] = useState('')
  const [captcha, setCaptcha] = useState('')
  const [captchaTrack, setCaptchaTrack] = useState('')
  const [captchaError, setCaptchaError] = useState('')
  const [captchaErrorTrack, setCaptchaErrorTrack] = useState('')
  const [errorCount, setErrorCount] = useState(0)
  const [errorCountTrack, setErrorCountTrack] = useState(0)
  const [backgroundImage, setBackgroundImage] = useState('')
  const [backgroundImageTrack, setBackgroundImageTrack] = useState('')

  const handlePaste = e => {
    e.preventDefault()
  }

  useEffect(() => {
    generateCaptcha()
    generateBackgroundImage()
    generateCaptchatrack()
    generateBackgroundImageTrack()
  }, [])
  // const clearCaptchaInput = () => {
  //   if (captchaRef.current) {
  //     captchaRef.current.value = "";
  //   }
  //   console.log("captchaRef",captchaRef.current);
  // };
  const generateCaptcha = () => {
    // // Generate a random CAPTCHA code
    // const code = Math.random().toString(36).slice(2, 8).toUpperCase();
    // setCaptchaCode(code);
    // // Reset error count
    // setErrorCount(0);
    const characters = 'ABCDEFGHKLMNPRSTUVWXYZ2345689'
    // Generate a random CAPTCHA code
    let code = ''
    // Generate characters of both cases
    for (let i = 0; i < 6; i++) {
      const randomChar =
        characters[Math.floor(Math.random() * characters.length)]
      code += randomChar
    }
    setCaptchaCode(code)
    // Reset error count
    setErrorCount(0)
  }
  const generateCaptchatrack = () => {
    const characters = 'ABCDEFGHKLMNPRSTUVWXYZ2345689'
    // Generate a random CAPTCHA code
    let code = ''
    // Generate characters of both cases
    for (let i = 0; i < 6; i++) {
      const randomChar =
        characters[Math.floor(Math.random() * characters.length)]
      code += randomChar
    }
    setCaptchaCodeTrack(code)
    // Reset error count
    setErrorCountTrack(0)
  }
  const generateBackgroundImage = () => {
    const patterns = [
      'pattern-dot',
      'pattern-dash',
      'pattern-cross-dots',
      'pattern-cross'
    ]
    const randomIndex = Math.floor(Math.random() * patterns.length)
    const patternClass = patterns[randomIndex]
    setBackgroundImage(patternClass)
  }
  const generateBackgroundImageTrack = () => {
    const patterns = [
      'pattern-dot',
      'pattern-dash',
      'pattern-cross-dots',
      'pattern-cross'
    ]
    const randomIndex = Math.floor(Math.random() * patterns.length)
    const patternClass = patterns[randomIndex]
    setBackgroundImageTrack(patternClass)
  }
  const validateCaptcha = e => {
    setCaptcha(e.target.value)
    if (e.target.value.trim() === '') {
      setCaptchaError('Enter the CAPTCHA code!')
    } else if (e.target.value.trim() !== captchaCode) {
      setCaptchaError('Enter the valid CAPTCHA code!')
    } else {
      setCaptchaError('')
    }
  }
  const validateCaptchatrack = e => {
    setCaptchaTrack(e.target.value)
    if (e.target.value.trim() === '') {
      setCaptchaErrorTrack('Enter the CAPTCHA code!')
    } else if (e.target.value.trim() !== captchaCodeTrack) {
      setCaptchaErrorTrack('Enter the valid CAPTCHA code!')
    } else {
      setCaptchaErrorTrack('')
    }
  }
  const refreshCaptcha = () => {
    generateCaptcha()
    generateBackgroundImage()
  }
  const refreshCaptchaTrack = () => {
    generateCaptchatrack()
    generateBackgroundImageTrack()
  }
  const handleInputChange =
    input =>
    ({ target: { value } }) => {
      setIsError(false)
      setField({
        ...field,
        [input]: value
      })

      const formErrors = { ...fieldError }

      switch (input) {
        case 'Password':
          formErrors.Password =
            value.length >= 0 && value.replaceAll(' ', '').length < 5
              ? 'Minimum 5 character required'
              : ''
          break

        case 'Email':
          formErrors.Email =
            value.match(
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            ) == null
              ? 'Please add valid emailId'
              : ''
          break

        default:
          break
      }

      Object.values(formErrors).forEach(function (error) {
        if (error.length > 0) {
          setIsError(true)
        }
      })

      setFieldError({
        ...formErrors
      })
    }
  const [showPassword, setShowPassword] = React.useState(false)

  const handleKeyPress = event => {
    if (event && event.key === 'Enter') {
      event.preventDefault()

      // Refresh CAPTCHA code
      // refreshCaptcha();

      // Trigger login process
      onUserLogin()
    }
  }
  const handleKeyPressship = event => {
    if (!id || !captchaTrack || captchaErrorTrack) {
      return
    } else {
      if (event && event.key === 'Enter') {
        event.preventDefault()

        let encrypted = CryptoJS.AES.encrypt(id, SECRET_KEY).toString()
        let encoded = CryptoJS.enc.Base64.parse(encrypted).toString(
          CryptoJS.enc.Hex
        )

        navigate(`${'/shipment/details/'}${encoded}`)
      }
    }
  }
  const onUserLogin = event => {
    if (event) {
      event.preventDefault()
    }
    setLoading(true)
    const newErrorCount = errorCount + 1
    setErrorCount(newErrorCount)

    if (showcaptcha) {
      if (captcha.trim() === '') {
        setCaptchaError('Enter the CAPTCHA code!')
        setLoading(false)
        return
      }
      // Validate CAPTCHA code
      if (captcha.trim() !== captchaCode) {
        setCaptchaError('Enter the valid CAPTCHA code!')
        setLoading(false)
        return
      }
    }
    refreshCaptcha()
    const emptyFields = Object.keys(field).filter(
      key => field[key].trim() === ''
    )
    if (emptyFields.length > 0) {
      const newFieldError = { ...fieldError }
      emptyFields.forEach(emptyField => {
        newFieldError[emptyField] = 'This field is required'
      })
      setFieldError(newFieldError)
      setLoading(false)
      return
    }
    // setCaptcha("");
    // console.log("captcha",captcha);
    const token = Buffer.from(
      `${field.Email}:${field.Password}`,
      'utf8'
    ).toString('base64')
    const config = {
      method: 'post',
      url: `${baseUrl}${API_PATH.LOGIN}`,
      headers: { Authorization: `Basic ${token}` }
    }
    axios(config)
      .then(response => {
        setLoading(false)
        if (response.data.IsSuccess) {
          context.setToken(response.data.ResponseData)
          context.setIslogin(true)
          localStorage.setItem(
            LOCAL_STORAGE_KEY,
            JSON.stringify(response.data.ResponseData)
          )
          const decoded = jwt_decode(response.data.ResponseData.AccessToken)
          let obj = {
            Role: '',
            Name: '',
            Email: ''
          }

          if (Object.keys(decoded).length) {
            Object.keys(decoded).map(item => {
              if (item.includes('role')) {
                obj.Role = decoded[item]
              } else if (item.includes('name')) {
                obj.Name = decoded[item]
              } else if (item.includes('emailaddress')) {
                obj.Email = decoded[item]
              }
            })
          }
          context.setUserData(obj)
          setErrorCount(0)
          setCaptcha('')
          // clearCaptchaInput();
          // to get the data of access module
          ;(async () => {
            try {
              instance
                .get(`${API_PATH.GET_MODULE}?emailid=${field.Email}`)
                .then(
                  res => {
                    encryptData('moduleAccessibility', res?.data)
                    // localStorage.setItem(
                    //   "moduleAccessibility",
                    //   JSON.stringify(res?.data)
                    // );
                  },
                  err => {
                    console.log(err)
                  }
                )
            } catch (err) {
              console.log(err)
            } finally {
              navigate(Routeconstant.LANDING_PAGES)
              // time so that all the module permission be set
              setTimeout(() => {
                setModuleAccessibility(modulePermission())
              }, 1000)
            }
          })()
        } else {
          toast.error(response.data.Message.Message)
          const newToastErrorCount = errorCount + 1
          // if (newToastErrorCount >= 4) {
          //   setShowcaptcha(true);
          // }
          setErrorCount(newToastErrorCount)
          // clearCaptchaInput();
          setCaptcha('')
        }
      })
      .catch(error => {
        toast.error('Something went wrong!')
        setLoading(false)
        console.log(error)
        setCaptcha('')
      })
  }

  const Captcharefresh = event => {
    event.preventDefault()
  }
  if (trackYourOrder) {
    return (
      <Grid container style={{ height: '100vh', width: '100vw' }}>
        <div className='backgimgForTrackShipment'></div>
        <Grid
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          // className="backgimgForTrackShipment"
          className='adc'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box className='trackYourShipment'>
            <div
              className='mb-3'
              style={{
                cursor: 'pointer',
                fontWeight: '600',
                display: 'flex',
                justifyContent: 'end'
              }}
              onClick={() => setTrackYourOrder(false)}
            >
              <CancelIcon className='cancelIcon' />
            </div>
            <Typography
              // className="font-change"
              variant='h4'
              align='center'
              sx={{
                fontFamily: 'var(--family-label)',

                marginBottom: '',
                color: '#1084f1'
              }}
            >
              Track Shipment
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Typography
                // className="font-change"
                variant='overline'
                sx={{
                  fontSize: '0.8rem',
                  color: 'var(--color-primary)',
                  fontFamily: 'var(--family-label)'
                }}
              >
                Know exactly where your shipment is
              </Typography>
            </div>

            <form onSubmit={Captcharefresh}>
              <FormGroup>
                <FormLabel
                  // className="font-change"
                  id='ShipmentNumber'
                  sx={{
                    fontSize: '80%',
                    margin: '0',
                    fontFamily: 'var(--family-label)'
                  }}
                >
                  Shipment /House Bill Number /Mbl Number
                </FormLabel>
                <TextField
                  fullWidth
                  value={id}
                  size='small'
                  id='ShipmentNumber'
                  name='ShipmentNumber'
                  onChange={e => setId(e.target.value)}
                  sx={{
                    marginBottom: '1%',
                    backgroundColor: 'white',
                    borderRadius: '.3em'
                  }}
                />
                {showcaptchaTrack && (
                  <>
                    <FormLabel
                      // className="font-change"
                      id='captchaTrack'
                      sx={{
                        fontSize: '80%',
                        margin: '0',
                        marginTop: '10px',
                        fontFamily: 'var(--family-label)'
                      }}
                    >
                      CAPTCHA
                    </FormLabel>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xl={7}
                        lg={6}
                        md={12}
                        sm={12}
                        className='text_filedStyle'
                      >
                        <TextField
                          fullWidth
                          size='small'
                          id='captchaTrack'
                          name='captchaTrack'
                          sx={{
                            marginBottom: '3%',
                            // backgroundColor: "white",
                            borderRadius: '.3em'
                          }}
                          onChange={validateCaptchatrack}
                          value={captchaTrack}
                          error={!!captchaErrorTrack}
                          onPaste={handlePaste}
                          helperText={captchaErrorTrack}
                          onKeyDown={handleKeyPressship}
                        />
                      </Grid>

                      <Grid
                        item
                        xl={5}
                        md={12}
                        sm={12}
                        lg={6}
                        className='captcha-container-track'
                      >
                        <div
                          className={`captcha-code-track ${backgroundImageTrack}`}
                          style={{
                            userSelect: 'none',
                            width: '10rem',
                            marginBottom: '6px',
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          {captchaCodeTrack.split('').map((char, index) => (
                            <span key={index}>{char}</span>
                          ))}
                        </div>
                        {/* <button
                          className="refresh-button" style={{ userSelect: 'none' }}
                          onClick={refreshCaptchaTrack}
                        >
                          <FaSyncAlt />
                        </button> */}
                        <div
                          className='refresh-button-track'
                          style={{
                            userSelect: 'none',
                            color: 'white',
                            fontFamily: 'var(--family-label)'
                          }}
                          onClick={refreshCaptchaTrack}
                        >
                          <LoopIcon />
                          Refresh
                        </div>
                      </Grid>
                    </Grid>
                  </>
                )}

                <Button
                  className='font-change'
                  variant='contained'
                  color='primary'
                  type='submit'
                  style={{ marginTop: '1em' }}
                  disabled={!id || !captchaTrack || captchaErrorTrack}
                  onClick={() => {
                    let encrypted = CryptoJS.AES.encrypt(
                      id,
                      SECRET_KEY
                    ).toString()
                    let encoded = CryptoJS.enc.Base64.parse(encrypted).toString(
                      CryptoJS.enc.Hex
                    )

                    navigate(`${'/shipment/details/'}${encoded}`)
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      size='2rem'
                      style={{ color: 'var(--color-miscellaneous-4)' }}
                    />
                  ) : (
                    <span className='font-change'>Track</span>
                  )}
                </Button>
              </FormGroup>
            </form>
          </Box>
        </Grid>
        {/* <div className="row" style={{ position: "absolute", top: "30px", right: "20px" }}>
          <div
            className="mb-3"
            style={{ cursor: "pointer", fontWeight: "600" }}
            onClick={() => setTrackYourOrder(false)}
          >
            <CloseSharpIcon/>
          </div>
        </div> */}
      </Grid>
    )
  }

  return (
    <Grid
      container
      direction={{
        lg: 'row',
        md: 'row',
        sm: 'row',
        xs: 'column-reverse'
      }}
      sx={{
        flexWrap: 'inherit'
      }}
    >
      {/* SIGN IN */}
      <Grid
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box
          sx={{
            width: '68%'
          }}
        >
          <Typography
            className='font-change'
            variant='h5'
            align='center'
            sx={{
              marginBottom: '2%'
            }}
          >
            Sign In
          </Typography>
          {/* <Typography variant="overline">Lets Build Something great</Typography> */}
          <form onSubmit={onUserLogin}>
            <FormGroup>
              <FormLabel
                className='font-change'
                id='Email'
                sx={{
                  fontSize: '80%',
                  margin: '0'
                }}
              >
                Email
              </FormLabel>
              <TextField
                fullWidth
                size='small'
                id='Email'
                name='Email'
                onChange={handleInputChange('Email')}
                sx={{
                  marginBottom: '3%'
                }}
                error={fieldError['Email']}
                helperText={fieldError['Email']}
              />
              <FormLabel
                className='font-change'
                id='password'
                sx={{
                  fontSize: '80%',
                  margin: '0'
                }}
              >
                Password
              </FormLabel>
              <TextField
                sx={{
                  marginBottom: '4%'
                }}
                fullWidth
                size='small'
                type={showPassword ? 'text' : 'password'}
                id='Password'
                name='Password'
                onChange={handleInputChange('Password')}
                error={fieldError['Password']}
                helperText={fieldError['Password']}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={() => setShowPassword(!showPassword)}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
              {showcaptcha && (
                <>
                  <FormLabel
                    className='font-change'
                    id='captcha'
                    sx={{
                      fontSize: '80%',
                      margin: '0'
                    }}
                  >
                    CAPTCHA
                  </FormLabel>
                  <TextField
                    fullWidth
                    size='small'
                    id='captcha'
                    name='captcha'
                    onChange={validateCaptcha}
                    value={captcha}
                    error={!!captchaError}
                    onPaste={handlePaste}
                    helperText={captchaError}
                    onKeyDown={handleKeyPress}
                  />
                  <div className='captcha-container'>
                    <div
                      className={`captcha-code ${backgroundImage}`}
                      style={{ userSelect: 'none' }}
                    >
                      {captchaCode.split('').map((char, index) => (
                        <span key={index}>{char}</span>
                      ))}
                    </div>
                    <button
                      className='refresh-button'
                      onClick={refreshCaptcha}
                      style={{ userSelect: 'none' }}
                    >
                      <FaSyncAlt />
                    </button>
                  </div>
                </>
              )}

              <Button
                className='font-change'
                variant='contained'
                color='primary'
                type='submit'
                disabled={Object.keys(fieldError).length === 0 || isError}
                // onClick={() => {
                //   onUserLogin();
                // }}
                // onClick={onUserLogin}
              >
                {loading ? (
                  <CircularProgress
                    size='2rem'
                    style={{ color: 'var(--color-miscellaneous-4)' }}
                  />
                ) : (
                  'Login'
                )}
              </Button>
              <Typography
                className='font-change'
                variant='caption'
                color='primary'
                align='right'
                sx={{
                  cursor: 'pointer'
                }}
              >
                <span
                  onClick={() => {
                    navigate(Routeconstant.FORGETPASSWORD)
                  }}
                >
                  {' '}
                  Forgot password?
                </span>
              </Typography>
            </FormGroup>
          </form>
          <Divider className='font-change'>OR</Divider>
          <Button
            className='font-change'
            variant='outlined'
            fullWidth
            sx={{
              marginTop: '3%',
              marginBottom: '3%'
            }}
            onClick={() => {
              navigate(`${Routeconstant.TRACKYOURSHIPMENT}`)
            }}
          >
            Track your Shipment
          </Button>
          {/* <Button
              className='font-change'
              variant='outlined'
              fullWidth
              sx={{
                marginTop: '3%',
                marginBottom: '3%'
              }}
              onClick={() => {
                navigate(`${Routeconstant.PUBLICENQUIRY}`)
              }}
            >
              Create General Enquiry
            </Button> */}
        </Box>
      </Grid>
      {/* COMPANY INFO */}
      <Grid
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
        className='second-section'
        key={1}
      >
        <div className='image-container'>
          <img
            src={urlResolver('assets/login_image.jpg')}
            alt='Login'
            className='background-image'
          />
          <img
            src={urlResolver('assets/Logo_head_white.png')}
            alt='Logo'
            className='overlay-image'
          />
        </div>
      </Grid>
    </Grid>
  )
}

export default Login
