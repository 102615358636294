import React, { useEffect, useRef, useState } from "react";
import Finances from "./Finances";
import PendingRms from "./Favorite";
import Upcoming from "./Upcoming";
import Etaetd from "./etaetd";
import "./dashboard.css";
import accessGiven from "../../utils/permission";
import UpdateInfoModal from "../../UpdateInfo/UpdateInfoModal";
import { useContext } from "react";
import { AuthContext } from "../../store/context/AuthProvider";
import { useDispatch, useSelector } from "react-redux";
import { UpdatedDataCollectionAPi } from "../../redux/updateRedux/updateInfomation";
import Fab from "@mui/material/Fab";

export default function Dashboard() {
  const permission = accessGiven();
  const { openUpdate, setOpenUpdate } = useContext(AuthContext);
  const [updateButton, setUpdateButton] = useState(false);
  const dispatch = useDispatch();
  const { updateInformation } = useSelector((state) => state.updateInformation);

  const updateInfo = async () => {
    dispatch(UpdatedDataCollectionAPi());
  };

  const isObjectEmpty = (objectName) => {
    return JSON.stringify(objectName) === "{}";
  };

  function dateCompare(a, b) {
    let date1 = new Date(a);
    let date2 = new Date(b);

    if (date1 > date2) {
      return true;
    }
    return false;
  }
  function areDatesEqual(dateStr1, dateStr2) {
    const date1 = new Date(dateStr1);
    const date2 = new Date(dateStr2);

    // Extract and compare the year, month, and day values
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }
  function getLatestUpdates(data) {
    // Find the latest PushUtcDate
    const latestDate = data?.reduce(
      (max, item) =>
        dateCompare(item?.PushUtcDate, max) ? item?.PushUtcDate : max,
      data[0]?.PushUtcDate
    );
    // Filter objects with the latest PushUtcDate
    return data?.filter(
      (item) =>
        areDatesEqual(item?.PushUtcDate, latestDate) && item?.IsReadable == true
    );
  }
  const updateInformationObject = useRef({
    "Features Added": [],
    Improvements: [],
    "Bugs Fixed": [],
    "Performance Enhancements": [],
  });
  function informationFilter() {
    const info = getLatestUpdates(updateInformation?.data);
    if (info?.length > 0) {
      info.forEach((obj) => {
        updateInformationObject?.current[obj?.Header]?.push(obj);
      });
      setUpdateButton(true);
    }
  }
  useEffect(() => {
    if (!isObjectEmpty(updateInformation)) {
      informationFilter();
    }
  }, [updateInformation]);
  useEffect(() => {
    updateInfo();
  }, []);
  return (
    <>
      {openUpdate && (
        <UpdateInfoModal
          open={openUpdate}
          setOpen={setOpenUpdate}
          updateInformationObject={updateInformationObject}
        />
      )}
      <div className="dashboard-container">
        <div className="finances-favorite-container">
          {permission.financesAccess && <Finances />}

          <div
            className={
              permission.financesAccess === false
                ? "favorite-container-check"
                : "favorite-container"
            }
          >
            <div className="inside-favorite-container">
              <PendingRms />
            </div>
          </div>
        </div>
        <div className="map-etd-eta-container">
          <div className="map-upcoming-container">
            <div className="inside-container">
              <Upcoming />
            </div>
          </div>
          <div className="etd-eta-container">
            <div className="inside-container-eta-etd">
              <Etaetd />
            </div>
          </div>
        </div>
        {updateButton && (
          <div className="updateIcon">
            <Fab variant="extended" onClick={() => {
              setOpenUpdate(true);
              setUpdateButton(false);
            }
            }>
              Whats New
            </Fab>
          </div>
        )}
      </div>
    </>
  );
}
