import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../Config";
const encryptData = (name, data) => {
  try {
    const encrypted = CryptoJS?.AES?.encrypt(
      JSON.stringify(data),
      SECRET_KEY
    ).toString();
    localStorage.setItem(name, encrypted);
  } catch (error) {
    console.log("encryptdata method err", error);
  }
};

export default encryptData;
