import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MarkdownRenderer from "../common/MarkdownRenderer ";
import { useDispatch } from "react-redux";
import { changeReadibilityOfUpdate } from "../redux/updateRedux/updateInfomation";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "2px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#888",
    borderRadius: "4px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#555",
  },
};
export default function UpdateInfoModal({
  setOpen,
  open,
  updateInformationObject,
}) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(changeReadibilityOfUpdate());
    setOpen(false);
  }
  const {
    "Features Added": featureArray,
    Improvements: improvementArray,
    "Bugs Fixed": bugFixeArray,
    "Performance Enhancements": Enhancemanets,
  } = updateInformationObject.current;
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* Close button */}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* Modal content */}
          <Typography id="modal-modal-title" variant="h3" component="h2" className="text-uppercase text-center"> 
            Update 
          </Typography>
          <div>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, maxHeight: "25em", overflowY: "auto" }}
            >
              {bugFixeArray.length > 0 ? (
                <>
                  <div className=" fw-bold text-uppercase text-decoration-underline">
                    {" "}
                    Bugs Fixed{" "}
                  </div>
                  <div style={{marginLeft :"30px"}}>
                    {bugFixeArray?.map((singleInfo) => (
                      <MarkdownRenderer data={singleInfo} />
                    ))}
                  </div>
                </>
              ):null}
              {improvementArray.length > 0 ?  (
                <>
                  <div className=" fw-bold text-uppercase text-decoration-underline"> Improvement : -</div>
                  <div style={{marginLeft :"30px"}}>
                    {improvementArray?.map((singleInfo) => (
                      <MarkdownRenderer data={singleInfo} />
                    ))}
                  </div>
                </>
              ):null}
              {featureArray.length > 0 ? (
                <>
                  <div className=" fw-bold text-uppercase text-decoration-underline"> Features Added : -</div>
                  <div style={{marginLeft :"30px"}}>
                    {featureArray?.map((singleInfo) => (
                      <MarkdownRenderer data={singleInfo} />
                    ))}
                  </div>
                </>
              ):null}
              {Enhancemanets.length  > 0 ?(
                <>
                  <div className=" fw-bold text-uppercase text-decoration-underline"> Performance Enhancements : -</div>
                  <div style={{marginLeft :"30px"}} >
                    {Enhancemanets?.map((singleInfo) => (
                      <MarkdownRenderer data={singleInfo} />
                    ))}
                  </div>
                </>
              ):null}
            </Typography>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
