import React from 'react'
import { tooltipClasses } from '@mui/material/Tooltip';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/material/styles';
import Information from './PorductInformation';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const CustomWidthTooltip = styled(({ className, ...props }) => (  
  <ClickAwayListener onClickAway={props.handleTooltipClose}><Tooltip PopperProps={{
    disablePortal: true,
  }}
  onClose={props.handleTooltipClose}
  open={props.open}
  // disableFocusListener
  // disableHoverListener
  // disableTouchListener 
   {...props} classes={{ popper: className }} /></ClickAwayListener>

  
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 250,
    "font-size": "12px"
  },
});
const FormatedInformation = (data) => {
  return (
    <>
      {
        Object.keys(data).map((title) => {
          const type = typeof (data[title]) === 'object'
          // when we have to prove the internal title informaiton
          if (type) {
            return (
              <ul>
                {
                  Object.keys(data[title]).map((internalTitile) => {
                    return (
                      <li>
                        {data[title][internalTitile]} <br />
                      </li>
                    )
                  })
                }
              </ul>
            )
          }
          // normal use
          else {
            return (
              <Typography>
                <strong>{title}</strong> {data[title]} <br />
              </Typography>
            )
          }
        }

        )
      }
    </>
  )
}
const CustomTooltip = ({ of, forKey }) => {
  const type = typeof (Information[of][forKey]) === 'object'
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <CustomWidthTooltip title={type ? FormatedInformation(Information[of][forKey]) : Information[of][forKey]} open={open} handleTooltipClose={handleTooltipClose} handleTooltipOpen={handleTooltipOpen}>
      <HelpOutlineIcon onClick={handleTooltipOpen} onMouseOverCapture={handleTooltipOpen} sx={{ fontSize: "15px" }} />
    </CustomWidthTooltip>
  )
}

export default CustomTooltip