import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Routeconstant } from "../utils/RouteConstant";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import sha256 from "crypto-js/sha256";
import "./SecureRoute.css";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { API_PATH } from "../../constant/apiPath";
import instance from "../../api/Interceptor";
import toast from "react-hot-toast";
const SecureRoute = (props) => {
  const navigate = useNavigate();
  const [valid, setValid] = useState(false);
  function AlertDialogSlide() {
    const [userId, setUserId] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const handleTogglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };
    let currentDate = new Date(); // This creates a new Date object with the current date and time
    let currentMinutes = currentDate.getMinutes(); // This extracts the minutes from the current date and time

    const logInApiForUpdateForm = async () => {
      const response = await instance.post(
        `${
          API_PATH.UPDATE_FORM_LOG_IN
        }?value=${encrypLoginCrendentials()}&currentdate=${getCurrentFormattedDate()}`
      );
      if (response?.IsSuccess) {
        setValid(true);
      } else {
        toast.error(response?.message?.Message ?? "N/A");
      }
    };

    function getCurrentFormattedDate() {
      const currentDate = new Date();

      // Get date components
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const day = currentDate.getDate().toString().padStart(2, "0");

      // Get time components
      const hours = currentDate.getHours().toString().padStart(2, "0");
      const minutes = currentDate.getMinutes().toString().padStart(2, "0");
      const seconds = currentDate.getSeconds().toString().padStart(2, "0");
      const milliseconds = currentDate
        .getMilliseconds()
        .toString()
        .padStart(3, "0");

      // Format the date
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;

      return formattedDate;
    }
    const encrypLoginCrendentials = () => {
      let originalData = "";

      if (currentMinutes < 30) {
        originalData =
          currentMinutes.toString() + ">>" + userId + "::" + userPassword;
      } else {
        originalData =
          userId + "::" + userPassword + ">>" + currentMinutes.toString();
      }
      return sha256(originalData);
    };
    const onsubmit = async () => {
      try {
        await logInApiForUpdateForm();
      } catch (err) {
        toast.error("Error try again !!!!!!!!");
        console.log(err);
      }
    };
    return (
      <Dialog
        open={true}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogContent className="removePadding">
          <Card style={{ border: "none", boxShadow: "none" }}>
            <CardHeader subheader={"Security"} />
            <Divider sx={{ backgroundColor: "var(--color-miscellaneous-1)" }} />
            <CardContent>
              <Grid container>
                <Grid item xs={6} sm={6} md={6}>
                  <Typography>User Id:-</Typography>
                  <TextField
                    className="InputInField"
                    required
                    id="outlined-required"
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Typography>Password</Typography>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    value={userPassword}
                    onChange={(e) => setUserPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions className="d-flex justify-content-end">
              <Button
                onClick={() => {
                  navigate(Routeconstant.LOGIN);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  // if (userId == userIdfixed && userPassword == passWord) {
                  //   setValid(true);
                  // } else {
                  //
                  // }
                  onsubmit();
                }}
              >
                Continue
              </Button>
            </CardActions>
          </Card>
        </DialogContent>
      </Dialog>
    );
  }
  if (valid) return props.children;
  else {
    return <AlertDialogSlide />;
  }
};

export default SecureRoute;
