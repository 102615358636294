import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
  Tooltip
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import {
  Autocomplete,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import instance from '../../api/Interceptor'
import { API_PATH } from '../../constant/apiPath'
import { styled } from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu'
import Barchartinput from '../../components/input/chartinput/Barchartinput'
import NodatafoundComp from '../NodatafoundComp'
import { AuthContext } from '../../store/context/AuthProvider'
import { useContext } from 'react'
const SmallButton = styled(Button)(({ theme }) => ({
  fontSize: '0.5rem',
  borderRadius: '20px',
  minWidth: '15%',
  letterSpacing: '0.09857rem'
}))
ChartJS.register(BarElement, CategoryScale, LinearScale, Legend, Tooltip)
export default function BarChartByTime(props) {
  const colors = [
    ['#236C9C'],
    ['#0065E3'],
    ['#6190CB'],
    ['#0A2D44'],
    ['#D0DEF0']
  ]
  const [age, setAge] = React.useState('')
  const [currentBarData, setCurrentBarData] = useState([])
  const [currentMode, setCurrentMode] = useState('SEA')
  const [labels, setLabels] = useState([])
  const [nodataFound, setNoDataFound] = useState(false)
  const [labelsForPopup, setLabelsForPopup] = useState([])
  const [transportmode, setTransportmode] = useState()
  const context = useContext(AuthContext)
  const createData = (data, mode) => {
    let temp = []
    let tempLabel = []
    data.sort((a, b) => {
      // Compare CarrierName first
      if (a.CarrierName === null && b.CarrierName !== null) {
        return 1 // Move item with null CarrierName to the end
      }
      if (a.CarrierName !== null && b.CarrierName === null) {
        return -1 // Move item with null CarrierName to the end
      }

      // If CarrierName is the same or not null, compare CarrierCode
      if (a.CarrierCode === null && b.CarrierCode !== null) {
        return 1 // Move item with null CarrierCode to the end
      }
      if (a.CarrierCode !== null && b.CarrierCode === null) {
        return -1 // Move item with null CarrierCode to the end
      }

      return 0 // Keep the order unchanged
    })
    data.map((obj, index) => {
      temp.push(obj.DiffValue)
      tempLabel.push(obj.CarrierName)
    })
    let tempTrimLab = tempLabel.slice(0, 4).map(label => {
      if (label === null) {
        return 'N/A'
      } else {
        return label?.slice(0, 5) + '...'
      }
    })
    setLabels(tempTrimLab)
    setLabelsForPopup(tempLabel)
    setCurrentBarData(temp)
    setCurrentMode(mode)
  }
  const getBarChart = mode => {
    const body = {
      ShpEtdFrom: '',
      ShpEtdTo: '',
      ShpEtaFrom: '',
      ShpEtaTo: '',

      ShpTransportMode: props.filterOptions.filterMode
        ? props.filterOptions.filterMode
        : 'AIR',
      ShpOriginPortCode: props.originval, //mendatory
      ShpOriginPortName: '',
      ShpDestinationPortCode: props.destinationval, //mendatory
      ShpConsignorCode: '',
      ShpConsigneeCode: '',
      IncoTerm: '',
      ShpCarrierCode: props?.filterOptions?.filterCarrier
        ? props.filterOptions?.filterCarrier
        : '',
      Year: props?.filterOptions?.filterYear
        ? parseInt(props.filterOptions?.filterYear)
        : 0,
      ShpCarrierName: ''
    }
    instance
      .post(API_PATH.GET_CAREER_AVERAGE_TRANSIT_TIME, body)
      .then(res => {
        if (res.data.length) {
          setNoDataFound(false)
          createData(res.data, mode)
          setTransportmode(res.data[0].TransportMode)
        } else {
          setNoDataFound(true)
        }
      })
      .catch(err => {
        console.log(err)
        // toast.error(err);
      })
  }

  useEffect(() => {
    if (context.filterLoaded) getBarChart(currentMode)
  }, [
    props.filterOptions,
    props.originval,
    props.destinationval,
    context.filterLoaded
  ])
  const comptoLoad = () => {
    if (props.originval === '' && props.destinationval === '') {
      return (
        <div className='head-space'>Please select Origin & Destination</div>
      )
    } else if (nodataFound) {
      return <NodatafoundComp />
    } else {
      return (
        <div
          style={{
            height: '90%',
            width:
              props?.checkPop &&
              currentBarData?.length >= 4 &&
              `${13 * currentBarData?.length}em`
          }}
        >
          <Bar
            style={{
              minHeight: props?.checkPop && '500px'
            }}
            data={data}
            options={{
              animation: !props?.checkPop
                ? false
                : {
                    x: {
                      duration: 1000,
                      from: 3000
                    },
                    y: {
                      duration: 1000,
                      from: 3000
                    }
                  },
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: false, // Hide legend
                tooltip: {
                  yAlign: 'bottom',
                  callbacks: {
                    title: tooltipItems => {
                      return labelsForPopup[tooltipItems[0].parsed.x]
                    }
                  }
                }
              }
            }}
          ></Bar>
        </div>
      )
    }
  }
  const data = {
    labels: props?.checkPop
      ? labelsForPopup.map(lable => {
          if (lable?.length < 15) return lable
          return lable?.slice(0, 15) + '...'
        })
      : labels,
    datasets: [
      {
        data: props?.checkPop ? currentBarData.slice(0, 4) : currentBarData,
        backgroundColor: colors,
        label: `${transportmode === 'AIR' ? 'Hour ' : 'Day '}`
      }
    ]
  }

  return <>{comptoLoad()}</>
}
