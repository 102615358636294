import orderInstance from "../../../api/orderInterceptor";
import { API_PATH } from "../../../constant/apiPath";
import {baseOrderUrl} from "../../../Config"
const getpermision = async () => {  
    try {
      // api call for permission in order management
      let { data } = await orderInstance.get(
        `${baseOrderUrl}${API_PATH.ORDER_PERMISSION}`
      );
      // dispatch(updatePermissionData({ ...data })); syntax to upload data to redux store slice
    //   const data = {

    //     "Id": 0,//leave
    //     "RollName": null,//leave

    //     "CreateOrder": true,//done work
    //     "EditOrder": true,//done work

    //     "DeleteOrder": true,//leave

    //     "ViewOrder": true,//leave

    //     "CreateOrderDetail": true,//leave
        
    //     "CreateOrderLine": false,//DONE PERMISION TO ADD LINE
    //     "CreateOrderDocument": false,//upload data permision

    //     "CreateOrderPlan": true,//leave

    //     // edit permison
    //     "EditOrderDetail": true,//work done
    //     "EditOrderLine": false,//work done
    //     "EditOrderDocument": true,//done
    //     "EditOrderPlan": false,//done


    //     "DeleteOrderLine": false,//work done
    //     "DeleteOrderDocument": false,//work done

    //     "DeleteOrderPlan": true,//leave

    //     "ViewOrderDetails": true,//leave
    //     "ViewOrderDocument": true,//leave
    //     "ViewOrderPlan": true,//leave
    //     "ViewOrderLine": true,//leave

    //     "FreezedPlan": true,//leave

    //     "ViewRates": true,//leave

    //     "CreateRates": true,//leave
        
    //     "StatusChangeApp": false,//done
    //     "StatusChangeSHP": true,//work done
    //     "StatusChangeCAN": false,//work done
    //     "StatusChangeSHPPLC": true,//work done
    //     "StatusChangeARC": true,//work done
        
    //     "ShowSubOrder": true,//done

    //     "CreateShipment": true//leave
    // }
    return data;
    } catch (err) {
      console.log(err);
    }
  };
  export default getpermision;