import decryptData from "../decryptencrypt/decrypt";

const modulePermission = () => {
  // const informaiton = JSON.parse(localStorage.getItem("moduleAccessibility"));
  const informaiton = decryptData("moduleAccessibility");
  const moduleAcess = {
    Rms: informaiton?.Rms,
    Finance: informaiton?.Finance,
    GeneralEnquiry: informaiton?.GeneralEnquiry,
    PurchageOrder: informaiton?.PurchageOrder,
    Shipment: informaiton?.Shipment,
    BookingModule: informaiton?.BookingModule,
    ScheduleModule: informaiton?.ScheduleModule,
    Admin_Portal_Action_Modules:
      informaiton?.IsOrgnisationMappingContactControl,
  };
  return moduleAcess;
};
export default modulePermission;
