import { LOCAL_STORAGE_KEY } from '../Config'

export default function authHeader() {
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))

  if (user && user.AccessToken) {
    return user.AccessToken
  }
  return null
}
export function authHeaderRefresh() {
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))

  if (user && user.RefreshToken) {
    return user.RefreshToken
  }
  return null
}
export function localData() {
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))

  if (user && user.AccessToken) {
    return user
  }
  return null
}
