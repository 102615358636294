import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
  Typography
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { API_PATH } from '../../../constant/apiPath'
import { baseUrl } from '../../../Config'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { DateFormat } from '../../../utils/moment'
import {
  Heading,
  HeadingDetails
} from '../../booking/dashboard/components/CustomComp'
const Circle = () => {
  return <CheckCircleIcon color='success' />
}
const Line = () => {
  return (
    <div
      id='line'
      style={{
        minHeight: '5rem',
        height: '100%',
        width: '2px',
        backgroundColor: 'var(--color-ternary)'
      }}
    />
  )
}
export default function AirMilestones() {
  const location = useLocation()
  const shipment = location.state.data
  const [containerMilestoneData, setContainerMilestoneData] = useState({})
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(false)
  const getContainerMilestones = () => {
    return new Promise(function (resolve, reject) {
      const body = JSON.stringify({
        mbl: shipment?.MasterBillNumber?.length
          ? shipment?.MasterBillNumber[0]
          : '',
        type: 'air'
      })

      let xhr = new XMLHttpRequest()
      xhr.open(
        'POST',
        `${baseUrl}${API_PATH.GET_CONTAINER_MILESTONE_ROUTES_AIR}`
      )
      xhr.setRequestHeader('Content-Type', 'application/json')
      xhr.send(body)
      xhr.onload = function (e) {
        let parsedRes = JSON.parse(xhr.response)
        if (parsedRes?.IsSuccess) {
          setContainerMilestoneData(parsedRes.ResponseData.data)
        }
        resolve(true)
      }
    })
  }
  useEffect(() => {
    // iffy !needed just in case😉
    ;(async () => {
      setLoading(true)
      let prom = getContainerMilestones()
      prom.then(() => setLoading(false))
    })()
  }, [location?.state?.ShipmentId])
  console.log('containerMilestoneData?.events', containerMilestoneData?.events)
  async function modifyData(data) {
    return new Promise((resolve, reject) => {
      try {
        let newData = []
        let cons = data.map((event, index) => {
          if (index > 0) {
            if (
              event.location.iata_code ===
              containerMilestoneData?.events[index - 1].location.iata_code
            ) {
              console.log('innnnnnnnnn', newData[newData.length - 1])
              newData[newData.length - 1][`children`] = []
              newData[newData.length - 1].children.push(event)
              // newData[index - 1].children.push(event)
            } else {
              newData.push(event)
            }
          } else {
            newData.push(event)
          }
        })
        resolve(newData)
      } catch (err) {
        reject(err)
      }
    })
  }
  useEffect(() => {
    let res = modifyData(containerMilestoneData?.events)
    res
      .then(re => {
        setEvents(re)
      })
      .catch(er => {
        setEvents([])
      })
  }, [containerMilestoneData])

  function Right({ ele }) {
    return (
      <>
        <div
          id='description'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          <h6
            style={{
              fontSize: 'var(--fs-lg)',
              color: ' var(--color-secondary-dark)'
            }}
          >
            {' '}
            {ele?.location?.iata_code} ({ele?.location?.nearest_city})
          </h6>
          <span
            style={{
              fontSize: 'var(--fs-mid)'
            }}
          >
            {/* {DateFormat(event.datetime_local.actual)} */}
            {ele?.piece}pcs | {ele?.weight}kg
          </span>
        </div>
        <h6
          style={{
            fontSize: 'var(--fs-lg)'
          }}
        >
          {ele?.description}
        </h6>
        <p
          style={{
            fontSize: 'var(--fs-lg)'
          }}
        >
          {ele?.flight_number && `${ele?.flight_number} |`}{' '}
          {DateFormat(ele.datetime_local.actual)}
        </p>
      </>
    )
  }
  function event(ele, index) {
    return (
      <div
        style={{
          display: 'flex',
          columnGap: '1rem'
        }}
      >
        <div>
          <div
            id='circle_line'
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Circle />
            {events.length - 1 > index && <Line />}
          </div>
        </div>
        <div
          style={{
            width: '100%'
          }}
        >
          {/* right */}
          <Right ele={ele} />
          {ele.hasOwnProperty('children') && (
            <>
              {ele.children.map((el, ind) => {
                return (
                  <div
                    style={{
                      marginLeft: '0.5rem'
                    }}
                  >
                    <Right ele={el} />
                  </div>
                )
              })}
            </>
          )}
        </div>
      </div>
    )
  }
  return (
    <div>
      {loading ? (
        <div className='skeleton-div'>
          <Skeleton style={{ height: '3rem', marginTop: '1rem' }} />
        </div>
      ) : (
        <Accordion style={{ width: '100%' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            <Typography className='font-shipment'>Airline milestone</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ overflow: 'auto' }}>
            {containerMilestoneData?.hasOwnProperty('events') ? (
              <div
                style={{
                  maxHeight: '10rem',
                  padding: '1rem 0rem'
                }}
              >
                {events.map(event)}
              </div>
            ) : (
              <>No Data Found.</>
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  )
}
