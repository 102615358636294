import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import instance from '../../api/Interceptor'
import { API_PATH } from '../../constant/apiPath'

export const fetchDoclist = createAsyncThunk('fetchDoclist', async body => {
  console.log(body, 'body')
  try {
    const response = await instance.post(`${API_PATH.GET_ALL_DOCUMENTS}`, body)
    return response
  } catch (err) {
    console.log('error in thunk', err)
  }
})
export const fetchRemarks = createAsyncThunk('fetchRemarks', async body => {
  try {
    const response = await instance.get(`${API_PATH.GET_REMARKS}=${body}`)
    return response
  } catch (err) {
    console.log('error in thunk', err)
  }
})
export const updateStatus = createAsyncThunk('updateStatus', async body => {
  try {
    const response = await instance.post(
      `${API_PATH.DOCUMENT_APPROVAL}`,
      null,
      {
        params: {
          pk: body?.pk,
          status: body?.status,
          shipmentid: body?.shipmentid
        }
      }
    )
    return response
  } catch (err) {
    console.log('error in thunk', err)
  }
})
export const postRemarks = createAsyncThunk('postRemarks', async body => {
  try {
    const response = await instance.post(`${API_PATH.UPDATE_REMARKS}`, body)
    return response
  } catch (err) {
    console.log('error in thunk', err)
  }
})
export const fetchCurrentDoc = createAsyncThunk(
  'fetchCurrentDoc',
  async body => {
    try {
      const response = await instance.post(
        `${API_PATH.GET_DOCUMENT_DATA}`,
        body
      )
      return response
    } catch (err) {
      console.log('error in thunk', err)
    }
  }
)

const documentSlice = createSlice({
  name: 'documentSlice',
  initialState: {
    userInfo: {
      userRole: ''
    },
    docListState: {
      filters: {},
      body: {
        HouseBillNumber: '',
        ParentId: '',
        Search: '',
        Status: '',
        StartDate: '',
        EndDate: '',
        CurrentPage: 0,
        PageSize: 10,
        SortColumnName: '',
        SortDirection: '',
        ConsigneeCode: '',
        ConsignorCode: '',
        Approver: ''
      },

      isFilterOn: false,
      totalCount: 0,
      isFilterOpen: false,
      isLoading: false,
      data: [],
      isError: false,
      errorMsg: null,
      showMore: false
    },
    remarks: {
      isReject: false,
      isLoading: false,
      isError: false,
      currentRemarks: {},
      isRemarksOpen: false,
      currentParentId: null,
      currentPk: '',
      updateRemarks: {
        isLoading: false
      }
    },
    status: {
      isLoading: false,
      isSuccess: false,
      isError: false
    },
    currentDoc: {
      isLoading: false,
      isError: false,
      data: []
    }
  },
  reducers: {
    // handle doclist
    updateDocListBody: (state, action) => {
      if (action.payload.objName) {
        state[action.payload.obj][action.payload.objName][action.payload.name] =
          action.payload.value
      }
      state[action.payload.obj][action.payload.name] = action.payload.value
    },
    resetOnUnmout: (state, action) => {
      state.docListState.body = {
        HouseBillNumber: '',
        ParentId: '',
        Search: '',
        Status: '',
        StartDate: '',
        EndDate: '',
        CurrentPage: 0,
        PageSize: 10,
        SortColumnName: '',
        SortDirection: '',
        ConsigneeCode: '',
        ConsignorCode: ''
      }
    },
    resetFilters: (state, action) => {
      state.docListState.filters = {
        jobNo: '',
        docType: '',
        Approver: '',
        StartDate: null,
        EndDate: null,
        clientName: '',
        status: null
      }
    }

    // handle current remarks
  },
  extraReducers: builder => {
    // Doc List Cases
    builder.addCase(fetchDoclist.pending, (state, action) => {
      state.docListState.isLoading = true
    })
    builder.addCase(fetchDoclist.fulfilled, (state, action) => {
      state.docListState.isLoading = false
      if (state.docListState.showMore) {
        state.docListState.data = [
          ...state.docListState.data,
          ...action?.payload?.data
        ]
      } else {
        state.docListState.data = action?.payload?.data
      }
      console.log('payloadddd', action?.payload)
      state.docListState.totalCount = action?.payload?.totalCount
      state.docListState.showMore = false
      state.docListState.isError = false
    })
    builder.addCase(fetchDoclist.rejected, (state, action) => {
      state.docListState.isError = true
      state.docListState.isLoading = false
      state.docListState.errorMsg = 'Something went wrong!'
    })
    // Remarks cases
    builder.addCase(fetchRemarks.pending, (state, action) => {
      state.remarks.isLoading = true
    })
    builder.addCase(fetchRemarks.fulfilled, (state, action) => {
      state.remarks.isLoading = false
      if (action.payload.IsSuccess) {
        state.remarks.currentRemarks = action?.payload?.data
      }
    })
    builder.addCase(fetchRemarks.rejected, (state, action) => {
      state.remarks.isLoading = false
      state.docListState.isError = true
    })
    // post remarks
    builder.addCase(postRemarks.pending, (state, action) => {
      state.remarks.updateRemarks.isLoading = true
    })
    builder.addCase(postRemarks.fulfilled, (state, action) => {
      state.remarks.updateRemarks.isLoading = false
      console.log('action', action.payload)
    })
    builder.addCase(postRemarks.rejected, (state, action) => {
      state.remarks.updateRemarks.isLoading = false
    })
    // update status
    builder.addCase(updateStatus.pending, (state, action) => {
      state.status.isLoading = true
    })
    builder.addCase(updateStatus.fulfilled, (state, action) => {
      state.status.isLoading = false
      state.status.isSuccess = true
    })
    builder.addCase(updateStatus.rejected, (state, action) => {
      state.status.isLoading = false
      state.status.isSuccess = false
    })
    // current doc
    builder.addCase(fetchCurrentDoc.pending, (state, action) => {
      state.currentDoc.isLoading = true
    })
    builder.addCase(fetchCurrentDoc.fulfilled, (state, action) => {
      if (action.payload.IsSuccess) {
        state.currentDoc.data = action?.payload?.data
      }
      state.currentDoc.isLoading = false
    })
    builder.addCase(fetchCurrentDoc.rejected, (state, action) => {
      state.currentDoc.isLoading = false
      state.currentDoc.isError = true
    })
  }
})
export default documentSlice.reducer
export const { updateDocListBody, resetOnUnmout, resetFilters } =
  documentSlice.actions
