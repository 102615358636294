import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Editor } from "primereact/editor";
import "./UpdateForm.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TurndownService from "turndown";
import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Button } from "@mui/material";
import PublicHeader from "../publicEnquiry/PublicHeader";
import instance from "../../api/Interceptor";
import { API_PATH } from "../../constant/apiPath";
import BackDropLoader from "../../components/Loader/BackDropLoader";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
// import { marked } from 'marked';
const UpdateForm = () => {
  const [text, setText] = useState("");
  const [type, setType] = useState("");
  const [loader, setLoader] = useState(false);
  const [versionAssigned , setVersionAssigned] = useState("");
  const [lastVersion , setLastVersion] = useState(10);
  const onTextChange = (e) => {
    const htmlValue = e.htmlValue;
    // Check if htmlValue is empty
    if (htmlValue === null) {
      setText(""); // Clear the text state
    } else {
      // Convert HTML to Markdown
      const turndownService = new TurndownService();
      const markdownValue = turndownService.turndown(htmlValue);
      setText(markdownValue);
    }
  };
  const LastVersion = async () =>{
    try{
      const responce = await instance.get(API_PATH?.UPDATE_FORM_LAST_VERSION);
      console.log(responce?.data?.LastUpdatedVersion ?? "100");
      setLastVersion(responce?.data?.LastUpdatedVersion ?? "100")
    }catch(err){
      console.log(err);
    }
  }
  useEffect(()=>{
    LastVersion();
  },[])
  const updateInfo = async () => {
    if (type == "" || text == "" ||versionAssigned == "") {
      toast.error("Fill All the fields");
      return;
    }
    setLoader(true);
    const body = {
      Header: type,
      MarkdownText: text,
      Resion: "dfdd",
      Type: type,
      Version : versionAssigned,
    };
    try {
      const responce = await instance.post(API_PATH?.UPDATE_INFORMATION, body);
      setLoader(false);
      if (responce?.IsSuccess) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: responce?.message?.Message ?? "N/A",
          confirmButtonColor: "#236c9c",
        });
        setText("");
        setType("");
        setVersionAssigned("");
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: responce?.message?.Message ?? "N/A",
          confirmButtonColor: "#236c9c",
        });
      }
    } catch (err) {
      console.log("API ERROR", err);
      setLoader(false);
    }
  };
  // const htmlValue = marked(text);convert to htmlvalue format
  const createVersionDropDownList = () => {
    const list = [];
    for (let i = lastVersion; i < 100; i++) {
      let displayValue = "";
      if(i%10 == 0)displayValue = i/10 + "." + 0;
      else  displayValue = i/10;
      list.push(<MenuItem value={i}>{displayValue}</MenuItem>);
    }
    return list;
  };
  return (
    <>
      <PublicHeader />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "10px",
        }}
      >
        {loader && <BackDropLoader open={loader} />}
        <Paper
          elevation={3}
          style={{
            height: "90vh",
            width: "90vw",
            display: "flex",
            flexDirection: "column",
            padding: "37px",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            style={{ textTransform: "uppercase" }}
          >
            Insight Update Submission Form
          </Typography>
          <div style={{ display: "flex", gap: "16px" ,flexWrap : "wrap" }} className="inputs-div">
            {/* TextField */}
            <FormControl sx={{ minWidth: 120, width: "20%" }}>
              <InputLabel id="demo-simple-select-helper-label">
                VERSION
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="VERSION"
                value={versionAssigned}
                onChange={(event) => {
                  setVersionAssigned(event.target.value);
                }}
                style={{ flex: 1, height: "40px" }} // Set the height to the same value as TextField
              >
                {createVersionDropDownList()}
              </Select>
            </FormControl>

            {/* Select */}
            <FormControl sx={{ minWidth: 120, width: "40%" }}>
              <InputLabel id="demo-simple-select-helper-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Type"
                value={type}
                onChange={(event) => {
                  setType(event.target.value);
                }}
                style={{ flex: 1, height: "40px" }} // Set the height to the same value as TextField
              >
                <MenuItem value={"Features Added"}>Features Added</MenuItem>
                <MenuItem value={"Improvements"}>Improvements</MenuItem>
                <MenuItem value={"Bugs Fixed"}>Bugs Fixed</MenuItem>
                <MenuItem value={"Performance Enhancements"}>
                  Performance Enhancements
                </MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="UpdateFormCard">
            <PrimeReactProvider>
              <Editor
                value={text}
                onTextChange={onTextChange}
                style={{ height: "320px" }}
              />
            </PrimeReactProvider>
          </div>
          <Button variant="contained" onClick={updateInfo}>
            Submit
          </Button>
        </Paper>
      </div>
    </>
  );
};

export default UpdateForm;
