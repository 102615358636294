import React, { useEffect, useState } from "react";
import LayoutDetail from "../../../components/layout/LayoutDetail";
import "../shipment.css";
import Mapcomp from "./Mapcomp";
import MajorMilestone from "./MajorMilestone";
import Cw1Milestone from "./Cw1Milestone";
import Mapping from "./Mapping";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AuthContext } from "../../../store/context/AuthProvider";
import { useContext } from "react";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import AirMilestones from "./AirMilestones";

const Routing = (props) => {
  const [shipmentRoute, setShipmentRoute] = useState({});
  const [transportMode, setTransportMode] = useState("");
  const location = useLocation();
  const context = useContext(AuthContext);

  useEffect(() => {
    setShipmentRoute(
      location.pathname === "/shipment/listing/routing"
        ? location?.state?.data
        : props.shipmentDetails
    );
    setTransportMode(
      location.pathname === "/shipment/listing/routing"
        ? location?.state?.data?.TransportMode
        : props?.shipmentDetails?.TransportMode
    );
  }, [location?.state?.ShipmentId]);
  const navigate = useNavigate();
  useEffect(() => {
    if (context?.shipmentId?.length === 0) {
      navigate(Routeconstant.SHIPMENT_LISTING);
    }
  }, []);

  if (context?.shipmentId?.length === 0) return <></>;

  return (
    <LayoutDetail>
      <br />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <p className="basic-head m-0 font-change">Route Details</p>
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Cw1Milestone />
            <br />
            {transportMode === "SEA" ? <MajorMilestone /> : null}
            {transportMode === "AIR" ? <AirMilestones /> : null}
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{
                // width: "37rem",
                height: "30rem",
                position: "relative",
                marginBottom: "1rem",
                // marginRight: "0.5rem",
              }}
            >
              {Object.keys(shipmentRoute).length > 0 && (
                <Mapcomp
                  shipmentDetails={
                    location.pathname === "/shipment/listing/routing"
                      ? location?.state?.data
                      : props.shipmentDetails
                  }
                />
              )}
            </Grid>
            <Mapping />
          </Grid>
        </Grid>
      </Box>
    </LayoutDetail>
    //   <LayoutDetail>
    //     <Grid
    //        container
    //        spacing={4}
    //       style={{
    //         padding: "1%",
    //       }}
    //     >
    //       <Grid item lg={5} md={12} sm={12} xs={12}>
    //         <Grid container spacing={4}>
    //           <Grid item lg={5} md={12} sm={12} xs={12}>
    //             <MajorMilestone />
    //           </Grid>
    //           <Grid item lg={12} md={12} sm={12} xs={12}>
    //             <Cw1Milestone />
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //       <Grid item lg={7} md={12} sm={12} xs={12}>
    //         <Grid container spacing={4}>
    //           <Grid
    //             item
    //             lg={12}
    //             md={12}
    //             sm={12}
    //             xs={12}
    //             style={{
    //               width: "37rem",
    //               height: "30rem",
    //               position: "relative",
    //               marginBottom: "1rem",
    //               marginRight: "0.5rem",
    //             }}
    //           >
    //             <Mapcomp />
    //           </Grid>
    //           <Grid item lg={12} md={12} sm={12} xs={12}>
    //             <Mapping />
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //     </Grid>
    //   </LayoutDetail>
  );
};

export default Routing;
