import useApiCall from "./useApiCall";

async function FetchHook() {
  const { body, setLoading, setData, apiUrl, method } = arguments[0];
  let data = {};
  setLoading(true);
  data = await useApiCall(apiUrl, body, method);
  try {
    setData(data ?? {});
  } catch (error) {
    // Handle the error if needed
    console.error("Error in useApiCall:", error);
  } finally {
    setLoading(false);
  }
  return data;
}

export default FetchHook;
