import React, { useRef } from "react";
import { Grid, Paper, Skeleton } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect } from "react";
import instance from "../../../api/Interceptor";
import { API_PATH } from "../../../constant/apiPath";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { Container } from "@mui/system";
import { DateFormat } from "../../../utils/moment";
import { useDraggable } from "react-use-draggable-scroll";
import CustomTooltip from "../../../utils/CustomTooltip";

const Mapping = () => {
  const [mappingData, setMappingData] = useState({});
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [checkColor, setCheckColor] = useState([]);
  const [accordin, setAccordin] = useState(true);
  const [lastAtaFieldIndex, setLastAtaFieldIndex] = useState(0);

  const getShipmentTransportDetails = () => {
    setLoading(true);

    const body = {
      ShipmentId: location?.state?.ShipmentId ?? "",
    };
    instance
      .post(API_PATH.GET_TRANSPORT_DETAILS, body)
      .then((res) => {
        let i = 0;
        if (res?.data && res?.data?.length) {
          const response = res?.data[0];
          response.RoutesNodeColleciton.map((item, index) => {
            if (item.ataDate) {
              i = index;
            }
          });

          setLastAtaFieldIndex(i);
        }
        setMappingData(res?.data[0] ?? "");
      })
      .catch((err) => {
        console.log(err, "err");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   let temp2 = [];
  //   if (mappingData?.RoutesNodeColleciton) {
  //     mappingData?.RoutesNodeColleciton.map((item) => {
  //       console.log("item", item);
  //       temp2.push(item.ataDate);
  //     });
  //   }

  //   let temp = temp2.reverse();
  //   let isValid = false;
  //   let res = [];
  //   temp.map((x) => {
  //     if (!isValid && x !== null) {
  //       isValid = true;
  //     }
  //     res.push(isValid ? "success" : "grey");
  //   });
  //   let rev = res.reverse();
  //   setCheckColor(rev);
  // }, [mappingData]);
  const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  const { events } = useDraggable(ref);
  useEffect(() => {
    getShipmentTransportDetails();
  }, [location?.state?.ShipmentId]);
  return (
    <>
      {loading ? (
        <div className="skeleton-div">
          <Skeleton style={{ height: "10rem", marginTop: "1rem" }} />
        </div>
      ) : (
        <Accordion
          expanded={accordin}
          className="flex max-w-xl space-x-3 overflow-x-scroll scrollbar-hide"
          {...events}
          ref={ref}
          style={{ overflowY: "auto" }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                className="icon-expand"
                onClick={() => setAccordin(!accordin)}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="font-shipment">Routing</Typography>
          </AccordionSummary>
          <AccordionDetails className="details-map">
            {mappingData?.RoutesNodeColleciton &&
            mappingData?.RoutesNodeColleciton.length &&
            mappingData?.VesselVoyageCollection &&
            mappingData?.VesselVoyageCollection.length ? (
              <>
                <Grid
                  container
                  spacing={0}
                  sx={{
                    // overflow: "auto",
                    padding: " 0px 30px 5px 10px",
                    // paddingBottom: "5px",
                  }}
                >
                  <Grid
                    item
                    lg={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "1em",
                    }}
                  >
                    {mappingData?.VesselVoyageCollection &&
                      mappingData?.VesselVoyageCollection.map((i, index) => {
                        return (
                          <>
                            <div>
                              <CheckCircleRoundedIcon
                                // color={`${checkColor[index]}`}
                                color={`${
                                  (index <= lastAtaFieldIndex &&
                                    mappingData?.RoutesNodeColleciton?.[index]
                                      ?.ataDate) ||
                                  index === 0 ||
                                  i.etdDate
                                    ? "success"
                                    : "grey"
                                }`}
                              />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                className="font-shipment"
                                variant="caption"
                                title={i?.Vessel ?? "N/A"}
                                sx={{ fontSize: "10px" }}
                              >
                                {i?.Vessel === "" ? "N/A" : i?.Vessel}
                              </Typography>
                              <Container
                                sx={{
                                  border:
                                    index < lastAtaFieldIndex
                                      ? "1px solid green"
                                      : "1px dashed gray",
                                  minWidth: "8.25rem",
                                }}
                              ></Container>
                              <Typography
                                className="font-shipment"
                                variant="caption"
                                title={i?.Voyage ?? "N/A"}
                                sx={{ fontSize: "10px" }}
                              >
                                {i?.Voyage === "" ? "N/A" : i?.Voyage}
                              </Typography>
                            </div>
                            {mappingData?.VesselVoyageCollection.length - 1 ===
                              index && (
                              <CheckCircleRoundedIcon
                                // color={checkColor[index]}
                                color={`${
                                  index < lastAtaFieldIndex ? "success" : "grey"
                                }`}
                              />
                            )}
                          </>
                        );
                      })}
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    sm={12}
                    md={12}
                    xs={12}
                    sx={{
                      display: "flex",
                    }}
                  >
                    {mappingData?.RoutesNodeColleciton &&
                      mappingData?.RoutesNodeColleciton.map((item, index) => {
                        return (
                          <div className="mapping-description">
                            <Typography
                              className="font-shipment"
                              title={item?.description ?? "N/A"}
                              style={{
                                marginTop: "-10px",
                                marginBottom: "0.5rem",
                                textOverflow: "ellipsis",
                                width: "78px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {item?.description ?? "N/A"}
                            </Typography>
                            <Paper
                              elevation={1}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                padding: "5%",
                                background: "#F5F5F5",
                                height: "85%",
                                justifyContent: "center",
                                minWidth: "125px",
                              }}
                            >
                              {item?.nameId === "Dept_From_First_Load_Port" ||
                              item?.nameId ===
                                "Dept_From_First_Load_Port_air" ? null : (
                                <>
                                  <Typography
                                    className="font-shipment"
                                    variant="caption"
                                    sx={{
                                      fontSize: "13px",
                                    }}
                                    title={DateFormat(item?.etaDate ?? null)}
                                  >
                                    ETA: {DateFormat(item?.etaDate ?? null)}
                                  </Typography>
                                  <Typography
                                    className="font-shipment"
                                    variant="caption"
                                    sx={{
                                      fontSize: "13px",
                                    }}
                                    title={DateFormat(item?.ataDate ?? null)}
                                  >
                                    ATA: {DateFormat(item?.ataDate ?? null)}
                                  </Typography>
                                  {item?.nameId === "Arrival_At_Destination" ||
                                  item?.nameId ===
                                    "Arrival_At_Destination_air" ? null : (
                                    <div className="cw1-card my-2"></div>
                                  )}
                                </>
                              )}

                              {item?.nameId === "Arrival_At_Destination" ||
                              item?.nameId ===
                                "Arrival_At_Destination_air" ? null : (
                                <>
                                  {" "}
                                  <Typography
                                    className="font-shipment"
                                    variant="caption"
                                    sx={{
                                      fontSize: "13px",
                                    }}
                                    title={DateFormat(item?.etdDate ?? null)}
                                  >
                                    ETD: {DateFormat(item?.etdDate ?? null)}
                                  </Typography>
                                  <Typography
                                    className="font-shipment"
                                    variant="caption"
                                    sx={{
                                      fontSize: "13px",
                                    }}
                                    title={DateFormat(item?.atdDate ?? null)}
                                  >
                                    ATD: {DateFormat(item?.atdDate ?? null)}
                                  </Typography>
                                </>
                              )}
                            </Paper>
                          </div>
                        );
                      })}
                  </Grid>
                </Grid>
              </>
            ) : (
              <div className="d-flex justify-content-center">
                <p className="m-0 font-change">No data found!</p>
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default Mapping;
