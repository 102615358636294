import React from "react";

export default function NodatafoundComp() {
  return (
    <div
      style={{
        width: "100%",
        minHeight: "23dvh",
        height: "20dvh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "var(--color-primary)",
        fontFamily: "var(--family-label)",
        fontSize: "var(--fs-label)",
      }}
    >
      No Data Found
    </div>
  );
}
