import { Button } from "@mui/material";
import React from "react";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";

const Nointernet = () => {
  return (
    <div className="h-100 d-flex justify-content-center align-items-center flex-column">
      <p
        className="font-weight-bold"
        style={{
          color: "var(--color-primary)",
          fontFamily: "var(--family-label)",
          fontSize: "var(--fs-mid)",
        }}
      >
        Connect to the internet
      </p>
      <p
        className="font-weight-bold"
        style={{
          color: "var(--color-primary)",
          fontFamily: "var(--family-label)",
          fontSize: "var(--fs-mid)",
        }}
      >
        You're offline. Check your connection.
      </p>
      <div onClick={() => window.location.reload()}>
        <Button
          className="font-weight-bold"
          style={{
            color: "var(--color-primary)",
            fontFamily: "var(--family-label)",
            fontSize: "var(--fs-mid)",
            marginRight: "1rem",
          }}
        >
          RETRY
        </Button>
        <CachedOutlinedIcon />
      </div>
    </div>
  );
};

export default Nointernet;
