import React, { useContext, useState } from 'react'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { Grid, Skeleton } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import instance from '../../../api/Interceptor'
import { API_PATH } from '../../../constant/apiPath'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { DateFormat } from '../../../utils/moment'
import LinearProgress from '@mui/material/LinearProgress'
import { AuthContext } from '../../../store/context/AuthProvider'
import moment from 'moment/moment'
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select
} from '@mui/material'
import CustomTooltip from '../../../utils/CustomTooltip'
const Cw1Milestone = () => {
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const [options, setoptions] = useState([])
  const [cw1Milestone, setCw1Milestone] = useState({})
  const [cw1ProgressPercentage, setCw1ProgressPercentage] = useState(null)
  const [selectedContainerData, setSelectedContainerData] = useState({})
  const [status, setStatus] = useState('')
  const [selectedValue, setSelectedValue] = useState()
  const [indexValue, setindexValue] = useState(0)

  const calculatePercentage = () => {
    let completedMilestones = cw1Milestone?.ShipMilestoneCollection.filter(
      x => x.ActualStartDate !== null
    ).length
    let percentage = Math.round(
      (completedMilestones / cw1Milestone?.ShipMilestoneCollection?.length) *
        100
    )
    setCw1ProgressPercentage(percentage)
  }
  useEffect(() => {
    if (cw1Milestone.ShipMilestoneCollection) {
      calculatePercentage()
    }
  }, [cw1Milestone])

  const getCw1Milestone = () => {
    setLoading(true)
    const body = {
      ShipmentId: location?.state?.ShipmentId ?? ''
    }
    instance
      .post(API_PATH.GET_CW1_MILESTONE, body)
      .then(res => {
        const result = res?.data[0] ?? {}
        setCw1Milestone(result)
        const optionArray = []
        optionArray.push(result.ShipmentId)
        for (let element of result.ConMilestoneCollection) {
          optionArray.push(element?.ConsolId)
        }
        setoptions([...optionArray])
        if (optionArray.length > 0) setSelectedValue(optionArray[0])
        setSelectedContainerData([...(result?.ShipMilestoneCollection ?? [])])
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getCw1Milestone()
  }, [location?.state?.ShipmentId])
  useEffect(() => {
    if (indexValue === 0)
      setSelectedContainerData([
        ...(cw1Milestone?.ShipMilestoneCollection ?? [])
      ])
    else {
      setSelectedContainerData([
        ...(cw1Milestone?.ConMilestoneCollection[indexValue - 1]
          ?.MilestoneCollection ?? [])
      ])
    }
  }, [indexValue])
  // SSI00004279
  if (selectedContainerData.length) {
    console.log(
      'selectedContainerDataselectedContainerData',
      selectedContainerData,
      selectedContainerData
        ?.slice() // Create a shallow copy of the array
        ?.sort((a, b) => {
          if (a.ActualStartDate && !b.ActualStartDate) {
            return -1 // Sort a before b if a has ActualStartDate and b doesn't
          } else if (!a.ActualStartDate && b.ActualStartDate) {
            return 1 // Sort b before a if b has ActualStartDate and a doesn't
          } else if (a.ActualStartDate && b.ActualStartDate) {
            const dateA = new Date(a.ActualStartDate)
            const dateB = new Date(b.ActualStartDate)
            return dateB.getTime() - dateA.getTime() // Sort based on ActualStartDate in descending order
          } else if (a.EstimatedDate && !b.EstimatedDate) {
            return -1 // Sort a before b if a has EstimatedDate and b doesn't
          } else if (!a.EstimatedDate && b.EstimatedDate) {
            return 1 // Sort b before a if b has EstimatedDate and a doesn't
          } else if (a.EstimatedDate && b.EstimatedDate) {
            const dateA = new Date(a.EstimatedDate)
            const dateB = new Date(b.EstimatedDate)
            return dateB.getTime() - dateA.getTime() // Sort based on EstimatedDate in descending order
          } else {
            return 0 // Sort as equal if both have null values
          }
        })
    )
  }
  return (
    <>
      {loading ? (
        <div className='skeleton-div'>
          <Skeleton style={{ height: '10rem', marginTop: '1rem' }} />
        </div>
      ) : (
        <Accordion style={{ width: '100%', overflow: 'auto' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='panel1a-header'
          >
            {/* changed Cw1 Milestone to Milestones requirement by Crest */}
            <Typography className='font-shipment'>Milestones</Typography>
          </AccordionSummary>
          {options.length > 0 && (
            <AccordionDetails>
              <FormControl fullWidth sx={{ marginBottom: '1rem' }}>
                <InputLabel id='demo-simple-select-label'>Select</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={selectedValue}
                  label='Select '
                  onChange={e => {
                    setSelectedValue(e.target.value)
                  }}
                >
                  {options.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={item}
                        onClick={() => {
                          setindexValue(index)
                        }}
                      >
                        {item}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </AccordionDetails>
          )}
          {Object.keys(selectedContainerData).length !== 0 ? (
            <AccordionDetails>
              <div className='milestone-incoming d-flex'>
                <div
                  className='milestone-buttons d-flex flex-column '
                  style={{
                    paddingTop: '0 rem',
                    maxHeight: '220px',
                    width: '100%',
                    overflow: 'auto'
                  }}
                >
                  {/* {cw1Milestone?.ShipMilestoneCollection?.map((item, index) => { */}
                  {/* {cw1Milestone?.ShipMilestoneCollection
  ?.slice() // Create a shallow copy of the array
  .sort((a, b) => {
    const dateA = a.ActualStartDate ? new Date(a.ActualStartDate) : new Date(a.EstimatedDate);
    const dateB = b.ActualStartDate ? new Date(b.ActualStartDate) : new Date(b.EstimatedDate);
    return dateB.getTime() - dateA.getTime();
  })
  .map((item, index) => { */}
                  {selectedContainerData
                    ?.slice()
                    .sort((a, b) => {
                      // If both have ActualStartDate and EstimatedDate
                      if (
                        a.ActualStartDate &&
                        a.EstimatedDate &&
                        b.ActualStartDate &&
                        b.EstimatedDate
                      ) {
                        return (
                          new Date(b.ActualStartDate) -
                          new Date(a.ActualStartDate)
                        ) // Sort by ActualStartDate in descending order
                      }
                      // If only a has both ActualStartDate and EstimatedDate
                      else if (
                        a.ActualStartDate &&
                        a.EstimatedDate &&
                        (!b.ActualStartDate || !b.EstimatedDate)
                      ) {
                        return -1 // a comes before b
                      }
                      // If only b has both ActualStartDate and EstimatedDate
                      else if (
                        (!a.ActualStartDate || !a.EstimatedDate) &&
                        b.ActualStartDate &&
                        b.EstimatedDate
                      ) {
                        return 1 // b comes before a
                      }
                      // If both have only ActualStartDate
                      else if (a.ActualStartDate && b.ActualStartDate) {
                        return (
                          new Date(b.ActualStartDate) -
                          new Date(a.ActualStartDate)
                        ) // Sort by ActualStartDate in descending order
                      }
                      // If only a has ActualStartDate
                      else if (a.ActualStartDate && !b.ActualStartDate) {
                        return -1 // a comes before b
                      }
                      // If only b has ActualStartDate
                      else if (!a.ActualStartDate && b.ActualStartDate) {
                        return 1 // b comes before a
                      }
                      // If both have only EstimatedDate
                      else if (a.EstimatedDate && b.EstimatedDate) {
                        return (
                          new Date(b.EstimatedDate) - new Date(a.EstimatedDate)
                        ) // Sort by EstimatedDate in descending order
                      }
                      // If only a has EstimatedDate
                      else if (a.EstimatedDate && !b.EstimatedDate) {
                        return -1 // a comes before b
                      }
                      // If only b has EstimatedDate
                      else if (!a.EstimatedDate && b.EstimatedDate) {
                        return 1 // b comes before a
                      }
                      // If both don't have either ActualStartDate or EstimatedDate
                      else {
                        return 0 // Sort as equal
                      }
                    })
                    .map((item, index) => {
                      return (
                        <>
                          <div style={{ marginTop: '2px' }}>
                            <div
                              className='d-flex align-items-center'
                              // style={{ marginTop: "-60px" }}
                            >
                              {/* <CheckCircleRoundedIcon
                                color={
                                  item.ActualStartDate === null
                                    ? "grey"
                                    : "success"
                                }
                              />
                              /> */}
                              {item.ActualStartDate === null ? (
                                <div
                                  style={{
                                    width: '1.1rem',
                                    height: '1.1rem',
                                    marginLeft: '0.25rem',
                                    border: '2.5px solid var(--color-black)',
                                    borderRadius: '15px'
                                  }}
                                />
                              ) : (
                                <CheckCircleRoundedIcon color='success' />
                              )}
                              <div
                                className='milestone-card'
                                style={{
                                  boxShadow:
                                    'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
                                  borderRadius: ' 12px',
                                  padding: '10px 22px',
                                  display: 'flex',
                                  justifyContent: ' center',
                                  alignItems: ' flex-start',
                                  flexDirection: 'column',
                                  // maxHeight: "150px",
                                  minHeight: '48px',
                                  // width: "350px",
                                  backgroundColor: '#F5F5F5',
                                  overflowY: 'auto',
                                  width: '92%'
                                }}
                              >
                                <div
                                  key={index}
                                  style={{
                                    width: '100%'
                                  }}
                                >
                                  <div className='d-flex flex-column gap-2'>
                                    <div>
                                      <div
                                        style={{
                                          color: 'rgb(101, 148, 147)',
                                          fontSize: 'var(--fs-mid)'
                                        }}
                                      >
                                        {/* {item?.country} */}
                                      </div>
                                    </div>
                                    <div>
                                      <div
                                        className='d-flex flex-column'
                                        style={{ fontSize: 'var(--fs-small)' }}
                                      >
                                        <React.Fragment key={index}>
                                          <div className='d-flex gap-4'>
                                            <div>
                                              <div>
                                                {item?.ActualStartDate
                                                  ? `A: ${moment(
                                                      item?.ActualStartDate
                                                    ).format('DD/MM/YYYY')}`
                                                  : item?.EstimatedDate === null
                                                  ? 'E:'
                                                  : `E: ${moment(
                                                      item?.EstimatedDate
                                                    ).format('DD/MM/YYYY')}`}
                                              </div>
                                            </div>
                                            <div>
                                              <div className='d-flex flex-column'>
                                                {item?.Description ?? ''}
                                              </div>
                                            </div>
                                          </div>
                                        </React.Fragment>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* {index !==  */}
                            {selectedContainerData[index]?.ActualStartDate !==
                            null ? (
                              <div
                                style={{
                                  width: '0px',
                                  height: '50px',
                                  marginLeft: '11px',
                                  border: '2px solid #2e7d32',
                                  marginTop: '-14px',
                                  marginBottom: '-16px',

                                  display:
                                    item.Sequence !==
                                    selectedContainerData.slice(-1)[0].Sequence
                                      ? 'block'
                                      : 'none'
                                }}
                              >
                                {/* {console.log(
                                  "data====>",
                                  selectedContainerData[index].ActualStartDate
                                )}
                                {console.log(
                                  "last====>",
                                  selectedContainerData.slice(-1)[0].Sequence
                                )}
                                {console.log("last11===>", item.Sequence)} */}
                              </div>
                            ) : (
                              <div
                                style={{
                                  width: '0px',
                                  height: '50px',
                                  marginLeft: '11px',
                                  border: '2px solid grey',
                                  marginTop: '-14px',
                                  marginBottom: '-16px',
                                  display:
                                    item.Sequence !==
                                    selectedContainerData.slice(-1)[0].Sequence
                                      ? 'block'
                                      : 'none'
                                }}
                              ></div>
                            )}
                          </div>
                        </>
                      )
                    })}
                </div>
              </div>
            </AccordionDetails>
          ) : (
            <div className='d-flex justify-content-center align-items-center mb-3 font-change'>
              No data found!
            </div>
          )}
        </Accordion>
      )}
      {/* {loading ? (
        <div className="skeleton-div">
          <Skeleton style={{ height: "10rem", marginTop: "1rem" }} />
        </div>
      ) : (
        <Accordion expanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Milestone</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {cw1Milestone.ShipMilestoneCollection &&
            !cw1Milestone?.ShipMilestoneCollection.length &&
            cw1Milestone?.ContMilestoneCollection &&
            !cw1Milestone?.ContMilestoneCollection.length &&
            cw1Milestone?.ConMilestoneCollection &&
            !cw1Milestone?.ConMilestoneCollection.length ? (
              <div className="d-flex justify-content-center">
                <p className="m-0">No data found!</p>
              </div>
            ) : (
              <>
                <div className="cw1-card w-100 overflow-auto">
                  <div className="d-flex">
                    <div className="cw1-text mr-2">
                      <p className="m-0 cw1-data">{cw1Milestone?.ShipmentId}</p>
                    </div>
                    <div className="d-flex">
                      <Grid container spacing={1} style={{ width: "100%" }}>
                        <Grid item lg={12}>
                          <div className="d-flex">
                            {cw1Milestone?.ShipMilestoneCollection?.map(
                              (item) => {
                                return (
                                  <Typography
                                    variant="caption"
                                    style={{
                                      marginRight: "8em",
                                      minWidth: "8em",
                                      maxWidth: "8em",
                                    }}
                                    color="primary"
                                  >
                                    {DateFormat(item?.ActualStartDate ?? null)}
                                  </Typography>
                                );
                              }
                            )}
                          </div>
                        </Grid>
                        <Grid item lg={12} sm={12} xs={12} md={12}>
                          <LinearProgress
                            variant="determinate"
                            color="success"
                            value={cw1ProgressPercentage - 5}
                          />
                        </Grid>
                        <Grid item lg={12}>
                          <div className="d-flex">
                            {cw1Milestone?.ShipMilestoneCollection?.map(
                              (item) => {
                                return (
                                  <Typography
                                    variant="caption"
                                    style={{
                                      marginRight: "8em",
                                      minWidth: "8em",
                                      maxWidth: "8em",
                                    }}
                                  >
                                    {item.Description}
                                  </Typography>
                                );
                              }
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  {cw1Milestone?.ConMilestoneCollection ? (
                    <>
                      {cw1Milestone?.ConMilestoneCollection.map(
                        (item, index) => {
                          return (
                            <div className="d-flex" key={index}>
                              <div className="cw1-text mr-2">
                                <p className="m-0 cw1-data">
                                  {item?.ConsolId ?? "N/A"}
                                </p>
                              </div>
                              <div className="d-flex">
                                <Grid
                                  container
                                  spacing={1}
                                  style={{ width: "100%" }}
                                >
                                  <Grid item lg={12}>
                                    <div className="d-flex">
                                      {item.MilestoneCollection.map(
                                        (i, ind) => {
                                          return (
                                            <Typography
                                              variant="caption"
                                              style={{
                                                marginRight: "8em",
                                                minWidth: "8em",
                                                maxWidth: "8em",
                                              }}
                                              color="primary"
                                              key={ind}
                                            >
                                              {DateFormat(
                                                i?.ActualStartDate ?? null
                                              )}
                                            </Typography>
                                          );
                                        }
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item lg={12} sm={12} xs={12} md={12}>
                                    <LinearProgress
                                      variant="determinate"
                                      color="success"
                                      value={cw1ProgressPercentage - 5}
                                    />
                                  </Grid>
                                  <Grid item lg={12}>
                                    <div className="d-flex">
                                      {item.MilestoneCollection.map(
                                        (i, ind) => {
                                          return (
                                            <Typography
                                              variant="caption"
                                              style={{
                                                marginRight: "8em",
                                                minWidth: "8em",
                                                maxWidth: "8em",
                                              }}
                                              key={ind}
                                            >
                                              {i?.Description ?? "N/A"}
                                            </Typography>
                                          );
                                        }
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </>
                  ) : null}
                  {cw1Milestone?.ContMilestoneCollection ? (
                    <>
                      {cw1Milestone?.ContMilestoneCollection.map(
                        (item, index) => {
                          return (
                            <div className="d-flex" key={index}>
                              <div className="cw1-text mr-2">
                                <p className="m-0 cw1-data">
                                  {item?.Containernum ?? "N/A"}
                                </p>
                              </div>
                              <div className="d-flex">
                                <Grid
                                  container
                                  spacing={1}
                                  style={{ width: "100%" }}
                                >
                                  <Grid item lg={12}>
                                    <div style={{ display: "flex" }}>
                                      {item.MilestoneCollection.map(
                                        (i, ind) => {
                                          return (
                                            <Typography
                                              variant="caption"
                                              style={{
                                                marginRight: "8em",
                                                minWidth: "8em",
                                                maxWidth: "8em",
                                              }}
                                              color="primary"
                                              key={ind}
                                            >
                                              {DateFormat(
                                                i?.ActualStartDate ?? null
                                              )}
                                            </Typography>
                                          );
                                        }
                                      )}
                                    </div>
                                  </Grid>
                                  <Grid item lg={12} sm={12} xs={12} md={12}>
                                    <LinearProgress
                                      variant="determinate"
                                      color="success"
                                      value={cw1ProgressPercentage - 5}
                                    />
                                  </Grid>
                                  <Grid item lg={12}>
                                    <div className="d-flex">
                                      {item.MilestoneCollection.map(
                                        (i, ind) => {
                                          return (
                                            <Typography
                                              variant="caption"
                                              style={{
                                                marginRight: "8em",
                                                minWidth: "8em",
                                                maxWidth: "8em",
                                              }}
                                              key={ind}
                                            >
                                              {i?.Description ?? "N/A"}
                                            </Typography>
                                          );
                                        }
                                      )}
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </>
                  ) : null}
                </div>
              </>
            )}
          </AccordionDetails>
        </Accordion>
      )} */}
    </>
  )
}

export default Cw1Milestone
