import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Divider,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import AddUser from "../component/AddUser";
import {
  userRegisterApiDelete,
  userRegisterApiEditAndUpdate,
} from "../userApi/userRegisterApi";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function AddEditDelete({
  pageNo,
  getAllUser,
  setBackDropLoader,
  getActiveCount,
  setOpen,
  propertiesAddEditDelete,
  open,
}) {
  const handleClose = () => {
    setOpen(false);
  };
  const [alterBoxOpen, setAlterBoxOpen] = useState(false);
  const sender = propertiesAddEditDelete?.sender;
  const buttontext = {
    first:
      sender == "addUser"
        ? "cancel"
        : sender == "removeUser"
        ? "Discard"
        : "Discard",
    second:
      sender == "addUser"
        ? "submit"
        : sender == "removeUser"
        ? "Delete"
        : "Save",
  };
  const data = propertiesAddEditDelete?.data ?? {};
  const formik = useFormik({
    initialValues: {
      ContactName: data?.ContactName ?? "",
      JobTitle: data?.JobTitle ?? "", //not required
      JobCategory: data?.JobCategory ?? "", //not required
      EmailId: data?.Email ?? "",
      Gender: data?.Gender ?? "",
      ContactNumber: data?.ContactNumber ?? "",
      ContactPk: data?.PK ?? "",
    },
    validationSchema:
      sender != "removeUser"
        ? Yup.object({
            ContactName: Yup.string()
              .trim()
              .matches(
                /^[a-zA-Z\s]+$/,
                "Only alphabet characters and spaces are allowed"
              )
              .required("Required"),
            EmailId: Yup.string()
              .trim()
              .email("Invalid email address")
              .required("Required"),
            Gender: Yup.string().required("Required"),
            // ContactNumber: Yup.string()
            //   .required("Required")
            //   .test("len", "Must be between 7 to 15", (val) => {
            //     const trimmedValue = val?.replace(/[\s,]/g, "");
            //     return trimmedValue?.length >= 7 && trimmedValue?.length <= 15;
            //   })
            //   .matches(/^[0-9+\-\s,]+$/, {
            //     message: "Invalid format",
            //     excludeEmptyString: true,
            //   }),
          })
        : null,
    onSubmit: (values) => {
      const type =
        sender == "addUser"
          ? "post"
          : sender == "removeUser"
          ? "delete"
          : "put";
      if (type == "delete") {
        setAlterBoxOpen(true);
      } else {
        userRegisterApiEditAndUpdate(
          formik?.values,
          type,
          handleClose,
          setBackDropLoader,
          getAllUser,
          pageNo
        );
      }
    },
  });
  function AlertDialog() {
    const handleCloseAlterBox = () => {
      setAlterBoxOpen(false);
    };

    return (
      <React.Fragment>
        <Dialog
          open={alterBoxOpen}
          // onClose={handleCloseAlterBox}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Please confirm your decision to delete this account"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Once confirmed, the account cannot be recovered
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAlterBox}>Cancel</Button>
            <Button
              onClick={() => {
                handleCloseAlterBox();
                userRegisterApiDelete(
                  formik?.values,
                  getActiveCount,
                  handleClose,
                  setBackDropLoader,
                  getAllUser,
                  pageNo
                );
              }}
              autoFocus
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
  return (
    <>
      {alterBoxOpen && <AlertDialog />}
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
      >
        <DialogContent>
          <Card style={{ border: "none", boxShadow: "none" }}>
            <CardHeader
              // action={<Button onClick={() => {}}>Reset all</Button>}
              subheader={
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                  color={"primary"}
                >
                  <KeyboardBackspaceIcon /> Back
                </Button>
              }
            />
            <Divider sx={{ backgroundColor: "var(--color-miscellaneous-1)" }} />
            <CardContent>
              <AddUser
                propertiesAddEditDelete={propertiesAddEditDelete}
                formik={formik}
              />
            </CardContent>
            <CardActions className="d-flex justify-content-end">
              <Button
                onClick={() => {
                  handleClose();
                }}
                variant="contained"
                color="primary"
                type="submit"
              >
                {buttontext.first}
              </Button>
              <Button
                onClick={() => {
                  formik.handleSubmit();
                }}
                variant="contained"
                color="primary"
                type="submit"
              >
                {buttontext.second}
              </Button>
            </CardActions>
          </Card>
        </DialogContent>
      </Dialog>
    </>
  );
}
