const formatCurrency = (amount, currency) => {
  let amtstr = amount.toString()
  if (amtstr.length > 3) {
    let currencyCode = currency.toUpperCase()
    let data = amtstr.split('.')

    let finalData
    switch (currencyCode) {
      case 'BRL':
        data[0] = data[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        finalData = data.join(',')
        break
      case 'USD':
        data[0] = data[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        finalData = data.join('.')
        break
      case 'CLP':
        data[0] = data[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        finalData = data.join(',')

        break
      case 'INR':
        data[0] = data[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        finalData = data.join('.')
        break
      default:
        return amount
    }
    return finalData
  } else {
    return amtstr
  }
}

export default formatCurrency
