const formatNumber = row => {
  let amtstr = row?.toString()

  if (amtstr?.includes('.') || !amtstr?.includes('.')) {
    let data = amtstr?.split('.')
    data[0] = data[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    let finalData = data?.join(',')
    return finalData
  }
}

export default formatNumber
